import { Injectable, Output, EventEmitter } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { HandleError } from '../services/http-error-handler.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Address } from '../models/address';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class ApplicationHandlerService {

  private handleError: HandleError;
  public accessControlConfig: any = [];

  public isBodyClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(public cookieService: CookieService, public http: HttpClient, public snackBar: MatSnackBar) { }

  errorAudio = new Audio();
  temporaryToken: string = null;

  hostUrl = "https://api.zurexa.in/api";
  // hostUrl = "http://erp-dev-api.zurexa.in/api";
  // hostUrl = "http://pos-dev.southindia.cloudapp.azure.com:8000/api";
  // hostUrl = "http://104.211.217.202:8000/api";
  // hostUrl = "http://localhost:60298/api";
  rpKey: string = "rzp_live_Z28Cr5LN40v2L0";

  getCurrentVersion(): string {
    return "v3.0.7";
  }

  getCurrencyBreakup(): any {
    return [{ value: 2000, count: 0 },
    { value: 500, count: 0 },
    { value: 200, count: 0 },
    { value: 100, count: 0 },
    { value: 50, count: 0 },
    { value: 20, count: 0 },
    { value: 10, count: 0 }]
  };

  colors = [
    "#00ffff",
    "#a52a2a",
    "#008b8b",
    "#a9a9a9",
    "#006400",
    "#bdb76b",
    "#8b008b",
    "#556b2f",
    "#ff8c00",
    "#9932cc",
    "#8b0000",
    "#e9967a",
    "#9400d3",
    "#ff00ff",
    "#ffd700",
    "#008000",
    "#4b0082",
    "#f0e68c",
    "#add8e6",
    "#e0ffff",
    "#90ee90",
    "#d3d3d3",
    "#ffb6c1",
    "#ffffe0",
    "#00ff00",
    "#ff00ff",
    "#800000",
    "#000080",
    "#808000",
    "#ffa500",
    "#ffc0cb",
    "#800080",
    "#800080",
    "#ff0000",
    "#c0c0c0",
    "#ffffff",
    "#ffff00"
  ];

  getRandomColorsByNumber(indexval) {
    if (indexval != null) {
      return this.colors[indexval % 10];
    }
  }

  getAppointmentBookingModes(): any {
    return [{ FieldId: 1, FieldText: "Walk In" },
    { FieldId: 2, FieldText: "Through Phone Call" },
    { FieldId: 3, FieldText: "Self Booking" }];
  }

  getTimesForPromotional(): any {
    return [
      { id: "09:30 AM", time: "09:30 AM" },
      { id: "10:00 AM", time: "10:00 AM" },
      { id: "10:30 AM", time: "10:30 AM" },
      { id: "11:00 AM", time: "11:00 AM" },
      { id: "11:30 AM", time: "11:30 AM" },
      { id: "12:00 PM", time: "12:00 PM" },
      { id: "12:30 PM", time: "12:30 PM" },
      { id: "01:00 PM", time: "01:00 PM" },
      { id: "01:30 PM", time: "01:30 PM" },
      { id: "02:00 PM", time: "02:00 PM" },
      { id: "02:30 PM", time: "02:30 PM" },
      { id: "03:00 PM", time: "03:00 PM" },
      { id: "03:30 PM", time: "03:30 PM" },
      { id: "04:00 PM", time: "04:00 PM" },
      { id: "04:30 PM", time: "04:30 PM" },
      { id: "05:00 PM", time: "05:00 PM" },
      { id: "05:30 PM", time: "05:30 PM" },
      { id: "06:00 PM", time: "06:00 PM" },
      { id: "06:30 PM", time: "06:30 PM" },
      { id: "07:00 PM", time: "07:00 PM" },
      { id: "07:30 PM", time: "07:30 PM" },
      { id: "08:00 PM", time: "08:00 PM" },
      { id: "08:30 PM", time: "08:30 PM" }
    ]
  }

  convertUTCtoLocal(date): string {
    var dateString = "";
    if (date != null) {
      var dateVal = new Date(date);
      var utcToLocal = new Date(Date.UTC(dateVal.getFullYear(), dateVal.getMonth(), dateVal.getDate(), dateVal.getHours(), dateVal.getMinutes(), dateVal.getSeconds()));
      dateString = (utcToLocal.toLocaleDateString("en-GB") + ", " + utcToLocal.toLocaleTimeString());
    }
    return dateString;
  };

  getCustomFieldModules() {
    return [
      { Module: "INVENTORY", ModuleName: "Inventory" }
    ]
  }

  getFieldTypes() {
    return [
      { FieldId: "TEXT", FieldText: "Text" },
      { FieldId: "DROPDOWN", FieldText: "Dropdown With Static Content" },
      { FieldId: "DROPDOWN_DYNAMIC_CONTENT", FieldText: "Dropdown with Dynamic Content" },
      { FieldId: "CHECKBOX", FieldText: "Checkbox" },
      { FieldId: "TEXTAREA", FieldText: "Text Area" },
      { FieldId: "MULTISELECT", FieldText: "Multi Select" },
      { FieldId: "DYNAMIC_TEXT_ON_BILLING", FieldText: "Dynamic Text On Billing" }
    ]
  }

  getFeedbackQuestionTypes() {
    return [
      { FieldId: this.FEEDBACK_QUESTION_FOR_VISIT, FieldText: "Question For Visit" },
      { FieldId: this.FEEDBACK_QUESTION_FOR_SERVICE, FieldText: "Question For Service" }
    ]
  }

  getMembershipTypes() {
    return [
      { FieldId: this.MEMBERSHIP_VARIAION_IN_UNIT_PRICE, FieldText: "Price Variation in Unit Price" },
      { FieldId: this.MEMBERSHIP_VARIAION_BY_DISCOUNT, FieldText: "Discount in Unit Price" },
      { FieldId: this.MEMBERSHIP_VARIATION_IN_PACKAGE_PRICE, FieldText: "Price Variation in Packages" }
    ]
  }

  getDiscountTypes() {
    return [
      { FieldId: this.DISCOUNT_TYPE_FLAT_RS, FieldText: "Discount Flat in Rs." },
      { FieldId: this.DISCOUNT_TYPE_FLAT_PERCENTAGE, FieldText: "Discount Flat in %" }
    ]
  }

  getCustomerEnquiryQuestionTypes() {
    return [
      { FieldId: this.CUSOTMER_ENQUIRY_GENERAL, FieldText: "General Query" },
      { FieldId: this.CUSOTMER_ENQUIRY_FOR_SERVICE, FieldText: "Query Related to Service" }
    ]
  }

  CUSOTMER_ENQUIRY_GENERAL = 1;
  CUSOTMER_ENQUIRY_FOR_SERVICE = 2;

  FEEDBACK_RATING_FOR_SERVICE = 1;
  FEEDBACK_RATING_FOR_STAFF = 2;

  getFeedbackQuestionsFor() {
    return [
      { FieldId: this.FEEDBACK_RATING_FOR_SERVICE, FieldText: "Rating For Service" },
      { FieldId: this.FEEDBACK_RATING_FOR_STAFF, FieldText: "Rating For Staff" }
    ]
  }

  PAYMENT_TYPE_CUSTOMER_WALLET = "CUSTOMER_WALLET";

  FEEDBACK_ANSWER_TEXT = "TEXT";
  FEEDBACK_ANSWER_DROPDOWN = "DROPDOWN";
  FEEDBACK_ANSWER_TEXTAREA = "TEXTAREA";
  FEEDBACK_ANSWER_RATING = "RATING";

  MEMBERSHIP_VARIAION_IN_UNIT_PRICE = 1;
  MEMBERSHIP_VARIAION_BY_DISCOUNT = 2;
  MEMBERSHIP_VARIATION_IN_PACKAGE_PRICE = 3;

  APPOINTMENT_STATUS_SCHEDULED = 1;
  APPOINTMENT_STATUS_DECLINED = 2;
  APPOINTMENT_STATUS_REQUESTED = 3;
  APPOINTMENT_STATUS_BILLED = 4;
  APPOINTMENT_STATUS_DUE = 5;
  APPOINTMENT_STATUS_CANCELED = 6;

  APPOINTMENT_CANCELATION_SICK = "SICK";
  APPOINTMENT_DECLINE_SICK = "SICK";

  LOG_BILL_DELETE = "DELETE_BILL";
  LOG_BILL_PAYMENT_EDIT = "PAYMENT_EDIT_BILL";
  LOG_BILL_STAFF_EDIT = "STAFF_EDIT_BILL";
  LOG_BILL_DATE_CHANGE = "BILL_DATE_CHANGE";

  ZUREXA_MEMBERSHIP_BILL_TEMPLATE = 1;
  ZUREXA_MEMBERSHIP_EXPIRY_TEMPLATE = 2;
  ZUREXA_MEMBERSHIP_BEFORE_EXPIRY_TEMPLATE = 3;
  ZUREXA_MEMBERSHIP_AFTER_EXPIRY_TEMPLATE = 4;

  CUSTOMER_FILTER_TYPE_ALL = "ALL";
  CUSTOMER_FILTER_TYPE_ONLY_MEMBERSHIP = "MEMBERSHIP_CUSTOMERS";
  CUSTOMER_FILTER_TYPE_NON_MEMBERSHIP_ONLY = "NON_MEMBERSHIP_CUSTOMERS";
  CUSTOMER_FILTER_TYPE_CUSTOM = "CUSTOM_FILTER";

  getAuditLogTypes() {
    return [
      { FieldId: this.LOG_BILL_DELETE, FieldText: "Bill Delete" },
      { FieldId: this.LOG_BILL_DATE_CHANGE, FieldText: "Bill Date Change" },
      { FieldId: this.LOG_BILL_PAYMENT_EDIT, FieldText: "Bill Payment Edit" },
      { FieldId: this.LOG_BILL_STAFF_EDIT, FieldText: "Bill Staff Change" }
    ]
  }

  getFeedbackAnswerTypes() {
    return [
      { FieldId: this.FEEDBACK_ANSWER_TEXT, FieldText: "Text" },
      { FieldId: this.FEEDBACK_ANSWER_DROPDOWN, FieldText: "Dropdown" },
      { FieldId: this.FEEDBACK_ANSWER_TEXTAREA, FieldText: "Text Area" },
      { FieldId: this.FEEDBACK_ANSWER_RATING, FieldText: "Rating [1-5 Star]" },
    ]
  }

  getAppointmentCancellationReasons() {
    return [
      { FieldId: this.APPOINTMENT_CANCELATION_SICK, FieldText: "sick" }
    ]
  }

  getAppointmentDeclinedReasons() {
    return [
      { FieldId: this.APPOINTMENT_DECLINE_SICK, FieldText: "sick" }
    ]
  }

  getCampaignModes() {
    return [
      { CampaignMode: 1, Campaign: "Automatic" },
      { CampaignMode: 2, Campaign: "Manual" }
    ]
  }

  getCampaignStatusList() {
    return [
      { CampaignStatus: 1, Status: 'Planned' },
      { CampaignStatus: 2, Status: 'In Progress' },
      { CampaignStatus: 3, Status: 'Completed' },
      { CampaignStatus: 4, Status: 'Canceled' }
    ]
  }

  getCampaignRepeatOptions() {
    return [
      { Repeat: 1, RepeatMode: "Once" },
      { Repeat: 2, RepeatMode: "Daily" },
      { Repeat: 3, RepeatMode: "Monday to Friday" },
      { Repeat: 4, RepeatMode: "Custom" }
    ]
  }

  getSMSCount(smsTemplate: string) {
    var credit = 0;
    if (smsTemplate != null && smsTemplate.trim().length > 0) {
      if (smsTemplate.trim().length / 160 < 1) {
        credit = 1;
      }
      else {
        credit = smsTemplate.trim().length / 160;
        credit = Math.floor(credit);
        if (smsTemplate.trim().length % 160 > 0) {
          credit = credit + 1;
        }
      }
    }
    return credit;
  }

  getCharactersRemaining(smsTemplate: string, smsCount: number) {
    var charactersRemaining = 0;
    if (smsTemplate != null && smsTemplate.trim().length > 0) {
      if (smsCount >= 0) {
        // if (smsTemplate.trim().length % 150 == 0) {
        //   charactersRemaining = (smsCount * 150) - smsTemplate.trim().length;
        // }
        // else {
        //   charactersRemaining = ((smsCount + 1) * 150) - smsTemplate.trim().length;
        // }
        charactersRemaining = (smsCount * 160) - smsTemplate.trim().length;
      }
      else {

      }
    }
    else {
      charactersRemaining = 160;
    }
    return charactersRemaining;
  }

  getCampaignTypes() {
    return [
      { CampaignType: 1, Campaign: "Advertisement" },
      { CampaignType: 2, Campaign: "Awards" },
      { CampaignType: 3, Campaign: "Tele Marketing" },
      { CampaignType: 4, Campaign: "Trade Show" },
      { CampaignType: 5, Campaign: "Others" }
    ]
  }

  getFilterFieldTypes() {
    return [
      { Field: "MEMBERSHIP_CUSTOMERS", FieldText: "Membership Customers", FieldType: "DROP_DOWN_MEMBERSHIP" },
      { Field: "NO_OF_VISITS", FieldText: "Number Of Visits", FieldType: "NUMBER" },
      { Field: "CUSTOMER_BILL_VALUE", FieldText: "Customer Bill Value", FieldType: "NUMBER" },
      { Field: "CUSTOMER_TAKEN_SERVICE", FieldText: "Customer Service", FieldType: "DROP_DOWN_SERVICE" },
      { Field: "CUSTOMER_BOUGHT_PRODUCT", FieldText: "Customer Purchase Product", FieldType: "DROP_DOWN_PRODUCT" },
      { Field: "SERVICE_STAFF", FieldText: "Customers who has taken service from", FieldType: "DROP_DOWN_STAFF" },
      { Field: "LAST_VISIT", FieldText: "Customers who has last visit before", FieldType: "DROP_DOWN_STAFF" },
      { Field: "GENDER", FieldText: "Customers whose gender is", FieldType: "DROP_DOWN_GENDER" },
      { Field: "CUSTOMER_LABEL", FieldText: "Customers label is", FieldType: "DROP_DOWN_CUSTOMER_LABEL" }
    ]
  }

  getFilterExpressions() {
    return [
      { Expression: "EQUALS", ExpressionText: "Equal" },
      { Expression: "NOT_EQUALS", ExpressionText: "Not Equals" },
      { Expression: "GREATER_THAN", ExpressionText: "Greater Than" },
      { Expression: "GREATER_THAN_OR_EQUAL_TO", ExpressionText: "Greater Than Or Equal To" },
      { Expression: "LESS_THAN", ExpressionText: "Less Than" },
      { Expression: "LESS_THAN_OR_EQUAL_TO", ExpressionText: "Less Than Or Equal To" }
    ]
  }

  getAddressString(address: Address): any {
    let addressToReturn: string = "";
    if (address.AddressLine1 != null && address.AddressLine1.trim().length > 0)
      addressToReturn = addressToReturn + address.AddressLine1.trim() + ", ";
    if (address.AddressLine2 != null && address.AddressLine2.trim().length > 0)
      addressToReturn = addressToReturn + address.AddressLine2.trim() + ", ";
    if (address.City != null && address.City.trim().length > 0)
      addressToReturn = addressToReturn + address.City.trim() + ", ";
    if (address.State != null && address.State.trim().length > 0)
      addressToReturn = addressToReturn + address.State.trim() + ", ";
    if (address.Country != null && address.Country.trim().length > 0)
      addressToReturn = addressToReturn + address.Country.trim() + ", ";
    if (address.Zipcode != null && address.Zipcode.trim().length > 0)
      addressToReturn = addressToReturn + address.Zipcode.trim();

    return addressToReturn;
  }

  ROLE_ADMIN = "ROLE_ADMIN";
  ROLE_STAFF = "ROLE_STAFF";
  ROLE_BILLING_SPECIALIST = "ROLE_BILLING_SPECIALIST";
  ROLE_REPORT_ADMIN = "ROLE_REPORT_ADMIN";
  ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN";
  ROLE_CUSTOMER_SERVICE_REPRESENTATIVE = "ROLE_CUSTOMER_SERVICE_REPRESENTATIVE";
  ROLE_SUPERVISOR = "ROLE_SUPERVISOR";
  ROLE_SECONDARY_ADMIN = "ROLE_SECONDARY_ADMIN";

  GREETING_BIRTHDAY = 1;
  GREETING_ANNIVERSARY = 2;

  RESULT_SUCCESS = "success";
  RESULT_ERROR = "failure";
  STATUS_CODE_SUCCESS = 200;
  STATUS_CODE_CONFLICT = 409;
  STATUS_CODE_UNAUTHORIZED = 401;
  STATUS_CODE_FORBIDDEN = 403;
  STATUS_CODE_ITEM_NOT_FOUND = 204;
  STATUS_CODE_INTERNAL_SERVER_ERROR = 500;
  STATUS_CODE_EXPECTATION_FAILED = 417;
  STATUS_CODE_GONE = 410;
  STATUS_CODE_UPGRADE_REQUIRED = 426;
  STATUS_CODE_SERVICE_UNAVAILABLE = 503;

  BILL_ON_HOLD = "BILL_ON_HOLD";
  BILL_DECLINED = "BILL_DECLINED";
  BILL_BILLED = "BILL_BILLED";

  BUSINESS_TYPE_GENERAL = 1;
  BUSINESS_TYPE_SPA = 2;

  SMS_TEMPLATE_TYPE_CAMPAIGN = 1;
  SMS_TEMPLATE_TYPE_BBLC = 2;

  FEEDBACK_QUESTION_FOR_VISIT = 1;
  FEEDBACK_QUESTION_FOR_SERVICE = 2;

  DISCOUNT_TYPE_FLAT_RS = 1;
  DISCOUNT_TYPE_FLAT_PERCENTAGE = 2;

  CONSUMABLE_REQUESTED = "CONSUMABLE_REQUESTED";
  CONSUMABLE_PROCESSED = "CONSUMABLE_PROCESSED";
  CONSUMABLE_DECLINED = "CONSUMABLE_DECLINED";

  getPaymentTypes(): any {
    return [
      { PaymentTypeId: "CASH", PaymentType: "Cash" },
      { PaymentTypeId: "CREDIT_CARD", PaymentType: "Credit Card" },
      { PaymentTypeId: "CREDIT_BALANCE_CUSTOMER", PaymentType: "Credit Balance" },
      { PaymentTypeId: "CHEQUE", PaymentType: "Cheque" },
      { PaymentTypeId: "DEBIT_CARD", PaymentType: "Debit Card" },
      { PaymentTypeId: "GIFT_CARD", PaymentType: "Gift Card" },
      { PaymentTypeId: "UPI_ONLINE_WALLET", PaymentType: "UPI/Online Wallet" },
      { PaymentTypeId: "OTHER", PaymentType: "Other" }
    ]
  }

  @Output() change: EventEmitter<string> = new EventEmitter();

  updateBillMode(billMode) {
    this.change.emit(billMode);
  }

  getMembershipFilters(): any {
    return [
      { FilterId: "MEMBERSHIP", FilterText: "Membership Bills Only" },
      { FilterId: "NON_MEMBERSHIP", FilterText: "Non Membership Bills Only" }
    ]
  }

  getReportFilters(): any {
    return [
      { FilterId: "INVENTORY", FilterText: "Inventory" },
      { FilterId: "SERVICE", FilterText: "Service" },
      { FilterId: "PACKAGE", FilterText: "Package" },
      // { FilterId: "MEMBERSHIP", FilterText: "Membership" }
    ]
  }

  getSalesTrendFilters(): any {
    return [
      { FilterId: "ALL", FilterText: "All" },
      { FilterId: "INVENTORY", FilterText: "Inventory" },
      { FilterId: "SERVICE", FilterText: "Service" },
      { FilterId: "PACKAGE", FilterText: "Package" },
      { FilterId: "MEMBERSHIP", FilterText: "Membership" }
    ]
  }

  getExpenseCategories(): any {
    return [
      { CategoryId: "AIR_TRAVEL", CategoryName: "Air Travel Expense" },
      { CategoryId: "AUTOMOBILE", CategoryName: "Automobile Expense" },
      { CategoryId: "FUEL_MILEAGE", CategoryName: "Fuel/Mileage Expenses" },
      { CategoryId: "IT_INTERNET", CategoryName: "IT and Internet Expenses" },
      { CategoryId: "JOB_COSTING", CategoryName: "Job Costing" },
      { CategoryId: "LABOR", CategoryName: "Labor" },
      { CategoryId: "LODGING", CategoryName: "Lodging" },
      { CategoryId: "MATERIALS", CategoryName: "Materials" },
      { CategoryId: "MEALS_ENTERTAINMENTS", CategoryName: "Meals and Entertainment" },
      { CategoryId: "OFFICE_SUPPLIES", CategoryName: "Office Supplies" },
      { CategoryId: "OTHERS", CategoryName: "Other Expenses" },
      { CategoryId: "PARKING", CategoryName: "Parking" },
      { CategoryId: "SUB_CINTRACTOR", CategoryName: "Subcontractor" },
      { CategoryId: "TELEPHONE", CategoryName: "Telephone Expense" }
    ]
  }

  getUserRoles(): any {
    return [
      { RoleId: this.ROLE_ADMIN, RoleName: "Account Admin" },
      { RoleId: this.ROLE_BILLING_SPECIALIST, RoleName: "Billing Specialist" }
      // { RoleId: this.ROLE_SUPERVISOR, RoleName: "Supervisor" },
      // { RoleId: this.ROLE_CUSTOMER_SERVICE_REPRESENTATIVE, RoleName: "Customer Representative" }
    ]
  }

  getAllUserRoles(): any {
    return [
      { RoleId: this.ROLE_ADMIN, RoleName: "Account Admin" },
      { RoleId: this.ROLE_SECONDARY_ADMIN, RoleName: "Secondary Admin" },
      { RoleId: this.ROLE_BILLING_SPECIALIST, RoleName: "Billing Specialist" },
      { RoleId: this.ROLE_REPORT_ADMIN, RoleName: "Report Admin" }
      // { RoleId: this.ROLE_SUPERVISOR, RoleName: "Supervisor" },
      // { RoleId: this.ROLE_CUSTOMER_SERVICE_REPRESENTATIVE, RoleName: "Customer Representative" }
    ]
  }

  getUnitOfMeasures(): any {
    return [
      { unitId: "milligram", unitLabel: "Mg" },
      { unitId: "Gram", unitLabel: "G" },
      { unitId: "Kilogram", unitLabel: "Kg" }
    ]
  }

  getBusinessTypes(): any {
    return [
      { BusinessType: this.BUSINESS_TYPE_GENERAL, Text: "General Retail [Sales Only]" },
      { BusinessType: this.BUSINESS_TYPE_SPA, Text: "General Retail [Sales & Service]" }
    ]
  }

  getSMSTemplateTypes(): any {
    return [
      { TemplateType: this.SMS_TEMPLATE_TYPE_CAMPAIGN, Text: "Campaign" },
      { TemplateType: this.SMS_TEMPLATE_TYPE_BBLC, Text: "Bring Back Lost Customer" },
    ]
  }

  getMembershipSMSTemplateTypes(): any {
    return [
      { TemplateType: this.ZUREXA_MEMBERSHIP_BILL_TEMPLATE, Text: "Membership Bill SMS" },
      { TemplateType: this.ZUREXA_MEMBERSHIP_EXPIRY_TEMPLATE, Text: "Membership Expiry Alert[On Expiry Date]" },
      { TemplateType: this.ZUREXA_MEMBERSHIP_BEFORE_EXPIRY_TEMPLATE, Text: "Membership Expiry Reminder Before Expiry Date" },
      { TemplateType: this.ZUREXA_MEMBERSHIP_AFTER_EXPIRY_TEMPLATE, Text: "Membership Expiry Reminder After Expiry Date" }
    ]
  }

  getCustomerFilterTypes(): any {
    return [
      { FilterType: this.CUSTOMER_FILTER_TYPE_ALL, Text: "All" },
      { FilterType: this.CUSTOMER_FILTER_TYPE_ONLY_MEMBERSHIP, Text: "Membership Customers Only" },
      { FilterType: this.CUSTOMER_FILTER_TYPE_NON_MEMBERSHIP_ONLY, Text: "Non Membership Customers Only" },
      { FilterType: this.CUSTOMER_FILTER_TYPE_CUSTOM, Text: "Custom Filters" }
    ]
  }

  getInventoryTypes(): any {
    return [
      { typeId: "INVENTORY", typeLabel: "Inventory" },
      { typeId: 'SERVICE', typeLabel: "Service" },
      { typeId: 'CONSUMABLE', typeLabel: "Consumable" }
      // { typeId: "GIFTCARD", typeLabel: "Gift Card" },
      // { typeId: "MEMBERSHIP", typeLabel: "Membership" },
    ]
  }

  getTaxTypes(): any {
    return [
      { taxId: "INCLUSIVE", taxLabel: "Inclusive" },
      { taxId: "EXCLUSIVE", taxLabel: "Exclusive" }
    ]
  }


  getTitles(): any {
    return [
      { TitleId: "Ms.", TitleName: "Ms" },
      { TitleId: "Mr.", TitleName: "Mr" },
      { TitleId: "Mrs.", TitleName: "Mrs" },
    ]
  }

  getCustomerTitles(): any {
    return [
      { TitleId: "Ms.", TitleName: "Ms" },
      { TitleId: "Mr.", TitleName: "Mr" },
      { TitleId: "Mrs.", TitleName: "Mrs" },
      { TitleId: "Dr.", TitleName: "Dr" },
    ]
  }

  getGenders(): any {
    return [
      { GenderId: "Male", GenderName: "Male" },
      { GenderId: "Female", GenderName: "Female" },
      { GenderId: "Not Specified", GenderName: "Not Specified" }
    ]
  }

  setUserRole(role: string): any {
    return this.cookieService.set('role', role);
  }

  setUserActiveRole(role: string) {
    return this.cookieService.set("active", role);
  }

  getUserActiveRole() {
    return this.cookieService.get("active");
  }

  // getNoteWindowStatus() {
  //   return this.cookieService.get("noteWindow");
  // }

  // setNoteWindowStatus() {
  //   return this.cookieService.set("noteWindow", "1");
  // }

  getAccessControlConfig() {
    return this.accessControlConfig;
  }

  setAccessControlConfig(configs: any[]) {
    if (configs != null && configs.length > 0) {
      this.accessControlConfig = configs;
    } else {
      this.accessControlConfig = [
        {
          AllowedForAdmin: true,
          AllowedForBillingSpecialist: true,
          AllowedForSecondaryAdmin: true,
          Menu: "UTILITIES"
        },
        {
          AllowedForAdmin: true,
          AllowedForBillingSpecialist: true,
          AllowedForSecondaryAdmin: true,
          Menu: "DAY_CLOSURE"
        }
      ]
    }
  }

  getVendorLogo() {
    return this.cookieService.get("logo");
  }

  setVendorLogo(logo) {
    if (logo != null && logo.trim().length > 0) {
      this.cookieService.set("logo", logo);
    }
    else {
      this.cookieService.delete("logo");
    }
  }

  getBusinessType() {
    return this.cookieService.get("business");
  }

  getSelectedLocation() {
    return this.cookieService.get("selected_location");
  }

  getVendorId() {
    return this.cookieService.get("vendorid");
  }

  getVendorName() {
    return this.cookieService.get("vendorname");
  }

  updateSaleInfoFilterTab(tab) {
    this.cookieService.set("r_f_t", tab);
  }

  getSaleInfoFilterTab() {
    return this.cookieService.get("r_f_t");
  }

  getMembershipSaleInfoFilterTab() {
    return this.cookieService.get("m_f_t");
  }

  updateMembershipSaleInfoFilterTab(tab) {
    this.cookieService.set("m_f_t", tab);
  }

  getLocationId() {
    return this.cookieService.get("locationid");
  }

  getLocationName() {
    return this.cookieService.get("locationname");
  }

  getSMSEnabled() {
    return this.cookieService.get("sms");
  }

  getEmailEnabled() {
    return this.cookieService.get("email");
  }

  setLocationsMapped(locations: any) {
    if (locations != null) {
      this.cookieService.set("locations", JSON.stringify(locations));
    }
  }

  setLocationId(locationId: any) {
    this.cookieService.set("locationid", locationId);
  }

  setSelectedLocation(locationId: any) {
    this.cookieService.set("selected_location", locationId);
  }

  setLocationName(locationname) {
    this.cookieService.set("locationname", locationname);
  }

  getLocationsMapped() {
    var locationsMapped = this.cookieService.get("locations");
    if (locationsMapped != null && locationsMapped.length > 0) {
      return JSON.parse(locationsMapped);
    }
  }

  getUserRole(): any {
    return this.cookieService.get('role');
  }

  getRoleNameUsingKey(key): string {
    switch (key) {
      case this.ROLE_ADMIN:
        return "Account Admin";
      case this.ROLE_BILLING_SPECIALIST:
        return "Billing Specialist";
      case this.ROLE_SUPER_ADMIN:
        return "Super Admin";
      case this.ROLE_STAFF:
        return "Staff";
      case this.ROLE_SECONDARY_ADMIN:
        return "Secondary Admin";
      case this.ROLE_REPORT_ADMIN:
        return "Report Admin";
    }
  }

  getAccessToken(): string {
    return this.cookieService.get('token');
  }

  getCurrencySymbol(): string {
    return this.cookieService.get('currency');
  }

  removeAccessToken(): void {
    this.cookieService.deleteAll();
  }

  setAccessToken(token: string): void {
    this.cookieService.set('token', token);
  }


  getNavBasedOnRole(navPath: string): Observable<any> {
    return this.http.get<any>(navPath)
      .pipe(
        catchError(this.handleError('navbar retrieve'))
      );
  }

  updateTemporaryToken(token: string) {
    this.temporaryToken = token;
  }

  getTemporaryToken() {
    return this.temporaryToken;
  }

  openSnackBar(message: string, action: string, type: string) {
    if (type == "danger") {
      this.errorAudio.src = "../../../assets/audio/error_tone.mp3";
      this.errorAudio.load();
      this.errorAudio.play();
    }
    this.snackBar.open(message, "OKAY", {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: [type]
    });
  }

  // getAddressString(address: Address): string {
  //   let addressToReturn: string = "";
  //   for (var key in address) {
  //     if (addressToReturn.trim().length > 0 && address[key] != null && address[key].trim().length > 0)
  //       addressToReturn = addressToReturn + ", ";
  //     if (address[key] != null)
  //       addressToReturn = addressToReturn + address[key];
  //   }
  //   return addressToReturn;
  // };

  // showNotification(message, type): void {
  //   if ($("[data-notify=container]") != null) {
  //     $("[data-notify=container]").remove();
  //   }
  //   var icon = '';
  //   if (type == 'success')
  //     icon = 'fa fa-check';
  //   else if (type == 'danger')
  //     icon = 'fa fa-times-circle-o';
  //   else if (type == 'warning')
  //     icon = 'fa fa-exclamation-triangle';
  //   else if (type == 'info')
  //     icon = 'fa fa-info-circle';
  //   $.notify({
  //     message: message,
  //     icon: icon
  //   }, {
  //       type: type
  //     });
  //   setTimeout(function () {
  //     if ($('.animated fadeInDown') != null) {
  //       $('.animated').removeClass("fadeInDown").addClass("fadeOutUp");
  //     }
  //   }, 3000);
  // }
}
