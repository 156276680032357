export class Address {
    AddressLine1: string;
    AddressLine2: string;
    City: string;
    State: string;
    Zipcode: string;
    Country: string;
    Latitude: string;
    Longitude: string;
    CreatedTime: Date;
    UpdatedTime: Date;
}