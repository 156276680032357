export const navItemForAdminNonCRM = [
    {
        name: 'Dashboard',
        url: '/admin/dashboard',
        icon: 'icon-speedometer',
        active: true,
        quickLinks: [
            {
                name: 'Products',
                url: '/admin/products',
                icon: 'fa fa-barcode'
            },
            {
                name: 'Expenses',
                url: '/admin/expenses',
                icon: 'fa fa-money'
            },
            {
                name: 'Report',
                url: '/admin/sales-report',
                icon: 'fa fa-pie-chart'
            }
        ]
    },
    // {
    //     title: true,
    //     name: 'Point Of Sale'
    // },
    {
        name: 'Inventories/Services',
        url: '/admin/products',
        icon: 'fa fa-barcode',
        active: true,
        quickLinks: [
            {
                name: 'Add Product',
                url: '/admin/product-info',
                icon: 'fa fa-plus-square-o',
                quickLinks: [
                    {
                        name: 'Products',
                        url: '/admin/products',
                        icon: 'fa fa-barcode'
                    },
                    {
                        name: 'Import Product',
                        url: '/admin/products/import',
                        icon: 'fa fa-cloud-upload'
                    },
                    {
                        name: 'Report',
                        url: '/admin/sales-report',
                        icon: 'fa fa-pie-chart'
                    }
                ]
            },
            {
                name: 'Import Product',
                url: '/admin/products/import',
                icon: 'fa fa-cloud-upload',
                quickLinks: [
                    {
                        name: 'Products',
                        url: '/admin/products',
                        icon: 'fa fa-barcode'
                    },
                    {
                        name: 'Add Product',
                        url: '/admin/product-info',
                        icon: 'fa fa-plus-square-o'
                    },
                    {
                        name: 'Report',
                        url: '/admin/sales-report',
                        icon: 'fa fa-pie-chart'
                    }
                ]
            },
            {
                name: 'Report',
                url: '/admin/sales-report',
                icon: 'fa fa-pie-chart',
                quickLinks: [
                    {
                        name: 'Products',
                        url: '/admin/products',
                        icon: 'fa fa-barcode'
                    },
                    {
                        name: 'Purchase Orders',
                        url: '/admin/purchases',
                        icon: 'fa fa-cart-arrow-down'
                    },
                    {
                        name: 'Goods Received',
                        url: '/admin/grns',
                        icon: 'fa fa-truck'
                    }
                ]
            }
        ]
    },
    {
        name: 'Packages',
        url: '/admin/package-list',
        icon: 'fa fa-inbox',
        active: true,
        quickLinks: [
            {
                name: 'Add Package',
                url: '/admin/package-view',
                icon: 'fa fa-inbox',
                quickLinks: [
                    {
                        name: 'Packages',
                        url: '/admin/package-list',
                        icon: 'fa fa-inbox'
                    }
                ]
            },
            {
                name: 'Products',
                url: '/admin/products',
                icon: 'fa fa-barcode'
            },
            {
                name: 'Import Product',
                url: '/admin/products/import',
                icon: 'fa fa-cloud-upload'
            }
        ]
    },
    {
        name: 'Memberships',
        url: '/admin/membership-list',
        icon: 'fa fa-address-card',
        active: true,
        quickLinks: [
            {
                name: 'Add Membership',
                url: '/admin/membership-view',
                icon: 'fa fa-address-card',
                quickLinks: [
                    {
                        name: 'Memberships',
                        url: '/admin/membership-list',
                        icon: 'fa fa-address-card'
                    }
                ]
            },
            {
                name: 'Packages',
                url: '/admin/package-list',
                icon: 'fa fa-inbox'
            },
            {
                name: 'Products',
                url: '/admin/products',
                icon: 'fa fa-barcode'
            }
        ]
    },
    {
        name: 'Consumable Request',
        url: '/admin/consumables-requested',
        icon: 'fa fa-shopping-cart',
        active: true,
        quickLinks: [
            {
                name: 'New Consumable Request',
                url: '/admin/consumable-request',
                icon: 'fa fa-shopping-cart',
                quickLinks: [
                    {
                        name: 'Consumables Requested',
                        url: '/admin/consumables-requested',
                        icon: 'fa fa-shopping-cart',
                    },
                    {
                        name: 'Purchase Orders',
                        url: '/admin/purchases',
                        icon: 'fa fa-cart-arrow-down'
                    },
                    {
                        name: 'Products',
                        url: '/admin/products',
                        icon: 'fa fa-barcode'
                    }
                ]
            },
            {
                name: 'Purchase Orders',
                url: '/admin/purchases',
                icon: 'fa fa-cart-arrow-down'
            },
            {
                name: 'Products',
                url: '/admin/products',
                icon: 'fa fa-barcode'
            }
        ]
    },
    {
        name: 'Purchase Orders',
        url: '/admin/purchases',
        icon: 'fa fa-cart-arrow-down',
        active: true,
        quickLinks: [
            {
                name: 'Add Purchase',
                url: '/admin/purchase-info',
                icon: 'fa fa-plus-square-o',
                quickLinks: [
                    {
                        name: 'Purchase Orders',
                        url: '/admin/purchases',
                        icon: 'fa fa-cart-arrow-down'
                    },
                    {
                        name: 'Goods Received',
                        url: '/admin/grns',
                        icon: 'fa fa-truck'
                    },
                    {
                        name: 'Products',
                        url: '/admin/products',
                        icon: 'fa fa-barcode'
                    }
                ]
            },
            {
                name: 'Goods Received',
                url: '/admin/grns',
                icon: 'fa fa-truck'
            },
            {
                name: 'Products',
                url: '/admin/products',
                icon: 'fa fa-barcode'
            }
        ]
    },
    {
        name: 'Goods Received',
        url: '/admin/grns',
        icon: 'fa fa-truck',
        active: true,
        quickLinks: [
            {
                name: 'Add Goods Received Note',
                url: '/admin/grn-info',
                icon: 'fa fa-truck',
                quickLinks: [
                    {
                        name: 'Goods Received',
                        url: '/admin/grns',
                        icon: 'fa fa-truck'
                    },
                    {
                        name: 'Purchase Orders',
                        url: '/admin/purchases',
                        icon: 'fa fa-cart-arrow-down'
                    },
                    {
                        name: 'Products',
                        url: '/admin/products',
                        icon: 'fa fa-barcode'
                    }
                ]
            },
            {
                name: 'Purchase Orders',
                url: '/admin/purchases',
                icon: 'fa fa-cart-arrow-down'
            },
            {
                name: 'Products',
                url: '/admin/products',
                icon: 'fa fa-barcode'
            }
        ]
    },
    {
        name: 'Reports',
        url: '/admin/reports',
        icon: 'fa fa-line-chart',
        active: true,
        quickLinks: [
            {
                name: 'Add Goods Received Note',
                url: '/admin/grn-info',
                icon: 'fa fa-truck',
                quickLinks: [
                    {
                        name: 'Goods Received',
                        url: '/admin/grns',
                        icon: 'fa fa-truck'
                    },
                    {
                        name: 'Purchase Orders',
                        url: '/admin/purchases',
                        icon: 'fa fa-cart-arrow-down'
                    },
                    {
                        name: 'Products',
                        url: '/admin/products',
                        icon: 'fa fa-barcode'
                    }
                ]
            },
            {
                name: 'Purchase Orders',
                url: '/admin/purchases',
                icon: 'fa fa-cart-arrow-down'
            },
            {
                name: 'Products',
                url: '/admin/products',
                icon: 'fa fa-barcode'
            }
        ]
    },
    // {
    //     name: 'Reports',
    //     url: '/reports',
    //     icon: 'fa fa-file-pdf-o',
    //     active: true,
    //     children: [
    //         {
    //             name: 'Sales Report',
    //             url: '/admin/sales-report',
    //             icon: 'fa fa-file-pdf-o',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Sales Report',
    //                     url: '/admin/sales-report',
    //                     icon: 'fa fa-file-pdf-o',
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Detailed Sales Report',
    //             url: '/admin/detailed-sales-report',
    //             icon: 'fa fa-pie-chart',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Purchase vs Sale',
    //                     url: '/admin/profit-ratio',
    //                     icon: 'fa fa-line-chart'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Finance Report',
    //             url: '/admin/finance-report',
    //             icon: 'fa fa-money',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Sales Report',
    //                     url: '/admin/sales-report',
    //                     icon: 'fa fa-file-pdf-o'
    //                 },
    //                 {
    //                     name: 'Purchase vs Sale',
    //                     url: '/admin/profit-ratio',
    //                     icon: 'fa fa-line-chart'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Purchase vs Sale',
    //             url: '/admin/profit-ratio',
    //             icon: 'fa fa-line-chart',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Sales Report',
    //                     url: '/admin/sales-report',
    //                     icon: 'fa fa-file-pdf-o'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Staff vs Service',
    //             url: '/admin/staff-service',
    //             icon: 'fa fa-bar-chart',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Sales Report',
    //                     url: '/admin/sales-report',
    //                     icon: 'fa fa-file-pdf-o'
    //                 },
    //                 {
    //                     name: 'Purchase vs Sale',
    //                     url: '/admin/profit-ratio',
    //                     icon: 'fa fa-line-chart'
    //                 },
    //                 {
    //                     name: 'Dashboard',
    //                     url: '/admin/dashboard',
    //                     icon: 'icon-speedometer'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Staff vs Customer',
    //             url: '/admin/staff-customer',
    //             icon: 'fa fa-users',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Sales Report',
    //                     url: '/admin/sales-report',
    //                     icon: 'fa fa-file-pdf-o'
    //                 },
    //                 {
    //                     name: 'Purchase vs Sale',
    //                     url: '/admin/profit-ratio',
    //                     icon: 'fa fa-line-chart'
    //                 },
    //                 {
    //                     name: 'Dashboard',
    //                     url: '/admin/dashboard',
    //                     icon: 'icon-speedometer'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Staff Collection',
    //             url: '/admin/staff-collection',
    //             icon: 'fa fa-money',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Sales Report',
    //                     url: '/admin/sales-report',
    //                     icon: 'fa fa-file-pdf-o'
    //                 },
    //                 {
    //                     name: 'Purchase vs Sale',
    //                     url: '/admin/profit-ratio',
    //                     icon: 'fa fa-line-chart'
    //                 },
    //                 {
    //                     name: 'Dashboard',
    //                     url: '/admin/dashboard',
    //                     icon: 'icon-speedometer'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Customer Report',
    //             url: '/admin/customer-report',
    //             icon: 'fa fa-user-o',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Sales Report',
    //                     url: '/admin/sales-report',
    //                     icon: 'fa fa-file-pdf-o'
    //                 },
    //                 {
    //                     name: 'Purchase vs Sale',
    //                     url: '/admin/profit-ratio',
    //                     icon: 'fa fa-line-chart'
    //                 },
    //                 {
    //                     name: 'Dashboard',
    //                     url: '/admin/dashboard',
    //                     icon: 'icon-speedometer'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Feedback Reports',
    //             url: '/admin/feedback-report',
    //             icon: 'fa fa-star-half-o',
    //             active: true
    //         },
    //         {
    //             name: 'Customer Enquiries',
    //             url: '/admin/customer-enquiries',
    //             icon: 'fa fa-question-circle',
    //             active: true
    //         },
    //         {
    //             name: 'Day Closure History',
    //             url: '/admin/closure-history',
    //             icon: 'fa fa-money',
    //             active: true,
    //         }
    //     ]
    // },
    {
        name: 'Expenses',
        url: '/admin/expenses',
        icon: 'fa fa-money',
        active: true,
        quickLinks: [
            {
                name: 'Add Expense',
                url: '/admin/expense-info',
                icon: 'fa fa-money',
                quickLinks: [
                    {
                        name: 'Expenses',
                        url: '/admin/expenses',
                        icon: 'fa fa-money'
                    },
                    {
                        name: 'Customers',
                        url: '/admin/customers',
                        icon: 'fa fa-user-circle-o'
                    },
                    {
                        name: 'Suppliers',
                        url: '/admin/suppliers',
                        icon: 'fa fa-user'
                    }
                ]
            },
            {
                name: 'Suppliers',
                url: '/admin/suppliers',
                icon: 'fa fa-user'
            },
            {
                name: 'Customers',
                url: '/admin/customers',
                icon: 'fa fa-list-alt'
            }
        ]
    },
    {
        name: 'Suppliers',
        url: '/admin/suppliers',
        icon: 'fa fa-user',
        active: true,
        quickLinks: [
            {
                name: 'Add Supplier',
                url: '/admin/supplier-info',
                icon: 'fa fa-user',
                quickLinks: [
                    {
                        name: 'Suppliers',
                        url: '/admin/suppliers',
                        icon: 'fa fa-user'
                    },
                    {
                        name: 'Customers',
                        url: '/admin/customers',
                        icon: 'fa fa-list-alt'
                    },
                    {
                        name: 'Purchase Orders',
                        url: '/admin/purchases',
                        icon: 'fa fa-cart-arrow-down'
                    }
                ]
            },
            {
                name: 'Customers',
                url: '/admin/customers',
                icon: 'fa fa-list-alt'
            },
            {
                name: 'Users',
                url: '/admin/users',
                icon: 'fa fa-users'
            }
        ]
    },
    {
        name: 'Customers',
        url: '/admin/customers',
        icon: 'fa fa-user-circle-o',
        active: true,
        quickLinks: [
            {
                name: 'Add Customer',
                url: '/admin/customer-info',
                icon: 'fa fa-user-circle-o',
                quickLinks: [
                    {
                        name: 'Customers',
                        url: '/admin/customers',
                        icon: 'fa fa-user-circle-o'
                    },
                    {
                        name: 'Suppliers',
                        url: '/admin/suppliers',
                        icon: 'fa fa-user'
                    },
                    {
                        name: 'Users',
                        url: '/admin/users',
                        icon: 'fa fa-users'
                    }
                ]
            },
            {
                name: 'Import Customer',
                url: '/admin/customer-import',
                icon: 'fa fa-user-circle-o'
            },
            {
                name: 'Suppliers',
                url: '/admin/suppliers',
                icon: 'fa fa-user'
            }
        ]
    },
    // {
    //     name: 'Customer Wallet',
    //     url: '/admin/customer/wallet',
    //     icon: 'fa fa-money',
    //     active: true,
    //     quickLinks: [
    //         {
    //             name: 'Add Customer',
    //             url: '/admin/customer-info',
    //             icon: 'fa fa-user-circle-o',
    //             quickLinks: [
    //                 {
    //                     name: 'Customers',
    //                     url: '/admin/customers',
    //                     icon: 'fa fa-user-circle-o'
    //                 },
    //                 {
    //                     name: 'Suppliers',
    //                     url: '/admin/suppliers',
    //                     icon: 'fa fa-user'
    //                 },
    //                 {
    //                     name: 'Users',
    //                     url: '/admin/users',
    //                     icon: 'fa fa-users'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Import Customer',
    //             url: '/admin/customer-import',
    //             icon: 'fa fa-user-circle-o'
    //         },
    //         {
    //             name: 'Suppliers',
    //             url: '/admin/suppliers',
    //             icon: 'fa fa-user'
    //         }
    //     ]
    // },
    {
        name: 'Users',
        url: '/admin/users',
        icon: 'fa fa-users',
        active: true,
        quickLinks: [
            {
                name: 'Add User',
                url: '/admin/user-info',
                icon: 'fa fa-users',
                quickLinks: [
                    {
                        name: 'Users',
                        url: '/admin/users',
                        icon: 'fa fa-users'
                    },
                    {
                        name: 'Customers',
                        url: '/admin/customers',
                        icon: 'fa fa-user-circle-o'
                    },
                    {
                        name: 'Suppliers',
                        url: '/admin/suppliers',
                        icon: 'fa fa-user'
                    }
                ]
            },
            {
                name: 'Customers',
                url: '/admin/customers',
                icon: 'fa fa-user-circle-o'
            },
            {
                name: 'Suppliers',
                url: '/admin/suppliers',
                icon: 'fa fa-user'
            }
        ]
    },
    {
        name: 'Staffs',
        url: '/admin/staffs',
        icon: 'fa fa-user-o',
        active: true,
        quickLinks: [
            {
                name: 'Add Staff',
                url: '/admin/staff-info',
                icon: 'fa fa-user-o',
                quickLinks: [
                    {
                        name: 'Staffs',
                        url: '/admin/staffs',
                        icon: 'fa fa-user-o'
                    },
                    {
                        name: 'Users',
                        url: '/admin/users',
                        icon: 'fa fa-user-circle-o'
                    },
                    {
                        name: 'Suppliers',
                        url: '/admin/suppliers',
                        icon: 'fa fa-user'
                    }
                ]
            },
            {
                name: 'Users',
                url: '/admin/users',
                icon: 'fa fa-user-circle-o'
            },
            {
                name: 'Suppliers',
                url: '/admin/suppliers',
                icon: 'fa fa-user'
            }
        ]
    },
    {
        name: 'Settings',
        url: '/admin/settings',
        icon: 'fa fa-cogs',
        active: true,
        quickLinks: [
            {
                name: 'Add Staff',
                url: '/admin/staff-info',
                icon: 'fa fa-user-o',
                quickLinks: [
                    {
                        name: 'Staffs',
                        url: '/admin/staffs',
                        icon: 'fa fa-user-o'
                    },
                    {
                        name: 'Users',
                        url: '/admin/users',
                        icon: 'fa fa-user-circle-o'
                    },
                    {
                        name: 'Suppliers',
                        url: '/admin/suppliers',
                        icon: 'fa fa-user'
                    }
                ]
            },
            {
                name: 'Users',
                url: '/admin/users',
                icon: 'fa fa-user-circle-o'
            },
            {
                name: 'Suppliers',
                url: '/admin/suppliers',
                icon: 'fa fa-user'
            }
        ]
    }
    // {
    //     name: 'Settings',
    //     url: '/settings',
    //     icon: 'fa fa-cogs',
    //     active: true,
    //     children: [
    //         {
    //             name: 'Bill Templates',
    //             url: '/admin/bill-templates',
    //             icon: 'fa fa-print',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Add Bill Template',
    //                     url: '/admin/bill-template-info',
    //                     icon: 'fa fa-plus-square-o',
    //                     quickLinks: [
    //                         {
    //                             name: 'Bill Templates',
    //                             url: '/admin/bill-templates',
    //                             icon: 'fa fa-print'
    //                         },
    //                         {
    //                             name: 'Purchase Orders',
    //                             url: '/admin/purchases',
    //                             icon: 'fa fa-cart-arrow-down'
    //                         },
    //                         {
    //                             name: 'Products',
    //                             url: '/admin/products',
    //                             icon: 'fa fa-barcode'
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     name: 'Purchase Orders',
    //                     url: '/admin/purchases',
    //                     icon: 'fa fa-cart-arrow-down'
    //                 },
    //                 {
    //                     name: 'Products',
    //                     url: '/admin/products',
    //                     icon: 'fa fa-barcode'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Bill SMS Templates',
    //             url: '/admin/bill-sms-templates',
    //             icon: 'fa fa-commenting-o',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Add Bill SMS Template',
    //                     url: '/admin/bill-sms-template-info',
    //                     icon: 'fa fa-plus-square-o',
    //                     quickLinks: [
    //                         {
    //                             name: 'Bill SMS Templates',
    //                             url: '/admin/bill-sms-templates',
    //                             icon: 'fa fa-commenting-o'
    //                         },
    //                         {
    //                             name: 'Bill Templates',
    //                             url: '/admin/bill-templates',
    //                             icon: 'fa fa-print'
    //                         },
    //                         {
    //                             name: 'Products',
    //                             url: '/admin/products',
    //                             icon: 'fa fa-barcode'
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     name: 'Purchase Orders',
    //                     url: '/admin/purchases',
    //                     icon: 'fa fa-cart-arrow-down'
    //                 },
    //                 {
    //                     name: 'Products',
    //                     url: '/admin/products',
    //                     icon: 'fa fa-barcode'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Feedback Questions',
    //             url: '/admin/feedback-questions',
    //             icon: 'fa fa-star-half-o',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Add Bill Template',
    //                     url: '/admin/bill-template-info',
    //                     icon: 'fa fa-plus-square-o',
    //                     quickLinks: [
    //                         {
    //                             name: 'Bill Templates',
    //                             url: '/admin/bill-templates',
    //                             icon: 'fa fa-print'
    //                         },
    //                         {
    //                             name: 'Purchase Orders',
    //                             url: '/admin/purchases',
    //                             icon: 'fa fa-cart-arrow-down'
    //                         },
    //                         {
    //                             name: 'Products',
    //                             url: '/admin/products',
    //                             icon: 'fa fa-barcode'
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     name: 'Bill Templates',
    //                     url: '/admin/bill-templates',
    //                     icon: 'fa fa-print'
    //                 },
    //                 {
    //                     name: 'Products',
    //                     url: '/admin/products',
    //                     icon: 'fa fa-barcode'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Enquiry Config',
    //             url: '/admin/enquiry-config',
    //             icon: 'fa fa-cogs',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Feedback Questions',
    //                     url: '/admin/feedback-questions',
    //                     icon: 'fa fa-star-half-o',
    //                 },
    //                 {
    //                     name: 'Bill Templates',
    //                     url: '/admin/bill-templates',
    //                     icon: 'fa fa-print',
    //                     active: true,
    //                 }
    //             ]
    //         },
    //         // {
    //         //     name: 'Discount Config',
    //         //     url: '/admin/discount-config',
    //         //     icon: 'fa fa-percent',
    //         //     active: true,
    //         //     quickLinks: [
    //         //         {
    //         //             name: 'Add Discount Config',
    //         //             url: '/admin/discount-info',
    //         //             icon: 'fa fa-percent',
    //         //         },
    //         //         {
    //         //             name: 'Feedback Questions',
    //         //             url: '/admin/feedback-questions',
    //         //             icon: 'fa fa-star-half-o',
    //         //         },
    //         //         {
    //         //             name: 'Bill Templates',
    //         //             url: '/admin/bill-templates',
    //         //             icon: 'fa fa-print',
    //         //             active: true,
    //         //         }
    //         //     ]
    //         // }
    //     ]
    // }
];
