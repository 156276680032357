import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { ApplicationHandlerService } from '../helpers_and_handlers/application-handler.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService {

  ngOnInit(): void {
  }

  screenInfo: object;
  constructor(private appHandler: ApplicationHandlerService, private router: Router) { }

  canActivate(state: RouterStateSnapshot): boolean {
    if (this.appHandler.getAccessToken() == null || this.appHandler.getAccessToken().length == 0 && (state.url != "/recoverpwd" && state.url != "/pwdreset")) {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }

}
