import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentService } from '../../../services/payment.service';
import { ApplicationHandlerService } from '../../../helpers_and_handlers/application-handler.service';
import { UserService } from '../../../services/user.service';
declare var Razorpay: any;

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
  userInfo: any = {};
  subscription: any = {
    locationId: null,
    creditCategory: "SUBSCRIPTION",
    unitPrice: 0.21,
    businessName: this.appHandler.getVendorName(),
    amount: null,
    gstCollected: 0
  }
  constructor(private router: Router, private paymentService: PaymentService,
    private userService: UserService,
    private appHandler: ApplicationHandlerService) { }

  ngOnInit() {
    this.getLoggedinUserProfile();
  }

  getLoggedinUserProfile() {
    this.userService.getLoggedInUserProfile()
      .subscribe(response => {
        if (response.status == this.appHandler.STATUS_CODE_SUCCESS) {
          if (response.data != null) {
            if (response.data.DateOfBirth != null) {
              response.data.DateOfBirth = new Date(response.data.DateOfBirth);
            }
            this.userInfo = response.data;
          }
          else {
            this.appHandler.openSnackBar('something went wrong! please try again.', 'OK', 'danger');
          }
        }
      });
  }

  initiatePayment(order: any) {
    var options = {
      "key": this.appHandler.rpKey, // Enter the Key ID generated from the Dashboard
      "amount": this.subscription.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": "INR",
      "name": "Zurexa", //your business name
      "description": order.Description,
      "image": "https://www.zurexa.in/assets/img/logo_with_name_grey.png",
      "order_id": order.OrderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      "handler": function (response) {
        alert(response.razorpay_payment_id);
        alert(response.razorpay_order_id);
        alert(response.razorpay_signature)
      },
      "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
        "name": order.CustomerName, //your customer's name
        "email": order.CustomerEmail,
        "contact": order.CustomerContactNo  //Provide the customer's phone number for better conversion rates 
      },
      "notes": {
        "address": "Zurexa"
      },
      "theme": {
        "color": "#ef3d59"
      }
    };
    var rzp1 = new Razorpay(options);
    rzp1.on('payment.failed', function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });

    rzp1.open();
  }

  createOrderId() {
    let tax = (18 / 100) * 8000;
    this.subscription.amount = 8000 + tax;
    this.subscription.gstCollected = tax;
    this.paymentService.createOrderId(this.subscription)
      .subscribe(response => {
        if (response && response.status == this.appHandler.STATUS_CODE_SUCCESS) {
          if (response.data != null && response.data.OrderId != null &&
            response.data.OrderId.trim().length > 0) {
            this.initiatePayment(response.data);
          }
        }
      })
  }

  navigateToDashboard() {
    this.router.navigate(['/admin/dashboard']);
  }

}
