import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandlerService } from './http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { ApplicationHandlerService } from '../helpers_and_handlers/application-handler.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Purchase } from '../models/purchase';
import { ConsumableRequest } from '../models/consumablerequest';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

  private handleError: HandleError;
  constructor(
    public http: HttpClient,
    public appHandler: ApplicationHandlerService,
    httpErrorHandler: HttpErrorHandlerService) {
    this.handleError = httpErrorHandler.createHandleError('Purchase Service');
  }

  addPurchase(purchaseInfo: Purchase): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/purchase/add", purchaseInfo)
      .pipe(
        catchError(this.handleError('add purchase'))
      );
  }

  addConsumableRequest(consumableRequestInfo: ConsumableRequest): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/purchase/consumable/request/add", consumableRequestInfo)
      .pipe(
        catchError(this.handleError('add consumable request'))
      );
  }

  updatePurchase(purchaseInfo: Purchase): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/purchase/update", purchaseInfo)
      .pipe(
        catchError(this.handleError('add purchase'))
      );
  }

  updateConsumableRequest(consumableRequestInfo: ConsumableRequest): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/purchase/consumable/request/update", consumableRequestInfo)
      .pipe(
        catchError(this.handleError('update consumable request'))
      );
  }

  updateRequestStatus(requestId: any, statusToUpdate: any, comments: any, etaDate: any): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/purchase/consumable/request/status/update", {
      RequestId: requestId,
      RequestStatus: statusToUpdate,
      Comments: comments,
      ETA: etaDate
    }).pipe(
      catchError(this.handleError('update consumable request status'))
    );
  }

  exportPurchaseOrdersByvendorId(vendorId: any): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/reports/purchase/" + vendorId)
      .pipe(
        catchError(this.handleError('export purchase orders'))
      );
  }

  exportConsumableRequestByvendorId(vendorId: any, locationId: any): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/reports/purchase/consumable/request/vendor/" + vendorId + "/" + locationId)
      .pipe(
        catchError(this.handleError('export consumable requests by vendor id'))
      );
  }

  getPurchases(vendorId: any): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/purchase/vendor/" + vendorId)
      .pipe(
        catchError(this.handleError('purchase listing'))
      );
  }

  getConsumablesRequested(vendorId: any, locationId: any): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/purchase/consumable/request/vendor/" + vendorId + "/" + locationId)
      .pipe(
        catchError(this.handleError('consumable requests by vendor id and location id'))
      );
  }

  getNotificationsForAdmin(vendorId: any): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/notification/admin/" + vendorId)
      .pipe(
        catchError(this.handleError('notifications for admin'))
      );
  }

  getEventSummary(vendorId: any): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/campaign/events/summary", {
      vendorId: vendorId
    })
      .pipe(
        catchError(this.handleError('notifications'))
      );
  }

  getConsumablesRequestedByAdmin(vendorId: any): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/purchase/consumable/request/vendor/admin/" + vendorId)
      .pipe(
        catchError(this.handleError('consumables requested by admin'))
      );
  }


  getPurchaseById(purchaseId: any): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/purchase/" + purchaseId)
      .pipe(
        catchError(this.handleError('purchase info by id'))
      );
  }

  getConsumableRequestInfoById(requestId: any): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/purchase/consumable/request/info/" + requestId)
      .pipe(
        catchError(this.handleError('consumable request info by id'))
      );
  }

  getPendingPurchases(vendorId: any): any {
    return this.http.get<any>(this.appHandler.hostUrl + "/purchase/pending/" + vendorId)
      .pipe(
        catchError(this.handleError('pending purchase info by vendor id'))
      );
  }

}
