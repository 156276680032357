export const naveItemForCRMOnly = [
    {
        name: 'Dashboard',
        url: '/admin/crm-dashboard',
        icon: 'fa fa-tachometer',
        active: true,
        quickLinks: [
            {
                name: 'Campaigns',
                url: '/admin/campaigns',
                icon: 'fa fa-bullhorn',
                quickLinks: [
                    {
                        name: 'Campaigns',
                        url: '/admin/campaigns',
                        icon: 'fa fa-bullhorn',
                    },
                    {
                        name: 'SMS Templates',
                        url: '/admin/sms-template',
                        icon: 'fa fa-text-height'
                    },
                    {
                        name: 'SMS Statistics',
                        url: '/admin/campaign-sms-stats',
                        icon: 'fa fa-line-chart'
                    }
                ]
            },
            {
                name: 'SMS Templates',
                url: '/admin/sms-template',
                icon: 'fa fa-text-height'
            },
            {
                name: 'SMS Statistics',
                url: '/admin/campaign-sms-stats',
                icon: 'fa fa-line-chart'
            },
        ]
    },
    {
        name: 'Campaigns',
        url: '/admin/campaigns',
        icon: 'fa fa-bullhorn',
        active: true,
        quickLinks: [
            {
                name: 'Add Campaign',
                url: '/admin/campaign-info',
                icon: 'fa fa-bullhorn',
                quickLinks: [
                    {
                        name: 'Campaigns',
                        url: '/admin/campaigns',
                        icon: 'fa fa-bullhorn',
                    },
                    {
                        name: 'SMS Templates',
                        url: '/admin/sms-template',
                        icon: 'fa fa-text-height'
                    },
                    {
                        name: 'SMS Statistics',
                        url: '/admin/campaign-sms-stats',
                        icon: 'fa fa-line-chart'
                    }
                ]
            },
            {
                name: 'SMS Templates',
                url: '/admin/sms-template',
                icon: 'fa fa-text-height'
            },
            {
                name: 'SMS Templates',
                url: '/admin/sms-template',
                icon: 'fa fa-text-height'
            }
        ]
    },
    // {
    //     name: 'BBLC Stages',
    //     url: '/admin/bblc-config',
    //     icon: 'fa fa-list-ol',
    //     active: true,
    //     quickLinks: [
    //         {
    //             name: 'Add BBLC Stage',
    //             url: '/admin/bblc-stage',
    //             icon: 'fa fa-retweet',
    //             quickLinks: [
    //                 {
    //                     name: 'BBLC SMS',
    //                     url: '/admin/bblc-template',
    //                     icon: 'fa fa-retweet',
    //                 },
    //                 {
    //                     name: 'Campaign Templates',
    //                     url: '/admin/sms-template',
    //                     icon: 'fa fa-bullhorn'
    //                 },
    //                 {
    //                     name: 'BBLC Stages',
    //                     url: '/admin/bblc-config',
    //                     icon: 'fa fa-list-ol'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Campaign Templates',
    //             url: '/admin/sms-template',
    //             icon: 'fa fa-bullhorn'
    //         },
    //         {
    //             name: 'Anniversary Templates',
    //             url: '/admin/anniversary-template',
    //             icon: 'fa fa-calendar',
    //         }
    //     ]
    // },
    {
        name: 'Customers',
        url: '/admin/customers',
        icon: 'fa fa-user-circle-o',
        active: true,
        quickLinks: [
            {
                name: 'Add Customer',
                url: '/admin/customer-info',
                icon: 'fa fa-user-circle-o',
                quickLinks: [
                    {
                        name: 'Customers',
                        url: '/admin/customers',
                        icon: 'fa fa-user-circle-o'
                    },
                    {
                        name: 'Import Customer',
                        url: '/admin/customer-import',
                        icon: 'fa fa-user-circle-o'
                    },
                    {
                        name: 'Campaigns',
                        url: '/admin/campaigns',
                        icon: 'fa fa-bullhorn'
                    }
                ]
            },
            {
                name: 'Import Customer',
                url: '/admin/customer-import',
                icon: 'fa fa-user-circle-o'
            },
            {
                name: 'Campaigns',
                url: '/admin/campaigns',
                icon: 'fa fa-bullhorn'
            }
        ]
    },
    // {
    //     name: 'Customer Import',
    //     url: '/admin/customer-import',
    //     icon: 'fa fa-cloud-upload',
    //     active: true,
    //     quickLinks: [
    //         {
    //             name: 'Add Campaign',
    //             url: '/admin/campaign-info',
    //             icon: 'fa fa-bullhorn',
    //             quickLinks: [
    //                 {
    //                     name: 'Campaigns',
    //                     url: '/admin/campaigns',
    //                     icon: 'fa fa-bullhorn',
    //                 },
    //                 {
    //                     name: 'SMS Templates',
    //                     url: '/admin/sms-template',
    //                     icon: 'fa fa-text-height'
    //                 },
    //                 {
    //                     name: 'SMS Statistics',
    //                     url: '/admin/campaign-sms-stats',
    //                     icon: 'fa fa-line-chart'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'SMS Templates',
    //             url: '/admin/sms-template',
    //             icon: 'fa fa-text-height'
    //         },
    //         {
    //             name: 'SMS Statistics',
    //             url: '/admin/campaign-sms-stats',
    //             icon: 'fa fa-line-chart'
    //         }
    //     ]
    // },
    {
        name: 'Bulk SMS',
        url: '/admin/bulksms-history',
        icon: 'fa fa-commenting',
        active: true,
        quickLinks: [
            {
                name: 'Send Bulk SMS',
                url: '/admin/bulk-sms',
                icon: 'fa fa-commenting',
                quickLinks: [
                    {
                        name: 'Campaigns',
                        url: '/admin/campaigns',
                        icon: 'fa fa-bullhorn',
                    },
                    {
                        name: 'Bulk SMS Templates',
                        url: '/admin/bulksms-template',
                        icon: 'fa fa-text-height'
                    },
                    {
                        name: 'Bulk SMS History',
                        url: '/admin/bulksms-history',
                        icon: 'fa fa-history'
                    }
                ]
            },
            {
                name: 'SMS Statistics',
                url: '/admin/campaign-sms-stats',
                icon: 'fa fa-line-chart'
            },
            {
                name: 'SMS Templates',
                url: '/admin/bulksms-template',
                icon: 'fa fa-text-height'
            }
        ]
    },
    {
        name: 'BBLC Stages',
        url: '/admin/bblc-config',
        icon: 'fa fa-sort-numeric-asc',
        active: true,
        quickLinks: [
            {
                name: 'Add BBLC Stage',
                url: '/admin/bblc-stage',
                icon: 'fa fa-cogs',
                quickLinks: [
                    {
                        name: 'BBLC Stages',
                        url: '/admin/bblc-config',
                        icon: 'fa fa-sort-numeric-asc',
                    },
                    {
                        name: 'SMS Templates',
                        url: '/admin/sms-template',
                        icon: 'fa fa-text-height'
                    },
                    {
                        name: 'Bulk SMS History',
                        url: '/admin/bulksms-history',
                        icon: 'fa fa-history'
                    }
                ]
            },
            {
                name: 'SMS Statistics',
                url: '/admin/campaign-sms-stats',
                icon: 'fa fa-line-chart'
            },
            {
                name: 'SMS Templates',
                url: '/admin/sms-template',
                icon: 'fa fa-text-height'
            }
        ]
    },
    {
        name: 'SMS Templates',
        url: '/templates',
        icon: 'fa fa-cogs',
        active: true,
        children: [
            {
                name: 'Campaign',
                url: '/admin/sms-template',
                icon: 'fa fa-bullhorn',
                active: true,
                quickLinks: [
                    {
                        name: 'Add Campaign SMS Template',
                        url: '/admin/sms-template-info',
                        icon: 'fa fa-bullhorn',
                        quickLinks: [
                            {
                                name: 'Campaign Templates',
                                url: '/admin/sms-template',
                                icon: 'fa fa-bullhorn'
                            },
                            {
                                name: 'Anniversay Templates',
                                url: '/admin/anniversary-template',
                                icon: 'fa fa-calendar',
                            },
                            {
                                name: 'Birthday Templates',
                                url: '/admin/bday-template',
                                icon: 'fa fa-birthday-cake',
                            }
                        ]
                    },
                    {
                        name: 'Anniversay Templates',
                        url: '/admin/anniversary-template',
                        icon: 'fa fa-calendar',
                    },
                    {
                        name: 'Birthday Templates',
                        url: '/admin/bday-template',
                        icon: 'fa fa-birthday-cake',
                    }
                ]
            },
            {
                name: 'Anniversary',
                url: '/admin/anniversary-template',
                icon: 'fa fa-calendar',
                active: true,
                quickLinks: [
                    {
                        name: 'Add Anniversary SMS Template',
                        url: '/admin/anniversary-template-info',
                        icon: 'fa fa-calendar',
                        quickLinks: [
                            {
                                name: 'Anniversary Templates',
                                url: '/admin/anniversary-template',
                                icon: 'fa fa-calendar',
                            },
                            {
                                name: 'Birthday Templates',
                                url: '/admin/bday-template',
                                icon: 'fa fa-birthday-cake',
                            },
                            {
                                name: 'Campaign Templates',
                                url: '/admin/sms-template',
                                icon: 'fa fa-bullhorn'
                            }
                        ]
                    },
                    {
                        name: 'Birthday Templates',
                        url: '/admin/bday-template',
                        icon: 'fa fa-birthday-cake',
                    },
                    {
                        name: 'Campaign Templates',
                        url: '/admin/sms-template',
                        icon: 'fa fa-bullhorn'
                    }
                ]
            },
            {
                name: 'Birthday',
                url: '/admin/bday-template',
                icon: 'fa fa-birthday-cake',
                active: true,
                quickLinks: [
                    {
                        name: 'Add Birthday SMS Template',
                        url: '/admin/bday-template-info',
                        icon: 'fa fa-birthday-cake',
                        quickLinks: [
                            {
                                name: 'Birthday Templates',
                                url: '/admin/bday-template',
                                icon: 'fa fa-birthday-cake',
                            },
                            {
                                name: 'Campaign Templates',
                                url: '/admin/sms-template',
                                icon: 'fa fa-bullhorn'
                            },
                            {
                                name: 'Anniversary Templates',
                                url: '/admin/anniversary-template',
                                icon: 'fa fa-calendar',
                            }
                        ]
                    },
                    {
                        name: 'Campaign Templates',
                        url: '/admin/sms-template',
                        icon: 'fa fa-bullhorn'
                    },
                    {
                        name: 'Anniversary Templates',
                        url: '/admin/anniversary-template',
                        icon: 'fa fa-calendar',
                    }
                ]
            },
            {
                name: 'BBLC SMS',
                url: '/admin/bblc-template',
                icon: 'fa fa-retweet',
                active: true,
                quickLinks: [
                    {
                        name: 'Add BBLC SMS Template',
                        url: '/admin/bblc-template-info',
                        icon: 'fa fa-retweet',
                        quickLinks: [
                            {
                                name: 'BBLC SMS',
                                url: '/admin/bblc-template',
                                icon: 'fa fa-retweet',
                            },
                            {
                                name: 'Campaign Templates',
                                url: '/admin/sms-template',
                                icon: 'fa fa-bullhorn'
                            },
                            {
                                name: 'Anniversary Templates',
                                url: '/admin/anniversary-template',
                                icon: 'fa fa-calendar',
                            }
                        ]
                    },
                    {
                        name: 'Campaign Templates',
                        url: '/admin/sms-template',
                        icon: 'fa fa-bullhorn'
                    },
                    {
                        name: 'Anniversary Templates',
                        url: '/admin/anniversary-template',
                        icon: 'fa fa-calendar',
                    }
                ]
            },
            {
                name: 'Bulk SMS',
                url: '/admin/bulksms-template',
                icon: 'fa fa-list-alt',
                active: true,
                quickLinks: [
                    {
                        name: 'Add Bulk SMS Template',
                        url: '/admin/bulksms-template-info',
                        icon: 'fa fa-list-alt',
                        quickLinks: [
                            {
                                name: 'Bulk SMS Templates',
                                url: '/admin/bulksms-template',
                                icon: 'fa fa-list-alt'
                            },
                            {
                                name: 'Campaign Templates',
                                url: '/admin/sms-template',
                                icon: 'fa fa-bullhorn'
                            },
                            {
                                name: 'Anniversary Templates',
                                url: '/admin/anniversary-template',
                                icon: 'fa fa-calendar',
                            }
                        ]
                    },
                    {
                        name: 'Campaign Templates',
                        url: '/admin/sms-template',
                        icon: 'fa fa-bullhorn'
                    },
                    {
                        name: 'Anniversary Templates',
                        url: '/admin/anniversary-template',
                        icon: 'fa fa-calendar',
                    }
                ]
            }
        ]
    },
    {
        name: 'Reports',
        url: '/admin/crm-reports',
        icon: 'fa fa-line-chart',
        active: true,
        quickLinks: [
            {
                name: 'BBLC Stages',
                url: '/admin/bblc-config',
                icon: 'fa fa-sort-numeric-asc'
            },
            {
                name: 'Campaigns',
                url: '/admin/campaigns',
                icon: 'fa fa-bullhorn'
            },
            {
                name: 'Bulk SMS History',
                url: '/admin/bulksms-history',
                icon: 'fa fa-history'
            }
        ]
    },
    // {
    //     name: 'Reports',
    //     url: '/reports',
    //     icon: 'fa fa-pie-chart',
    //     active: true,
    //     children: [
    //         {
    //             name: 'SMS Statistics',
    //             url: '/admin/campaign-sms-stats',
    //             icon: 'fa fa-line-chart',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Add SMS Template',
    //                     url: '/admin/sms-template-info',
    //                     icon: 'fa fa-text-height',
    //                     quickLinks: [
    //                         {
    //                             name: 'SMS Templates',
    //                             url: '/admin/sms-template',
    //                             icon: 'fa fa-text-height'
    //                         },
    //                         {
    //                             name: 'Campaigns',
    //                             url: '/admin/campaigns',
    //                             icon: 'fa fa-bullhorn',
    //                         },
    //                         {
    //                             name: 'Bulk SMS History',
    //                             url: '/admin/bulksms-history',
    //                             icon: 'fa fa-history'
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     name: 'Campaigns',
    //                     url: '/admin/campaigns',
    //                     icon: 'fa fa-bullhorn',
    //                 },
    //                 {
    //                     name: 'Bulk SMS History',
    //                     url: '/admin/bulksms-history',
    //                     icon: 'fa fa-history'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'BBLC Report',
    //             url: '/admin/bblc-report',
    //             icon: 'fa fa-retweet',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Add SMS Template',
    //                     url: '/admin/sms-template-info',
    //                     icon: 'fa fa-text-height',
    //                     quickLinks: [
    //                         {
    //                             name: 'SMS Templates',
    //                             url: '/admin/sms-template',
    //                             icon: 'fa fa-text-height'
    //                         },
    //                         {
    //                             name: 'Campaigns',
    //                             url: '/admin/campaigns',
    //                             icon: 'fa fa-bullhorn',
    //                         },
    //                         {
    //                             name: 'Bulk SMS History',
    //                             url: '/admin/bulksms-history',
    //                             icon: 'fa fa-history'
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     name: 'Campaigns',
    //                     url: '/admin/campaigns',
    //                     icon: 'fa fa-bullhorn',
    //                 },
    //                 {
    //                     name: 'Bulk SMS History',
    //                     url: '/admin/bulksms-history',
    //                     icon: 'fa fa-history'
    //                 }
    //             ]
    //         }
    //     ]
    // },
    {
        name: 'My Profile',
        url: '/admin/profile',
        icon: 'fa fa-user-circle',
        active: true,
        quickLinks: [
            {
                name: 'Send Bulk SMS',
                url: '/admin/bulk-sms',
                icon: 'fa fa-commenting',
                quickLinks: [
                    {
                        name: 'Campaigns',
                        url: '/admin/campaigns',
                        icon: 'fa fa-bullhorn',
                    },
                    {
                        name: 'SMS Templates',
                        url: '/admin/sms-template',
                        icon: 'fa fa-text-height'
                    },
                    {
                        name: 'Bulk SMS History',
                        url: '/admin/bulksms-history',
                        icon: 'fa fa-history'
                    }
                ]
            },
            {
                name: 'SMS Statistics',
                url: '/admin/campaign-sms-stats',
                icon: 'fa fa-line-chart'
            },
            {
                name: 'SMS Templates',
                url: '/admin/sms-template',
                icon: 'fa fa-text-height'
            }
        ]
    }
];