import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandlerService } from './http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { ApplicationHandlerService } from '../helpers_and_handlers/application-handler.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Campaign } from '../models/campaign';
import { BulkSMS } from '../models/bulksms';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  private handleError: HandleError;
  constructor(
    public http: HttpClient,
    public appHandler: ApplicationHandlerService,
    httpErrorHandler: HttpErrorHandlerService) {
    this.handleError = httpErrorHandler.createHandleError('Campaign Service');
  }

  addCampaign(campaignInfo: Campaign): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/campaign/add", campaignInfo)
      .pipe(
        catchError(this.handleError('add campaign'))
      );
  }

  updateCampaign(campaignInfo: Campaign): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/campaign/update", campaignInfo)
      .pipe(
        catchError(this.handleError('update campaign by campaign id'))
      );
  }

  sendBulkSMS(bulkSMSInfo: BulkSMS): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/campaign/sms/bulk", bulkSMSInfo)
      .pipe(
        catchError(this.handleError('bulk sms by filters'))
      );
  }

  getSMSCount(bulkSMSInfo: BulkSMS): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/campaign/sms/count/estimate", bulkSMSInfo)
      .pipe(
        catchError(this.handleError('sms count by filters'))
      );
  }

  getCampaigns(vendorId: any, locationId: any, activeOnly: any): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl
      + "/campaign/list", {
      vendorId: vendorId,
      locationId: locationId,
      activeOnly: activeOnly
    })
      .pipe(
        catchError(this.handleError('campaign list by vendor id'))
      );
  }

  getCampaignById(campaignId: any): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/campaign/info/" + campaignId)
      .pipe(
        catchError(this.handleError('campaign info by campaign id'))
      );
  }

  getCampaignsToday(vendorId: any): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/campaign/today", {
      vendorId: vendorId
    })
      .pipe(
        catchError(this.handleError('campaigns today'))
      );
  }

  getCustomFiltersByCampaignId(campaignId: any): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/filter/campaign/" + campaignId)
      .pipe(
        catchError(this.handleError('custom filters by campaign id'))
      );
  }

  getBulkSMSHistoryByVendorId(vendorId: any, locationIds: any[]): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/campaign/history/bulksms", {
      vendorId: vendorId,
      locationIds: locationIds
    })
      .pipe(
        catchError(this.handleError('bulk sms history by vendor and location ids'))
      );
  }
}
