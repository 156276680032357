import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import 'rxjs/add/operator/do';
// import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApplicationHandlerService } from '../helpers_and_handlers/application-handler.service';

import { SlimLoadingBarService } from 'ng2-slim-loading-bar';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService {
  public cookieservice: any;
  constructor(private slimLoadingBarService: SlimLoadingBarService, private spinner: NgxSpinnerService,
    public router: Router, public cookieService: CookieService, private appHandler: ApplicationHandlerService) {
    this.cookieservice = cookieService;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // this.spinnerService.show();
    // this.slimLoadingBarService.start(() => {
    // });
    if (req.url.indexOf("customers/SearchCustomer") < 0 && req.url.indexOf("api/customers/search") < 0 &&
      req.url.indexOf("inventories/SearchLocationProduct") < 0 &&
      req.url.indexOf("app/current/version") < 0) {
      this.spinner.show();
    }
    if (req.url.indexOf("/user/checkEmail") < 0 && req.url.indexOf("/user/checkPhoneNumber") < 0) {
      // this.ngxService.start();
    }
    let modified = req.clone({ setParams: {} });
    if (req.url.indexOf("OAuth") < 0) {
      modified = req.clone({ setHeaders: { 'Authorization': this.cookieservice.get('token') } });
      // modified = req.clone({ setParams: { 'token': this.cookieservice.get('token') } });
    }
    else if (req.url.indexOf("Logo") < 0 && req.url.indexOf("image/upload")) {
      modified = req.clone({ setHeaders: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' } });
    }
    else if (req.url.indexOf("Report") < 0) {
      modified = req.clone({ setHeaders: { 'Content-Type': 'text/csv' } });
    }
    return next.handle(modified).do(
      (event: HttpEvent<any>) => {
        // this.spinner.hide();
        if (event instanceof HttpResponse) {
          this.spinner.hide();
          // this.ngxService.stop();
          // if (req.url.indexOf("customers/SearchCustomer") < 0 && req.url.indexOf("inventories/SearchLocationProduct") < 0) {
          // this.spinner.hide();
          // }
          this.slimLoadingBarService.complete();
        }
      },
      (err: any) => {
        // this.spinnerService.hide();
        // this.ngxService.stop();
        // if (req.url.indexOf("customers/SearchCustomer") < 0 &&
        //   req.url.indexOf("inventories/SearchLocationProduct") < 0) {
        this.spinner.hide();
        // }
        this.slimLoadingBarService.complete();
        if (req.headers.get('handleError') === 'onService') {
        } else {
        }
        if (err.status == 0 || err.status == 401
          // || err.status == 500
        ) {
          this.appHandler.openSnackBar("Session expired, Please login again.", 'OK', 'danger');
          this.appHandler.removeAccessToken();
          this.router.navigate(['/login']);
        }
      }
    );
  }
}
