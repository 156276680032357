import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandlerService } from './http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { ApplicationHandlerService } from '../helpers_and_handlers/application-handler.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private handleError: HandleError;
  constructor(
    public http: HttpClient,
    public appHandler: ApplicationHandlerService,
    httpErrorHandler: HttpErrorHandlerService) {
    this.handleError = httpErrorHandler.createHandleError('Payment Service');
  }

  createOrderId(transaction: any): Observable<any> {
    let payload = {
      LocationId: transaction.locationId,
      Amount: transaction.amount,
      Currency: 'INR',
      BusinessName: transaction.businessName,
      Description: 'payment for sms credit',
      CreditCategory: 'SMS',
      UnitPrice: transaction.unitPrice,
      GstCollected: transaction.gstCollected,
    }
    return this.http.post<any>(this.appHandler.hostUrl + "/transaction/order/create", payload)
      .pipe(
        catchError(this.handleError('order id creation'))
      );
  }
}
