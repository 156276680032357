import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandlerService } from './http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { ApplicationHandlerService } from '../helpers_and_handlers/application-handler.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Billing } from '../models/billing';
import { CustomBillValue } from '../models/custombillvalue';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  private handleError: HandleError;
  constructor(
    public http: HttpClient,
    public appHandler: ApplicationHandlerService,
    httpErrorHandler: HttpErrorHandlerService) {
    this.handleError = httpErrorHandler.createHandleError('Billing Service');
  }

  addBill(billInfo: Billing): Observable<any> {
    var new_bill = this.appHandler.hostUrl + "/billing/AddBill";
    if (billInfo.BillId != null) {
      new_bill = this.appHandler.hostUrl + "/billing/CompleteHoldedBill";
    }
    return this.http.post<any>(new_bill, billInfo)
      .pipe(
        catchError(this.handleError('add bill'))
      );
  }

  cancelBill(billId: any, status: any): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/billing/status/update/" + billId + "/" + status)
      .pipe(
        catchError(this.handleError('update bill status'))
      );
  }

  updateBill(billInfo: Billing): Observable<any> {
    return this.http.put<any>(this.appHandler.hostUrl + "/billing/UpdateBill/" + billInfo.BillId, billInfo)
      .pipe(
        catchError(this.handleError('update bill by bill id'))
      );
  }

  sendBillSMSByBillId(billId: any): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/billing/bills/sms/" + billId, {})
      .pipe(
        catchError(this.handleError('send bill sms by bill id'))
      );
  }

  sendMembershipBillSMSByBillId(membershipBillId: any): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/billing/membership/bill/sms/" + membershipBillId)
      .pipe(
        catchError(this.handleError('send membership bill sms by bill id'))
      );
  }

  getBillsByDateRange(vendoorId, locationId, fromDate, toDate): Observable<any> {
    let from_date = new Date(fromDate).toDateString();
    let to_date = new Date(toDate).toDateString();

    return this.http.post<any>(this.appHandler.hostUrl + "/billing/bills/range",
      {
        locationId: locationId,
        fromDate: from_date,
        toDate: to_date,
        vendorId: this.appHandler.getVendorId()
      })
      .pipe(
        catchError(this.handleError('bills by date range'))
      );
  }

  getMembershipBillsByDateRange(vendorId, locationId, fromDate, toDate): Observable<any> {
    let from_date = new Date(fromDate).toDateString();
    let to_date = new Date(toDate).toDateString();

    return this.http.post<any>(this.appHandler.hostUrl + "/billing/bills/membership/range",
      {
        locationId: locationId,
        fromDate: from_date,
        toDate: to_date,
        vendorId: this.appHandler.getVendorId()
      })
      .pipe(
        catchError(this.handleError('membership bills by date range'))
      );
  }

  getBillStatisticsDateRange(vendorId, locationId, fromDate, toDate): Observable<any> {

    let from_date = new Date(fromDate).toDateString();
    let to_date = new Date(toDate).toDateString();

    return this.http.post<any>(this.appHandler.hostUrl + "/billing/statistics/range",
      {
        locationId: locationId,
        fromDate: from_date,
        toDate: to_date,
        vendorId: vendorId
      })
      .pipe(
        catchError(this.handleError('bill statistics by date range'))
      );
  }

  addBillCustomValues(billId: number, customBillValues: CustomBillValue[]): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/customfield/bill/" + billId, customBillValues)
      .pipe(
        catchError(this.handleError('add custom bill value by bill id'))
      );
  }

  getBillsOnHold(vendorId: string, locationId: string): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/billing/GetBillsByStatus/"
      + vendorId + "/" + locationId + "/" + this.appHandler.BILL_ON_HOLD)
      .pipe(
        catchError(this.handleError('get bills on hold'))
      );
  }

  getBillItemsByBillId(billId: any): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/billing/bill/items/"
      + billId)
      .pipe(
        catchError(this.handleError('get bill items by bill id'))
      );
  }

  getBillInfoByAppointmentId(appointmentId: any) {
    return this.http.get<any>(this.appHandler.hostUrl + "/billing/info/appointment/"
      + appointmentId)
      .pipe(
        catchError(this.handleError('get bill info by appointment id'))
      );
  }

  printBill(vendorId: any, locationId: any, billId: any): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/billing/PrintBill/" + vendorId + "/" + locationId + "/" + billId)
      .pipe(
        catchError(this.handleError('get populated bill print template'))
      );
  }

  printMembershipBill(membershipBillId: any): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/billing/membership/bill/print/" + membershipBillId)
      .pipe(
        catchError(this.handleError('get populated bill print template'))
      );
  }

  sendBillSMS(billNumber: string): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/billing/SendBillSMS/" + billNumber)
      .pipe(
        catchError(this.handleError('send bill sms'))
      );
  }

  printBillByBillNumber(billNumber: string): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/public/PrintBillByBillNumber/" + billNumber)
      .pipe(
        catchError(this.handleError('get populated bill print template by bill number'))
      );
  }

  getBillByBillNumber(billNumber: string): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/billing/GetBillByInvoiceNumber/" + billNumber)
      .pipe(
        catchError(this.handleError('get bill info by bill number'))
      );
  }

  getMembershipBillByBillNumber(billNumber: string): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/billing/membership/invoice/" + billNumber)
      .pipe(
        catchError(this.handleError('get membership bill info by bill number'))
      );
  }

  getMembershipBillByMobileNumber(mobileNumber: string): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/billing/membership/bills/mobile/" + mobileNumber +
      "/" + this.appHandler.getVendorId())
      .pipe(
        catchError(this.handleError('get membership bill list by mobile number'))
      );
  }

  getBillsByCustomerMobileNumber(mobileNumber: string, locationId: any): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/billing/GetBillsByMobileNumber", {
      Mobile: mobileNumber,
      vendorId: this.appHandler.getVendorId(),
      locationId: locationId
    })
      .pipe(
        catchError(this.handleError('get bill list by customer mobile number'))
      );
  }

  getMembershipBillsByCustomerMobileNumber(mobileNumber: string): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/billing/membership/bills/mobile/" + mobileNumber + "/" + this.appHandler.getVendorId())
      .pipe(
        catchError(this.handleError('get membership bill list by customer mobile number'))
      );
  }

  returnBillItemsByBillId(billId: any, billItems: any[]): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/billing/ReturnItems/" + billId, billItems)
      .pipe(
        catchError(this.handleError('return bill items by bill id'))
      );
  }
}

