import { Component, OnInit } from '@angular/core';
import { BillingService } from '../../services/billing.service';
import { ApplicationHandlerService } from '../../helpers_and_handlers/application-handler.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-customer-bill',
  templateUrl: './customer-bill.component.html',
  styleUrls: ['./customer-bill.component.scss']
})
export class CustomerBillComponent implements OnInit {

  billNumber: string; billNotFound: boolean = false;
  constructor(public billingService: BillingService, public appHandler: ApplicationHandlerService, public router: Router, private route: ActivatedRoute) { }

  printBill(billnumber: string) {
    this.billNotFound = false;
    this.billingService.printBillByBillNumber(billnumber)
      .subscribe(response => {
        if (response.status == this.appHandler.STATUS_CODE_SUCCESS) {
          if (response.data != null) {
            document.getElementById("billview").innerHTML = response.data;
          }
          else {
            this.billNotFound = true;
          }
        }
        else {
          this.billNotFound = true;
        }
      });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params != null) {
        this.billNumber = params.billnumber;
        if (this.billNumber != null && this.billNumber.length > 0) {
          this.printBill(this.billNumber);
        }
      }
    });
  }

}
