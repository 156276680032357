export const naveItemForReportAdmin = [
    {
        name: 'Reports',
        url: '/admin/reports',
        icon: 'fa fa-line-chart',
        active: true,
        quickLinks: [

        ]
    },
    {
        title: true,
        name: 'CRM'
    },
    // {
    //     name: 'Dashboard',
    //     url: '/admin/crm-dashboard',
    //     icon: 'fa fa-tachometer',
    //     active: true,
    //     quickLinks: [
    //         {
    //             name: 'Campaigns',
    //             url: '/admin/campaigns',
    //             icon: 'fa fa-bullhorn',
    //             quickLinks: [
    //                 {
    //                     name: 'Campaigns',
    //                     url: '/admin/campaigns',
    //                     icon: 'fa fa-bullhorn',
    //                 },
    //                 {
    //                     name: 'SMS Templates',
    //                     url: '/admin/sms-template',
    //                     icon: 'fa fa-text-height'
    //                 },
    //                 {
    //                     name: 'Suppliers',
    //                     url: '/admin/suppliers',
    //                     icon: 'fa fa-user'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'SMS Templates',
    //             url: '/admin/sms-template',
    //             icon: 'fa fa-text-height'
    //         },
    //         {
    //             name: 'SMS Statistics',
    //             url: '/admin/campaign-sms-stats',
    //             icon: 'fa fa-line-chart'
    //         },
    //     ]
    // },
    // {
    //     name: 'Customers',
    //     url: '/admin/customers',
    //     icon: 'fa fa-user-circle-o',
    //     active: true,
    //     quickLinks: [
    //         {
    //             name: 'Add Customer',
    //             url: '/admin/customer-info',
    //             icon: 'fa fa-user-circle-o',
    //             quickLinks: [
    //                 {
    //                     name: 'Customers',
    //                     url: '/admin/customers',
    //                     icon: 'fa fa-user-circle-o'
    //                 },
    //                 {
    //                     name: 'Import Customer',
    //                     url: '/admin/customer-import',
    //                     icon: 'fa fa-user-circle-o'
    //                 },
    //                 {
    //                     name: 'Campaigns',
    //                     url: '/admin/campaigns',
    //                     icon: 'fa fa-bullhorn'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Import Customer',
    //             url: '/admin/customer-import',
    //             icon: 'fa fa-user-circle-o'
    //         },
    //         {
    //             name: 'Campaigns',
    //             url: '/admin/campaigns',
    //             icon: 'fa fa-bullhorn'
    //         }
    //     ]
    // },
    // {
    //     name: 'Events',
    //     url: '/admin/events',
    //     icon: 'fa fa-calendar',
    //     active: true,
    //     quickLinks: [
    //         {
    //             name: 'Dashboard',
    //             url: '/admin/crm-dashboard',
    //             icon: 'fa fa-tachometer',
    //             quickLinks: [
    //                 {
    //                     name: 'Campaigns',
    //                     url: '/admin/campaigns',
    //                     icon: 'fa fa-bullhorn',
    //                 },
    //                 {
    //                     name: 'Send Bulk SMS',
    //                     url: '/admin/bulk-sms',
    //                     icon: 'fa fa-commenting',
    //                 },
    //                 {
    //                     name: 'Customer Import',
    //                     url: '/admin/customer-import',
    //                     icon: 'fa fa-cloud-upload',
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Campaigns',
    //             url: '/admin/campaigns',
    //             icon: 'fa fa-bullhorn',
    //         },
    //         {
    //             name: 'Send Bulk SMS',
    //             url: '/admin/bulk-sms',
    //             icon: 'fa fa-commenting',
    //         }
    //     ]
    // },
    // {
    //     name: 'Campaigns',
    //     url: '/admin/campaigns',
    //     icon: 'fa fa-bullhorn',
    //     active: true,
    //     quickLinks: [
    //         {
    //             name: 'Add Campaign',
    //             url: '/admin/campaign-info',
    //             icon: 'fa fa-bullhorn',
    //             quickLinks: [
    //                 {
    //                     name: 'Campaigns',
    //                     url: '/admin/campaigns',
    //                     icon: 'fa fa-bullhorn',
    //                 },
    //                 {
    //                     name: 'Bulk SMS',
    //                     url: '/admin/bulksms-history',
    //                     icon: 'fa fa-commenting',
    //                 },
    //                 {
    //                     name: 'Send Bulk SMS',
    //                     url: '/admin/bulk-sms',
    //                     icon: 'fa fa-commenting',
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'SMS Templates',
    //             url: '/admin/sms-template',
    //             icon: 'fa fa-text-height'
    //         },
    //         {
    //             name: 'Suppliers',
    //             url: '/admin/suppliers',
    //             icon: 'fa fa-user'
    //         }
    //     ]
    // },
    // {
    //     name: 'Customer Import',
    //     url: '/admin/customer-import',
    //     icon: 'fa fa-cloud-upload',
    //     active: true,
    //     quickLinks: [
    //         {
    //             name: 'Add Campaign',
    //             url: '/admin/campaign-info',
    //             icon: 'fa fa-bullhorn',
    //             quickLinks: [
    //                 {
    //                     name: 'Campaigns',
    //                     url: '/admin/campaigns',
    //                     icon: 'fa fa-bullhorn',
    //                 },
    //                 {
    //                     name: 'SMS Templates',
    //                     url: '/admin/sms-template',
    //                     icon: 'fa fa-text-height'
    //                 },
    //                 {
    //                     name: 'SMS Statistics',
    //                     url: '/admin/campaign-sms-stats',
    //                     icon: 'fa fa-line-chart'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'SMS Templates',
    //             url: '/admin/sms-template',
    //             icon: 'fa fa-text-height'
    //         },
    //         {
    //             name: 'SMS Statistics',
    //             url: '/admin/campaign-sms-stats',
    //             icon: 'fa fa-line-chart'
    //         }
    //     ]
    // },
    // {
    //     name: 'Bulk SMS',
    //     url: '/admin/bulksms-history',
    //     icon: 'fa fa-commenting',
    //     active: true,
    //     quickLinks: [
    //         {
    //             name: 'Send Bulk SMS',
    //             url: '/admin/bulk-sms',
    //             icon: 'fa fa-commenting',
    //             quickLinks: [
    //                 {
    //                     name: 'Campaigns',
    //                     url: '/admin/campaigns',
    //                     icon: 'fa fa-bullhorn',
    //                 },
    //                 {
    //                     name: 'Bulk SMS Templates',
    //                     url: '/admin/bulksms-template',
    //                     icon: 'fa fa-text-height'
    //                 },
    //                 {
    //                     name: 'Bulk SMS History',
    //                     url: '/admin/bulksms-history',
    //                     icon: 'fa fa-history'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'SMS Statistics',
    //             url: '/admin/campaign-sms-stats',
    //             icon: 'fa fa-line-chart'
    //         },
    //         {
    //             name: 'Bulk SMS Templates',
    //             url: '/admin/bulksms-template',
    //             icon: 'fa fa-text-height'
    //         }
    //     ]
    // },
    // {
    //     name: 'BBLC Stages',
    //     url: '/admin/bblc-config',
    //     icon: 'fa fa-sort-numeric-asc',
    //     active: true,
    //     quickLinks: [
    //         {
    //             name: 'Add BBLC Stage',
    //             url: '/admin/bblc-stage',
    //             icon: 'fa fa-cogs',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'BBLC Stages',
    //                     url: '/admin/bblc-config',
    //                     icon: 'fa fa-sort-numeric-asc',
    //                 },
    //                 {
    //                     name: 'SMS Templates',
    //                     url: '/admin/sms-template',
    //                     icon: 'fa fa-text-height'
    //                 },
    //                 {
    //                     name: 'Bulk SMS History',
    //                     url: '/admin/bulksms-history',
    //                     icon: 'fa fa-history'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'SMS Statistics',
    //             url: '/admin/campaign-sms-stats',
    //             icon: 'fa fa-line-chart'
    //         },
    //         {
    //             name: 'SMS Templates',
    //             url: '/admin/sms-template',
    //             icon: 'fa fa-text-height'
    //         }
    //     ]
    // },
    // {
    //     name: 'SMS Templates',
    //     url: '/templates',
    //     icon: 'fa fa-cogs',
    //     active: true,
    //     children: [
    //         {
    //             name: 'Anniversary',
    //             url: '/admin/anniversary-template',
    //             icon: 'fa fa-calendar',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Add Anniversary SMS Template',
    //                     url: '/admin/anniversary-template-info',
    //                     icon: 'fa fa-calendar',
    //                     quickLinks: [
    //                         {
    //                             name: 'Anniversary Templates',
    //                             url: '/admin/anniversary-template',
    //                             icon: 'fa fa-calendar',
    //                         },
    //                         {
    //                             name: 'Birthday Templates',
    //                             url: '/admin/bday-template',
    //                             icon: 'fa fa-birthday-cake',
    //                         },
    //                         {
    //                             name: 'Campaign Templates',
    //                             url: '/admin/sms-template',
    //                             icon: 'fa fa-bullhorn'
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     name: 'Birthday Templates',
    //                     url: '/admin/bday-template',
    //                     icon: 'fa fa-birthday-cake',
    //                 },
    //                 {
    //                     name: 'Campaign Templates',
    //                     url: '/admin/sms-template',
    //                     icon: 'fa fa-bullhorn'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Birthday',
    //             url: '/admin/bday-template',
    //             icon: 'fa fa-birthday-cake',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Add Birthday SMS Template',
    //                     url: '/admin/bday-template-info',
    //                     icon: 'fa fa-birthday-cake',
    //                     quickLinks: [
    //                         {
    //                             name: 'Birthday Templates',
    //                             url: '/admin/bday-template',
    //                             icon: 'fa fa-birthday-cake',
    //                         },
    //                         {
    //                             name: 'Campaign Templates',
    //                             url: '/admin/sms-template',
    //                             icon: 'fa fa-bullhorn'
    //                         },
    //                         {
    //                             name: 'Anniversary Templates',
    //                             url: '/admin/anniversary-template',
    //                             icon: 'fa fa-calendar',
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     name: 'Campaign Templates',
    //                     url: '/admin/sms-template',
    //                     icon: 'fa fa-bullhorn'
    //                 },
    //                 {
    //                     name: 'Anniversary Templates',
    //                     url: '/admin/anniversary-template',
    //                     icon: 'fa fa-calendar',
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'BBLC SMS',
    //             url: '/admin/bblc-template',
    //             icon: 'fa fa-retweet',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Add BBLC SMS Template',
    //                     url: '/admin/bblc-template-info',
    //                     icon: 'fa fa-retweet',
    //                     quickLinks: [
    //                         {
    //                             name: 'BBLC SMS',
    //                             url: '/admin/bblc-template',
    //                             icon: 'fa fa-retweet',
    //                         },
    //                         {
    //                             name: 'Campaign Templates',
    //                             url: '/admin/sms-template',
    //                             icon: 'fa fa-bullhorn'
    //                         },
    //                         {
    //                             name: 'Anniversary Templates',
    //                             url: '/admin/anniversary-template',
    //                             icon: 'fa fa-calendar',
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     name: 'Campaign Templates',
    //                     url: '/admin/sms-template',
    //                     icon: 'fa fa-bullhorn'
    //                 },
    //                 {
    //                     name: 'Anniversary Templates',
    //                     url: '/admin/anniversary-template',
    //                     icon: 'fa fa-calendar',
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Bulk SMS',
    //             url: '/admin/bulksms-template',
    //             icon: 'fa fa-list-alt',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Add Bulk SMS Template',
    //                     url: '/admin/bulksms-template-info',
    //                     icon: 'fa fa-list-alt',
    //                     quickLinks: [
    //                         {
    //                             name: 'Bulk SMS Templates',
    //                             url: '/admin/bulksms-template',
    //                             icon: 'fa fa-list-alt'
    //                         },
    //                         {
    //                             name: 'Campaign Templates',
    //                             url: '/admin/sms-template',
    //                             icon: 'fa fa-bullhorn'
    //                         },
    //                         {
    //                             name: 'Anniversary Templates',
    //                             url: '/admin/anniversary-template',
    //                             icon: 'fa fa-calendar',
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     name: 'Campaign Templates',
    //                     url: '/admin/sms-template',
    //                     icon: 'fa fa-bullhorn'
    //                 },
    //                 {
    //                     name: 'Anniversary Templates',
    //                     url: '/admin/anniversary-template',
    //                     icon: 'fa fa-calendar',
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Campaign',
    //             url: '/admin/sms-template',
    //             icon: 'fa fa-bullhorn',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Add Campaign SMS Template',
    //                     url: '/admin/sms-template-info',
    //                     icon: 'fa fa-bullhorn',
    //                     quickLinks: [
    //                         {
    //                             name: 'Campaign Templates',
    //                             url: '/admin/sms-template',
    //                             icon: 'fa fa-bullhorn'
    //                         },
    //                         {
    //                             name: 'Anniversay Templates',
    //                             url: '/admin/anniversary-template',
    //                             icon: 'fa fa-calendar',
    //                         },
    //                         {
    //                             name: 'Birthday Templates',
    //                             url: '/admin/bday-template',
    //                             icon: 'fa fa-birthday-cake',
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     name: 'Anniversay Templates',
    //                     url: '/admin/anniversary-template',
    //                     icon: 'fa fa-calendar',
    //                 },
    //                 {
    //                     name: 'Birthday Templates',
    //                     url: '/admin/bday-template',
    //                     icon: 'fa fa-birthday-cake',
    //                 }
    //             ]
    //         }
    //     ]
    // },
    {
        name: 'Reports',
        url: '/admin/crm-reports',
        icon: 'fa fa-line-chart',
        active: true,
        quickLinks: []
    },
    {
        name: 'My Profile',
        url: '/admin/profile',
        icon: 'fa fa-user-circle',
        active: true,
        quickLinks: []
    },
    // report links allowed but not in side menu
    { url: '/admin/reports', active: false },
    { url: '/admin/outstanding', active: false },
    { url: '/admin/sales-report', active: false },
    { url: '/admin/detailed-sales-report', active: false },
    { url: '/admin/sale-list', active: false },
    { url: '/admin/staff-service', active: false },
    { url: '/admin/staff-customer', active: false },
    { url: '/admin/staff-collection', active: false },
    { url: '/admin/feedback-report', active: false },
    { url: '/admin/daily-sales', active: false },
    { url: '/admin/monthly-sales', active: false },
    { url: '/admin/top-sales', active: false },
    { url: '/admin/products-report', active: false },
    { url: '/admin/payments-report', active: false },
    { url: '/admin/profit-ratio', active: false },
    { url: '/admin/customer-report', active: false },
    { url: '/admin/finance-report', active: false },
    { url: '/admin/trend-analysis', active: false },
    { url: '/admin/reports/loyalty', active: false },

    { url: '/admin/campaign-sms-stats', active: false },
    { url: '/admin/bulk-sms', active: false },
    { url: '/admin/bulksms-history', active: false },

    { url: '/admin/crm-reports', active: false },
    { url: '/admin/crm-dashboard', active: false },
    { url: '/admin/bblc-report', active: false },
]