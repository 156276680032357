export const navItemForAdmin = [
    {
        name: 'Dashboard',
        url: '/admin/dashboard',
        icon: 'icon-speedometer',
        active: true,
        quickLinks: [
            {
                name: 'Products',
                url: '/admin/products',
                icon: 'fa fa-barcode'
            },
            {
                name: 'Expenses',
                url: '/admin/expenses',
                icon: 'fa fa-money'
            },
            {
                name: 'Report',
                url: '/admin/sales-report',
                icon: 'fa fa-pie-chart'
            }
        ]
    },
    // {
    //     title: true,
    //     name: 'Point Of Sale'
    // },
    {
        name: 'Inventories/Services',
        url: '/admin/products',
        icon: 'fa fa-barcode',
        active: true,
        quickLinks: [
            {
                name: 'Add Product',
                url: '/admin/product-info',
                icon: 'fa fa-plus-square-o',
                quickLinks: [
                    {
                        name: 'Products',
                        url: '/admin/products',
                        icon: 'fa fa-barcode'
                    },
                    {
                        name: 'Import Product',
                        url: '/admin/products/import',
                        icon: 'fa fa-cloud-upload'
                    },
                    {
                        name: 'Report',
                        url: '/admin/sales-report',
                        icon: 'fa fa-pie-chart'
                    }
                ]
            },
            {
                name: 'Import Product',
                url: '/admin/products/import',
                icon: 'fa fa-cloud-upload',
                quickLinks: [
                    {
                        name: 'Products',
                        url: '/admin/products',
                        icon: 'fa fa-barcode'
                    },
                    {
                        name: 'Add Product',
                        url: '/admin/product-info',
                        icon: 'fa fa-plus-square-o'
                    },
                    {
                        name: 'Report',
                        url: '/admin/sales-report',
                        icon: 'fa fa-pie-chart'
                    }
                ]
            },
            {
                name: 'Report',
                url: '/admin/sales-report',
                icon: 'fa fa-pie-chart',
                quickLinks: [
                    {
                        name: 'Products',
                        url: '/admin/products',
                        icon: 'fa fa-barcode'
                    },
                    {
                        name: 'Purchase Orders',
                        url: '/admin/purchases',
                        icon: 'fa fa-cart-arrow-down'
                    },
                    {
                        name: 'Goods Received',
                        url: '/admin/grns',
                        icon: 'fa fa-truck'
                    }
                ]
            }
        ]
    },
    {
        name: 'Packages',
        url: '/admin/package-list',
        icon: 'fa fa-inbox',
        active: true,
        quickLinks: [
            {
                name: 'Add Package',
                url: '/admin/package-view',
                icon: 'fa fa-inbox',
                quickLinks: [
                    {
                        name: 'Packages',
                        url: '/admin/package-list',
                        icon: 'fa fa-inbox'
                    }
                ]
            },
            {
                name: 'Products',
                url: '/admin/products',
                icon: 'fa fa-barcode'
            },
            {
                name: 'Import Product',
                url: '/admin/products/import',
                icon: 'fa fa-cloud-upload'
            }
        ]
    },
    {
        name: 'Memberships',
        url: '/admin/membership-list',
        icon: 'fa fa-address-card',
        active: true,
        quickLinks: [
            {
                name: 'Add Membership',
                url: '/admin/membership-view',
                icon: 'fa fa-address-card',
                quickLinks: [
                    {
                        name: 'Memberships',
                        url: '/admin/membership-list',
                        icon: 'fa fa-address-card'
                    }
                ]
            },
            {
                name: 'Packages',
                url: '/admin/package-list',
                icon: 'fa fa-inbox'
            },
            {
                name: 'Products',
                url: '/admin/products',
                icon: 'fa fa-barcode'
            }
        ]
    },
    {
        name: 'Purchase Orders',
        url: '/admin/purchases',
        icon: 'fa fa-cart-arrow-down',
        active: true,
        quickLinks: [
            {
                name: 'Add Purchase',
                url: '/admin/purchase-info',
                icon: 'fa fa-plus-square-o',
                quickLinks: [
                    {
                        name: 'Purchase Orders',
                        url: '/admin/purchases',
                        icon: 'fa fa-cart-arrow-down'
                    },
                    {
                        name: 'Goods Received',
                        url: '/admin/grns',
                        icon: 'fa fa-truck'
                    },
                    {
                        name: 'Products',
                        url: '/admin/products',
                        icon: 'fa fa-barcode'
                    }
                ]
            },
            {
                name: 'Goods Received',
                url: '/admin/grns',
                icon: 'fa fa-truck'
            },
            {
                name: 'Products',
                url: '/admin/products',
                icon: 'fa fa-barcode'
            }
        ]
    },
    {
        name: 'Consumable Request',
        url: '/admin/consumable-requests',
        icon: 'fa fa-shopping-cart',
        active: true,
        quickLinks: [
            {
                name: 'Add Purchase',
                url: '/admin/purchase-info',
                icon: 'fa fa-plus-square-o',
                quickLinks: [
                    {
                        name: 'Purchase Orders',
                        url: '/admin/purchases',
                        icon: 'fa fa-cart-arrow-down'
                    },
                    {
                        name: 'Goods Received',
                        url: '/admin/grns',
                        icon: 'fa fa-truck'
                    },
                    {
                        name: 'Products',
                        url: '/admin/products',
                        icon: 'fa fa-barcode'
                    }
                ]
            },
            {
                name: 'Goods Received',
                url: '/admin/grns',
                icon: 'fa fa-truck'
            },
            {
                name: 'Products',
                url: '/admin/products',
                icon: 'fa fa-barcode'
            }
        ]
    },
    {
        name: 'Goods Received',
        url: '/admin/grns',
        icon: 'fa fa-truck',
        active: true,
        quickLinks: [
            {
                name: 'Add Goods Received Note',
                url: '/admin/grn-info',
                icon: 'fa fa-truck',
                quickLinks: [
                    {
                        name: 'Goods Received',
                        url: '/admin/grns',
                        icon: 'fa fa-truck'
                    },
                    {
                        name: 'Purchase Orders',
                        url: '/admin/purchases',
                        icon: 'fa fa-cart-arrow-down'
                    },
                    {
                        name: 'Products',
                        url: '/admin/products',
                        icon: 'fa fa-barcode'
                    }
                ]
            },
            {
                name: 'Purchase Orders',
                url: '/admin/purchases',
                icon: 'fa fa-cart-arrow-down'
            },
            {
                name: 'Products',
                url: '/admin/products',
                icon: 'fa fa-barcode'
            }
        ]
    },
    {
        name: 'Reports',
        url: '/admin/reports',
        icon: 'fa fa-line-chart',
        active: true,
        quickLinks: [
            {
                name: 'Add Goods Received Note',
                url: '/admin/grn-info',
                icon: 'fa fa-truck',
                quickLinks: [
                    {
                        name: 'Goods Received',
                        url: '/admin/grns',
                        icon: 'fa fa-truck'
                    },
                    {
                        name: 'Purchase Orders',
                        url: '/admin/purchases',
                        icon: 'fa fa-cart-arrow-down'
                    },
                    {
                        name: 'Products',
                        url: '/admin/products',
                        icon: 'fa fa-barcode'
                    }
                ]
            },
            {
                name: 'Purchase Orders',
                url: '/admin/purchases',
                icon: 'fa fa-cart-arrow-down'
            },
            {
                name: 'Products',
                url: '/admin/products',
                icon: 'fa fa-barcode'
            }
        ]
    },
    // {
    //     name: 'Reports',
    //     url: '/reports',
    //     icon: 'fa fa-file-pdf-o',
    //     active: true,
    //     children: [
    //         {
    //             name: 'Sales Report',
    //             url: '/admin/sales-report',
    //             icon: 'fa fa-file-pdf-o',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Purchase vs Sale',
    //                     url: '/admin/profit-ratio',
    //                     icon: 'fa fa-line-chart'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Detailed Sales Report',
    //             url: '/admin/detailed-sales-report',
    //             icon: 'fa fa-pie-chart',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Sales Report',
    //                     url: '/admin/sales-report',
    //                     icon: 'fa fa-file-pdf-o',
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Sales Trends',
    //             url: '/admin/trend-analysis',
    //             icon: 'fa fa-line-chart',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Sales Report',
    //                     url: '/admin/sales-report',
    //                     icon: 'fa fa-file-pdf-o'
    //                 },
    //                 {
    //                     name: 'Detailed Sales Report',
    //                     url: '/admin/detailed-sales-report',
    //                     icon: 'fa fa-pie-chart'
    //                 }, {
    //                     name: 'Finance Report',
    //                     url: '/admin/finance-report',
    //                     icon: 'fa fa-money'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Finance Report',
    //             url: '/admin/finance-report',
    //             icon: 'fa fa-money',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Sales Report',
    //                     url: '/admin/sales-report',
    //                     icon: 'fa fa-file-pdf-o'
    //                 },
    //                 {
    //                     name: 'Purchase vs Sale',
    //                     url: '/admin/profit-ratio',
    //                     icon: 'fa fa-line-chart'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Purchase vs Sale',
    //             url: '/admin/profit-ratio',
    //             icon: 'fa fa-line-chart',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Sales Report',
    //                     url: '/admin/sales-report',
    //                     icon: 'fa fa-file-pdf-o'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Staff vs Service',
    //             url: '/admin/staff-service',
    //             icon: 'fa fa-bar-chart',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Sales Report',
    //                     url: '/admin/sales-report',
    //                     icon: 'fa fa-file-pdf-o'
    //                 },
    //                 {
    //                     name: 'Purchase vs Sale',
    //                     url: '/admin/profit-ratio',
    //                     icon: 'fa fa-line-chart'
    //                 },
    //                 {
    //                     name: 'Dashboard',
    //                     url: '/admin/dashboard',
    //                     icon: 'icon-speedometer'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Staff vs Customer',
    //             url: '/admin/staff-customer',
    //             icon: 'fa fa-users',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Sales Report',
    //                     url: '/admin/sales-report',
    //                     icon: 'fa fa-file-pdf-o'
    //                 },
    //                 {
    //                     name: 'Purchase vs Sale',
    //                     url: '/admin/profit-ratio',
    //                     icon: 'fa fa-line-chart'
    //                 },
    //                 {
    //                     name: 'Dashboard',
    //                     url: '/admin/dashboard',
    //                     icon: 'icon-speedometer'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Staff Collection',
    //             url: '/admin/staff-collection',
    //             icon: 'fa fa-money',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Sales Report',
    //                     url: '/admin/sales-report',
    //                     icon: 'fa fa-file-pdf-o'
    //                 },
    //                 {
    //                     name: 'Purchase vs Sale',
    //                     url: '/admin/profit-ratio',
    //                     icon: 'fa fa-line-chart'
    //                 },
    //                 {
    //                     name: 'Dashboard',
    //                     url: '/admin/dashboard',
    //                     icon: 'icon-speedometer'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Customer Report',
    //             url: '/admin/customer-report',
    //             icon: 'fa fa-user-o',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Sales Report',
    //                     url: '/admin/sales-report',
    //                     icon: 'fa fa-file-pdf-o'
    //                 },
    //                 {
    //                     name: 'Purchase vs Sale',
    //                     url: '/admin/profit-ratio',
    //                     icon: 'fa fa-line-chart'
    //                 },
    //                 {
    //                     name: 'Dashboard',
    //                     url: '/admin/dashboard',
    //                     icon: 'icon-speedometer'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Feedback Reports',
    //             url: '/admin/feedback-report',
    //             icon: 'fa fa-star-half-o',
    //             active: true
    //         },
    //         {
    //             name: 'Customer Enquiries',
    //             url: '/admin/customer-enquiries',
    //             icon: 'fa fa-question-circle',
    //             active: true
    //         },
    //         {
    //             name: 'Day Closure History',
    //             url: '/admin/closure-history',
    //             icon: 'fa fa-money',
    //             active: true,
    //         }
    //     ]
    // },
    {
        name: 'Expenses',
        url: '/admin/expenses',
        icon: 'fa fa-money',
        active: true,
        quickLinks: [
            {
                name: 'Add Expense',
                url: '/admin/expense-info',
                icon: 'fa fa-money',
                quickLinks: [
                    {
                        name: 'Expenses',
                        url: '/admin/expenses',
                        icon: 'fa fa-money'
                    },
                    {
                        name: 'Customers',
                        url: '/admin/customers',
                        icon: 'fa fa-user-circle-o'
                    },
                    {
                        name: 'Suppliers',
                        url: '/admin/suppliers',
                        icon: 'fa fa-user'
                    }
                ]
            },
            {
                name: 'Suppliers',
                url: '/admin/suppliers',
                icon: 'fa fa-user'
            },
            {
                name: 'Customers',
                url: '/admin/customers',
                icon: 'fa fa-list-alt'
            }
        ]
    },
    {
        name: 'Suppliers',
        url: '/admin/suppliers',
        icon: 'fa fa-user',
        active: true,
        quickLinks: [
            {
                name: 'Add Supplier',
                url: '/admin/supplier-info',
                icon: 'fa fa-user',
                quickLinks: [
                    {
                        name: 'Suppliers',
                        url: '/admin/suppliers',
                        icon: 'fa fa-user'
                    },
                    {
                        name: 'Customers',
                        url: '/admin/customers',
                        icon: 'fa fa-list-alt'
                    },
                    {
                        name: 'Purchase Orders',
                        url: '/admin/purchases',
                        icon: 'fa fa-cart-arrow-down'
                    }
                ]
            },
            {
                name: 'Customers',
                url: '/admin/customers',
                icon: 'fa fa-list-alt'
            },
            {
                name: 'Users',
                url: '/admin/users',
                icon: 'fa fa-users'
            }
        ]
    },
    {
        name: 'Customers',
        url: '/admin/customers',
        icon: 'fa fa-user-circle-o',
        active: true,
        quickLinks: [
            {
                name: 'Add Customer',
                url: '/admin/customer-info',
                icon: 'fa fa-user-circle-o',
                quickLinks: [
                    {
                        name: 'Customers',
                        url: '/admin/customers',
                        icon: 'fa fa-user-circle-o'
                    },
                    {
                        name: 'Suppliers',
                        url: '/admin/suppliers',
                        icon: 'fa fa-user'
                    },
                    {
                        name: 'Users',
                        url: '/admin/users',
                        icon: 'fa fa-users'
                    }
                ]
            },
            {
                name: 'Import Customer',
                url: '/admin/customer-import',
                icon: 'fa fa-user-circle-o'
            },
            {
                name: 'Suppliers',
                url: '/admin/suppliers',
                icon: 'fa fa-user'
            }
        ]
    },
    // {
    //     name: 'Customer Wallet',
    //     url: '/admin/customer/wallet',
    //     icon: 'fa fa-money',
    //     active: true,
    //     quickLinks: [
    //         {
    //             name: 'Add Customer',
    //             url: '/admin/customer-info',
    //             icon: 'fa fa-user-circle-o',
    //             quickLinks: [
    //                 {
    //                     name: 'Customers',
    //                     url: '/admin/customers',
    //                     icon: 'fa fa-user-circle-o'
    //                 },
    //                 {
    //                     name: 'Suppliers',
    //                     url: '/admin/suppliers',
    //                     icon: 'fa fa-user'
    //                 },
    //                 {
    //                     name: 'Users',
    //                     url: '/admin/users',
    //                     icon: 'fa fa-users'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Import Customer',
    //             url: '/admin/customer-import',
    //             icon: 'fa fa-user-circle-o'
    //         },
    //         {
    //             name: 'Suppliers',
    //             url: '/admin/suppliers',
    //             icon: 'fa fa-user'
    //         }
    //     ]
    // },
    {
        name: 'Users',
        url: '/admin/users',
        icon: 'fa fa-users',
        active: true,
        quickLinks: [
            {
                name: 'Add User',
                url: '/admin/user-info',
                icon: 'fa fa-users',
                quickLinks: [
                    {
                        name: 'Users',
                        url: '/admin/users',
                        icon: 'fa fa-users'
                    },
                    {
                        name: 'Customers',
                        url: '/admin/customers',
                        icon: 'fa fa-user-circle-o'
                    },
                    {
                        name: 'Suppliers',
                        url: '/admin/suppliers',
                        icon: 'fa fa-user'
                    }
                ]
            },
            {
                name: 'Customers',
                url: '/admin/customers',
                icon: 'fa fa-user-circle-o'
            },
            {
                name: 'Suppliers',
                url: '/admin/suppliers',
                icon: 'fa fa-user'
            }
        ]
    },
    {
        name: 'Staffs',
        url: '/admin/staffs',
        icon: 'fa fa-user-o',
        active: true,
        quickLinks: [
            {
                name: 'Add Staff',
                url: '/admin/staff-info',
                icon: 'fa fa-user-o',
                quickLinks: [
                    {
                        name: 'Staffs',
                        url: '/admin/staffs',
                        icon: 'fa fa-user-o'
                    },
                    {
                        name: 'Users',
                        url: '/admin/users',
                        icon: 'fa fa-user-circle-o'
                    },
                    {
                        name: 'Suppliers',
                        url: '/admin/suppliers',
                        icon: 'fa fa-user'
                    }
                ]
            },
            {
                name: 'Users',
                url: '/admin/users',
                icon: 'fa fa-user-circle-o'
            },
            {
                name: 'Suppliers',
                url: '/admin/suppliers',
                icon: 'fa fa-user'
            }
        ]
    },
    {
        name: 'Settings',
        url: '/admin/settings',
        icon: 'fa fa-cogs',
        active: true,
        quickLinks: [
            {
                name: 'Add Staff',
                url: '/admin/staff-info',
                icon: 'fa fa-user-o',
                quickLinks: [
                    {
                        name: 'Staffs',
                        url: '/admin/staffs',
                        icon: 'fa fa-user-o'
                    },
                    {
                        name: 'Users',
                        url: '/admin/users',
                        icon: 'fa fa-user-circle-o'
                    },
                    {
                        name: 'Suppliers',
                        url: '/admin/suppliers',
                        icon: 'fa fa-user'
                    }
                ]
            },
            {
                name: 'Users',
                url: '/admin/users',
                icon: 'fa fa-user-circle-o'
            },
            {
                name: 'Suppliers',
                url: '/admin/suppliers',
                icon: 'fa fa-user'
            }
        ]
        // children: [
        // {
        //     name: 'Bill Templates',
        //     url: '/admin/bill-templates',
        //     icon: 'fa fa-print',
        //     active: true,
        //     quickLinks: [
        //         {
        //             name: 'Add Bill Template',
        //             url: '/admin/bill-template-info',
        //             icon: 'fa fa-plus-square-o',
        //             quickLinks: [
        //                 {
        //                     name: 'Bill Templates',
        //                     url: '/admin/bill-templates',
        //                     icon: 'fa fa-print'
        //                 },
        //                 {
        //                     name: 'Purchase Orders',
        //                     url: '/admin/purchases',
        //                     icon: 'fa fa-cart-arrow-down'
        //                 },
        //                 {
        //                     name: 'Products',
        //                     url: '/admin/products',
        //                     icon: 'fa fa-barcode'
        //                 }
        //             ]
        //         },
        //         {
        //             name: 'Purchase Orders',
        //             url: '/admin/purchases',
        //             icon: 'fa fa-cart-arrow-down'
        //         },
        //         {
        //             name: 'Products',
        //             url: '/admin/products',
        //             icon: 'fa fa-barcode'
        //         }
        //     ]
        // },
        // {
        //     name: 'Bill SMS Templates',
        //     url: '/admin/bill-sms-templates',
        //     icon: 'fa fa-commenting-o',
        //     active: true,
        //     quickLinks: [
        //         {
        //             name: 'Add Bill SMS Template',
        //             url: '/admin/bill-sms-template-info',
        //             icon: 'fa fa-plus-square-o',
        //             quickLinks: [
        //                 {
        //                     name: 'Bill SMS Templates',
        //                     url: '/admin/bill-sms-templates',
        //                     icon: 'fa fa-commenting-o'
        //                 },
        //                 {
        //                     name: 'Bill Templates',
        //                     url: '/admin/bill-templates',
        //                     icon: 'fa fa-print'
        //                 },
        //                 {
        //                     name: 'Products',
        //                     url: '/admin/products',
        //                     icon: 'fa fa-barcode'
        //                 }
        //             ]
        //         },
        //         {
        //             name: 'Purchase Orders',
        //             url: '/admin/purchases',
        //             icon: 'fa fa-cart-arrow-down'
        //         },
        //         {
        //             name: 'Products',
        //             url: '/admin/products',
        //             icon: 'fa fa-barcode'
        //         }
        //     ]
        // },
        // {
        //     name: 'Feedback Questions',
        //     url: '/admin/feedback-questions',
        //     icon: 'fa fa-star-half-o',
        //     active: true,
        //     quickLinks: [
        //         {
        //             name: 'Add Bill Template',
        //             url: '/admin/bill-template-info',
        //             icon: 'fa fa-plus-square-o',
        //             quickLinks: [
        //                 {
        //                     name: 'Bill Templates',
        //                     url: '/admin/bill-templates',
        //                     icon: 'fa fa-print'
        //                 },
        //                 {
        //                     name: 'Purchase Orders',
        //                     url: '/admin/purchases',
        //                     icon: 'fa fa-cart-arrow-down'
        //                 },
        //                 {
        //                     name: 'Products',
        //                     url: '/admin/products',
        //                     icon: 'fa fa-barcode'
        //                 }
        //             ]
        //         },
        //         {
        //             name: 'Bill Templates',
        //             url: '/admin/bill-templates',
        //             icon: 'fa fa-print'
        //         },
        //         {
        //             name: 'Products',
        //             url: '/admin/products',
        //             icon: 'fa fa-barcode'
        //         }
        //     ]
        // },
        // {
        //     name: 'Enquiry Config',
        //     url: '/admin/enquiry-config',
        //     icon: 'fa fa-cogs',
        //     active: true,
        //     quickLinks: [
        //         {
        //             name: 'Feedback Questions',
        //             url: '/admin/feedback-questions',
        //             icon: 'fa fa-star-half-o',
        //         },
        //         {
        //             name: 'Bill Templates',
        //             url: '/admin/bill-templates',
        //             icon: 'fa fa-print',
        //             active: true,
        //         }
        //     ]
        // },
        // {
        //     name: 'Discount Config',
        //     url: '/admin/discount-config',
        //     icon: 'fa fa-percent',
        //     active: true,
        //     quickLinks: [
        //         {
        //             name: 'Add Discount Config',
        //             url: '/admin/discount-info',
        //             icon: 'fa fa-percent',
        //         },
        //         {
        //             name: 'Feedback Questions',
        //             url: '/admin/feedback-questions',
        //             icon: 'fa fa-star-half-o',
        //         },
        //         {
        //             name: 'Bill Templates',
        //             url: '/admin/bill-templates',
        //             icon: 'fa fa-print',
        //             active: true,
        //         }
        //     ]
        // }
        // ]
    },
    {
        name: 'Imports',
        url: '/admin/imports',
        icon: 'fa fa-cloud-upload',
        active: true,
        quickLinks: [
            {
                name: 'Settings',
                url: '/admin/settings',
                icon: 'fa fa-cogs'
            },
            {
                name: 'Users',
                url: '/admin/users',
                icon: 'fa fa-user-circle-o'
            },
            {
                name: 'Suppliers',
                url: '/admin/suppliers',
                icon: 'fa fa-user'
            }
        ]
        // children: [
        // {
        //     name: 'Bill Templates',
        //     url: '/admin/bill-templates',
        //     icon: 'fa fa-print',
        //     active: true,
        //     quickLinks: [
        //         {
        //             name: 'Add Bill Template',
        //             url: '/admin/bill-template-info',
        //             icon: 'fa fa-plus-square-o',
        //             quickLinks: [
        //                 {
        //                     name: 'Bill Templates',
        //                     url: '/admin/bill-templates',
        //                     icon: 'fa fa-print'
        //                 },
        //                 {
        //                     name: 'Purchase Orders',
        //                     url: '/admin/purchases',
        //                     icon: 'fa fa-cart-arrow-down'
        //                 },
        //                 {
        //                     name: 'Products',
        //                     url: '/admin/products',
        //                     icon: 'fa fa-barcode'
        //                 }
        //             ]
        //         },
        //         {
        //             name: 'Purchase Orders',
        //             url: '/admin/purchases',
        //             icon: 'fa fa-cart-arrow-down'
        //         },
        //         {
        //             name: 'Products',
        //             url: '/admin/products',
        //             icon: 'fa fa-barcode'
        //         }
        //     ]
        // },
        // {
        //     name: 'Bill SMS Templates',
        //     url: '/admin/bill-sms-templates',
        //     icon: 'fa fa-commenting-o',
        //     active: true,
        //     quickLinks: [
        //         {
        //             name: 'Add Bill SMS Template',
        //             url: '/admin/bill-sms-template-info',
        //             icon: 'fa fa-plus-square-o',
        //             quickLinks: [
        //                 {
        //                     name: 'Bill SMS Templates',
        //                     url: '/admin/bill-sms-templates',
        //                     icon: 'fa fa-commenting-o'
        //                 },
        //                 {
        //                     name: 'Bill Templates',
        //                     url: '/admin/bill-templates',
        //                     icon: 'fa fa-print'
        //                 },
        //                 {
        //                     name: 'Products',
        //                     url: '/admin/products',
        //                     icon: 'fa fa-barcode'
        //                 }
        //             ]
        //         },
        //         {
        //             name: 'Purchase Orders',
        //             url: '/admin/purchases',
        //             icon: 'fa fa-cart-arrow-down'
        //         },
        //         {
        //             name: 'Products',
        //             url: '/admin/products',
        //             icon: 'fa fa-barcode'
        //         }
        //     ]
        // },
        // {
        //     name: 'Feedback Questions',
        //     url: '/admin/feedback-questions',
        //     icon: 'fa fa-star-half-o',
        //     active: true,
        //     quickLinks: [
        //         {
        //             name: 'Add Bill Template',
        //             url: '/admin/bill-template-info',
        //             icon: 'fa fa-plus-square-o',
        //             quickLinks: [
        //                 {
        //                     name: 'Bill Templates',
        //                     url: '/admin/bill-templates',
        //                     icon: 'fa fa-print'
        //                 },
        //                 {
        //                     name: 'Purchase Orders',
        //                     url: '/admin/purchases',
        //                     icon: 'fa fa-cart-arrow-down'
        //                 },
        //                 {
        //                     name: 'Products',
        //                     url: '/admin/products',
        //                     icon: 'fa fa-barcode'
        //                 }
        //             ]
        //         },
        //         {
        //             name: 'Bill Templates',
        //             url: '/admin/bill-templates',
        //             icon: 'fa fa-print'
        //         },
        //         {
        //             name: 'Products',
        //             url: '/admin/products',
        //             icon: 'fa fa-barcode'
        //         }
        //     ]
        // },
        // {
        //     name: 'Enquiry Config',
        //     url: '/admin/enquiry-config',
        //     icon: 'fa fa-cogs',
        //     active: true,
        //     quickLinks: [
        //         {
        //             name: 'Feedback Questions',
        //             url: '/admin/feedback-questions',
        //             icon: 'fa fa-star-half-o',
        //         },
        //         {
        //             name: 'Bill Templates',
        //             url: '/admin/bill-templates',
        //             icon: 'fa fa-print',
        //             active: true,
        //         }
        //     ]
        // },
        // {
        //     name: 'Discount Config',
        //     url: '/admin/discount-config',
        //     icon: 'fa fa-percent',
        //     active: true,
        //     quickLinks: [
        //         {
        //             name: 'Add Discount Config',
        //             url: '/admin/discount-info',
        //             icon: 'fa fa-percent',
        //         },
        //         {
        //             name: 'Feedback Questions',
        //             url: '/admin/feedback-questions',
        //             icon: 'fa fa-star-half-o',
        //         },
        //         {
        //             name: 'Bill Templates',
        //             url: '/admin/bill-templates',
        //             icon: 'fa fa-print',
        //             active: true,
        //         }
        //     ]
        // }
        // ]
    },
    {
        name: 'Utilities',
        url: '/admin/utilities',
        icon: 'fa fa-cogs',
        active: true,
    },
    {
        name: 'Audit Logs',
        url: '/admin/audit',
        icon: 'fa fa-history',
        active: true,
    },
    {
        title: true,
        name: 'CRM'
    },
    {
        name: 'Dashboard',
        url: '/admin/crm-dashboard',
        icon: 'fa fa-tachometer',
        active: true,
        quickLinks: [
            {
                name: 'Campaigns',
                url: '/admin/campaigns',
                icon: 'fa fa-bullhorn',
                quickLinks: [
                    {
                        name: 'Campaigns',
                        url: '/admin/campaigns',
                        icon: 'fa fa-bullhorn',
                    },
                    {
                        name: 'SMS Templates',
                        url: '/admin/sms-template',
                        icon: 'fa fa-text-height'
                    },
                    {
                        name: 'Suppliers',
                        url: '/admin/suppliers',
                        icon: 'fa fa-user'
                    }
                ]
            },
            {
                name: 'SMS Templates',
                url: '/admin/sms-template',
                icon: 'fa fa-text-height'
            },
            {
                name: 'SMS Statistics',
                url: '/admin/campaign-sms-stats',
                icon: 'fa fa-line-chart'
            },
        ]
    },
    {
        name: 'Customers',
        url: '/admin/customers',
        icon: 'fa fa-user-circle-o',
        active: true,
        quickLinks: [
            {
                name: 'Add Customer',
                url: '/admin/customer-info',
                icon: 'fa fa-user-circle-o',
                quickLinks: [
                    {
                        name: 'Customers',
                        url: '/admin/customers',
                        icon: 'fa fa-user-circle-o'
                    },
                    {
                        name: 'Import Customer',
                        url: '/admin/customer-import',
                        icon: 'fa fa-user-circle-o'
                    },
                    {
                        name: 'Campaigns',
                        url: '/admin/campaigns',
                        icon: 'fa fa-bullhorn'
                    }
                ]
            },
            {
                name: 'Import Customer',
                url: '/admin/customer-import',
                icon: 'fa fa-user-circle-o'
            },
            {
                name: 'Campaigns',
                url: '/admin/campaigns',
                icon: 'fa fa-bullhorn'
            }
        ]
    },
    {
        name: 'Events',
        url: '/admin/events',
        icon: 'fa fa-calendar',
        active: true,
        quickLinks: [
            {
                name: 'Dashboard',
                url: '/admin/crm-dashboard',
                icon: 'fa fa-tachometer',
                quickLinks: [
                    {
                        name: 'Campaigns',
                        url: '/admin/campaigns',
                        icon: 'fa fa-bullhorn',
                    },
                    {
                        name: 'Send Bulk SMS',
                        url: '/admin/bulk-sms',
                        icon: 'fa fa-commenting',
                    },
                    {
                        name: 'Customer Import',
                        url: '/admin/customer-import',
                        icon: 'fa fa-cloud-upload',
                    }
                ]
            },
            {
                name: 'Campaigns',
                url: '/admin/campaigns',
                icon: 'fa fa-bullhorn',
            },
            {
                name: 'Send Bulk SMS',
                url: '/admin/bulk-sms',
                icon: 'fa fa-commenting',
            }
        ]
    },
    {
        name: 'Campaigns',
        url: '/admin/campaigns',
        icon: 'fa fa-bullhorn',
        active: true,
        quickLinks: [
            {
                name: 'Add Campaign',
                url: '/admin/campaign-info',
                icon: 'fa fa-bullhorn',
                quickLinks: [
                    {
                        name: 'Campaigns',
                        url: '/admin/campaigns',
                        icon: 'fa fa-bullhorn',
                    },
                    {
                        name: 'Bulk SMS',
                        url: '/admin/bulksms-history',
                        icon: 'fa fa-commenting',
                    },
                    {
                        name: 'Send Bulk SMS',
                        url: '/admin/bulk-sms',
                        icon: 'fa fa-commenting',
                    }
                ]
            },
            {
                name: 'SMS Templates',
                url: '/admin/sms-template',
                icon: 'fa fa-text-height'
            },
            {
                name: 'Suppliers',
                url: '/admin/suppliers',
                icon: 'fa fa-user'
            }
        ]
    },
    // {
    //     name: 'BBLC Stages',
    //     url: '/admin/bblc-config',
    //     icon: 'fa fa-list-ol',
    //     active: true,
    //     quickLinks: [
    //         {
    //             name: 'Add BBLC Stage',
    //             url: '/admin/bblc-stage',
    //             icon: 'fa fa-retweet',
    //             quickLinks: [
    //                 {
    //                     name: 'BBLC SMS',
    //                     url: '/admin/bblc-template',
    //                     icon: 'fa fa-retweet',
    //                 },
    //                 {
    //                     name: 'Campaign Templates',
    //                     url: '/admin/sms-template',
    //                     icon: 'fa fa-bullhorn'
    //                 },
    //                 {
    //                     name: 'BBLC Stages',
    //                     url: '/admin/bblc-config',
    //                     icon: 'fa fa-list-ol'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'Campaign Templates',
    //             url: '/admin/sms-template',
    //             icon: 'fa fa-bullhorn'
    //         },
    //         {
    //             name: 'Anniversary Templates',
    //             url: '/admin/anniversary-template',
    //             icon: 'fa fa-calendar',
    //         }
    //     ]
    // },
    {
        name: 'Customer Import',
        url: '/admin/customer-import',
        icon: 'fa fa-cloud-upload',
        active: true,
        quickLinks: [
            {
                name: 'Add Campaign',
                url: '/admin/campaign-info',
                icon: 'fa fa-bullhorn',
                quickLinks: [
                    {
                        name: 'Campaigns',
                        url: '/admin/campaigns',
                        icon: 'fa fa-bullhorn',
                    },
                    {
                        name: 'SMS Templates',
                        url: '/admin/sms-template',
                        icon: 'fa fa-text-height'
                    },
                    {
                        name: 'SMS Statistics',
                        url: '/admin/campaign-sms-stats',
                        icon: 'fa fa-line-chart'
                    }
                ]
            },
            {
                name: 'SMS Templates',
                url: '/admin/sms-template',
                icon: 'fa fa-text-height'
            },
            {
                name: 'SMS Statistics',
                url: '/admin/campaign-sms-stats',
                icon: 'fa fa-line-chart'
            }
        ]
    },
    {
        name: 'Bulk SMS',
        url: '/admin/bulksms-history',
        icon: 'fa fa-commenting',
        active: true,
        quickLinks: [
            {
                name: 'Send Bulk SMS',
                url: '/admin/bulk-sms',
                icon: 'fa fa-commenting',
                quickLinks: [
                    {
                        name: 'Campaigns',
                        url: '/admin/campaigns',
                        icon: 'fa fa-bullhorn',
                    },
                    {
                        name: 'Bulk SMS Templates',
                        url: '/admin/bulksms-template',
                        icon: 'fa fa-text-height'
                    },
                    {
                        name: 'Bulk SMS History',
                        url: '/admin/bulksms-history',
                        icon: 'fa fa-history'
                    }
                ]
            },
            {
                name: 'SMS Statistics',
                url: '/admin/campaign-sms-stats',
                icon: 'fa fa-line-chart'
            },
            {
                name: 'Bulk SMS Templates',
                url: '/admin/bulksms-template',
                icon: 'fa fa-text-height'
            }
        ]
    },
    {
        name: 'BBLC Stages',
        url: '/admin/bblc-config',
        icon: 'fa fa-sort-numeric-asc',
        active: true,
        quickLinks: [
            {
                name: 'Add BBLC Stage',
                url: '/admin/bblc-stage',
                icon: 'fa fa-cogs',
                active: true,
                quickLinks: [
                    {
                        name: 'BBLC Stages',
                        url: '/admin/bblc-config',
                        icon: 'fa fa-sort-numeric-asc',
                    },
                    {
                        name: 'SMS Templates',
                        url: '/admin/sms-template',
                        icon: 'fa fa-text-height'
                    },
                    {
                        name: 'Bulk SMS History',
                        url: '/admin/bulksms-history',
                        icon: 'fa fa-history'
                    }
                ]
            },
            {
                name: 'SMS Statistics',
                url: '/admin/campaign-sms-stats',
                icon: 'fa fa-line-chart'
            },
            {
                name: 'SMS Templates',
                url: '/admin/sms-template',
                icon: 'fa fa-text-height'
            }
        ]
    },
    {
        name: 'SMS Templates',
        url: '/templates',
        icon: 'fa fa-cogs',
        active: true,
        children: [
            {
                name: 'Anniversary',
                url: '/admin/anniversary-template',
                icon: 'fa fa-calendar',
                active: true,
                quickLinks: [
                    {
                        name: 'Add Anniversary SMS Template',
                        url: '/admin/anniversary-template-info',
                        icon: 'fa fa-calendar',
                        quickLinks: [
                            {
                                name: 'Anniversary Templates',
                                url: '/admin/anniversary-template',
                                icon: 'fa fa-calendar',
                            },
                            {
                                name: 'Birthday Templates',
                                url: '/admin/bday-template',
                                icon: 'fa fa-birthday-cake',
                            },
                            {
                                name: 'Campaign Templates',
                                url: '/admin/sms-template',
                                icon: 'fa fa-bullhorn'
                            }
                        ]
                    },
                    {
                        name: 'Birthday Templates',
                        url: '/admin/bday-template',
                        icon: 'fa fa-birthday-cake',
                    },
                    {
                        name: 'Campaign Templates',
                        url: '/admin/sms-template',
                        icon: 'fa fa-bullhorn'
                    }
                ]
            },
            {
                name: 'Birthday',
                url: '/admin/bday-template',
                icon: 'fa fa-birthday-cake',
                active: true,
                quickLinks: [
                    {
                        name: 'Add Birthday SMS Template',
                        url: '/admin/bday-template-info',
                        icon: 'fa fa-birthday-cake',
                        quickLinks: [
                            {
                                name: 'Birthday Templates',
                                url: '/admin/bday-template',
                                icon: 'fa fa-birthday-cake',
                            },
                            {
                                name: 'Campaign Templates',
                                url: '/admin/sms-template',
                                icon: 'fa fa-bullhorn'
                            },
                            {
                                name: 'Anniversary Templates',
                                url: '/admin/anniversary-template',
                                icon: 'fa fa-calendar',
                            }
                        ]
                    },
                    {
                        name: 'Campaign Templates',
                        url: '/admin/sms-template',
                        icon: 'fa fa-bullhorn'
                    },
                    {
                        name: 'Anniversary Templates',
                        url: '/admin/anniversary-template',
                        icon: 'fa fa-calendar',
                    }
                ]
            },
            {
                name: 'BBLC SMS',
                url: '/admin/bblc-template',
                icon: 'fa fa-retweet',
                active: true,
                quickLinks: [
                    {
                        name: 'Add BBLC SMS Template',
                        url: '/admin/bblc-template-info',
                        icon: 'fa fa-retweet',
                        quickLinks: [
                            {
                                name: 'BBLC SMS',
                                url: '/admin/bblc-template',
                                icon: 'fa fa-retweet',
                            },
                            {
                                name: 'Campaign Templates',
                                url: '/admin/sms-template',
                                icon: 'fa fa-bullhorn'
                            },
                            {
                                name: 'Anniversary Templates',
                                url: '/admin/anniversary-template',
                                icon: 'fa fa-calendar',
                            }
                        ]
                    },
                    {
                        name: 'Campaign Templates',
                        url: '/admin/sms-template',
                        icon: 'fa fa-bullhorn'
                    },
                    {
                        name: 'Anniversary Templates',
                        url: '/admin/anniversary-template',
                        icon: 'fa fa-calendar',
                    }
                ]
            },
            {
                name: 'Bulk SMS',
                url: '/admin/bulksms-template',
                icon: 'fa fa-list-alt',
                active: true,
                quickLinks: [
                    {
                        name: 'Add Bulk SMS Template',
                        url: '/admin/bulksms-template-info',
                        icon: 'fa fa-list-alt',
                        quickLinks: [
                            {
                                name: 'Bulk SMS Templates',
                                url: '/admin/bulksms-template',
                                icon: 'fa fa-list-alt'
                            },
                            {
                                name: 'Campaign Templates',
                                url: '/admin/sms-template',
                                icon: 'fa fa-bullhorn'
                            },
                            {
                                name: 'Anniversary Templates',
                                url: '/admin/anniversary-template',
                                icon: 'fa fa-calendar',
                            }
                        ]
                    },
                    {
                        name: 'Campaign Templates',
                        url: '/admin/sms-template',
                        icon: 'fa fa-bullhorn'
                    },
                    {
                        name: 'Anniversary Templates',
                        url: '/admin/anniversary-template',
                        icon: 'fa fa-calendar',
                    }
                ]
            },
            {
                name: 'Campaign',
                url: '/admin/sms-template',
                icon: 'fa fa-bullhorn',
                active: true,
                quickLinks: [
                    {
                        name: 'Add Campaign SMS Template',
                        url: '/admin/sms-template-info',
                        icon: 'fa fa-bullhorn',
                        quickLinks: [
                            {
                                name: 'Campaign Templates',
                                url: '/admin/sms-template',
                                icon: 'fa fa-bullhorn'
                            },
                            {
                                name: 'Anniversay Templates',
                                url: '/admin/anniversary-template',
                                icon: 'fa fa-calendar',
                            },
                            {
                                name: 'Birthday Templates',
                                url: '/admin/bday-template',
                                icon: 'fa fa-birthday-cake',
                            }
                        ]
                    },
                    {
                        name: 'Anniversay Templates',
                        url: '/admin/anniversary-template',
                        icon: 'fa fa-calendar',
                    },
                    {
                        name: 'Birthday Templates',
                        url: '/admin/bday-template',
                        icon: 'fa fa-birthday-cake',
                    }
                ]
            }
        ]
    },
    {
        name: 'Reports',
        url: '/admin/crm-reports',
        icon: 'fa fa-line-chart',
        active: true,
        quickLinks: [
            {
                name: 'BBLC Stages',
                url: '/admin/bblc-config',
                icon: 'fa fa-sort-numeric-asc'
            },
            {
                name: 'Campaigns',
                url: '/admin/campaigns',
                icon: 'fa fa-bullhorn'
            },
            {
                name: 'Bulk SMS History',
                url: '/admin/bulksms-history',
                icon: 'fa fa-history'
            }
        ]
    },
    // {
    //     name: 'Reports',
    //     url: '/reports',
    //     icon: 'fa fa-pie-chart',
    //     active: true,
    //     children: [
    //         {
    //             name: 'SMS Statistics',
    //             url: '/admin/campaign-sms-stats',
    //             icon: 'fa fa-line-chart',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Add SMS Template',
    //                     url: '/admin/sms-template-info',
    //                     icon: 'fa fa-text-height',
    //                     quickLinks: [
    //                         {
    //                             name: 'SMS Templates',
    //                             url: '/admin/sms-template',
    //                             icon: 'fa fa-text-height'
    //                         },
    //                         {
    //                             name: 'Purchase Orders',
    //                             url: '/admin/purchases',
    //                             icon: 'fa fa-cart-arrow-down'
    //                         },
    //                         {
    //                             name: 'Products',
    //                             url: '/admin/products',
    //                             icon: 'fa fa-barcode'
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     name: 'Purchase Orders',
    //                     url: '/admin/purchases',
    //                     icon: 'fa fa-cart-arrow-down'
    //                 },
    //                 {
    //                     name: 'Products',
    //                     url: '/admin/products',
    //                     icon: 'fa fa-barcode'
    //                 }
    //             ]
    //         },
    //         {
    //             name: 'BBLC Report',
    //             url: '/admin/bblc-report',
    //             icon: 'fa fa-retweet',
    //             active: true,
    //             quickLinks: [
    //                 {
    //                     name: 'Add SMS Template',
    //                     url: '/admin/sms-template-info',
    //                     icon: 'fa fa-text-height',
    //                     quickLinks: [
    //                         {
    //                             name: 'SMS Templates',
    //                             url: '/admin/sms-template',
    //                             icon: 'fa fa-text-height'
    //                         },
    //                         {
    //                             name: 'Purchase Orders',
    //                             url: '/admin/purchases',
    //                             icon: 'fa fa-cart-arrow-down'
    //                         },
    //                         {
    //                             name: 'Products',
    //                             url: '/admin/products',
    //                             icon: 'fa fa-barcode'
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     name: 'Purchase Orders',
    //                     url: '/admin/purchases',
    //                     icon: 'fa fa-cart-arrow-down'
    //                 },
    //                 {
    //                     name: 'Products',
    //                     url: '/admin/products',
    //                     icon: 'fa fa-barcode'
    //                 }
    //             ]
    //         }
    //     ]
    // },
    {
        name: 'My Profile',
        url: '/admin/profile',
        icon: 'fa fa-user-circle',
        active: true,
        quickLinks: [
            {
                name: 'Send Bulk SMS',
                url: '/admin/bulk-sms',
                icon: 'fa fa-commenting',
                quickLinks: [
                    {
                        name: 'Campaigns',
                        url: '/admin/campaigns',
                        icon: 'fa fa-bullhorn',
                    },
                    {
                        name: 'SMS Templates',
                        url: '/admin/sms-template',
                        icon: 'fa fa-text-height'
                    },
                    {
                        name: 'Bulk SMS History',
                        url: '/admin/bulksms-history',
                        icon: 'fa fa-history'
                    }
                ]
            },
            {
                name: 'SMS Statistics',
                url: '/admin/campaign-sms-stats',
                icon: 'fa fa-line-chart'
            },
            {
                name: 'SMS Templates',
                url: '/admin/sms-template',
                icon: 'fa fa-text-height'
            }
        ]
    },
    {
        name: 'WhatsApp (Beta)',
        url: '/admin/fb-embedded-signup',
        active: true,
        icon: 'fa fa-cogs',
    },
    {
        name: 'Product Bundle (Beta)',
        url: '/admin/product-bundle',
        active: true,
        icon: 'fa fa-cart-plus',
    },


    //reports
    { url: '/admin/reports', active: false },
    { url: '/admin/outstanding', active: false },
    { url: '/admin/sales-report', active: false },
    { url: '/admin/detailed-sales-report', active: false },
    { url: '/admin/sale-list', active: false },
    { url: '/admin/staff-service', active: false },
    { url: '/admin/staff-customer', active: false },
    { url: '/admin/staff-collection', active: false },
    { url: '/admin/feedback-report', active: false },
    { url: '/admin/daily-sales', active: false },
    { url: '/admin/monthly-sales', active: false },
    { url: '/admin/top-sales', active: false },
    { url: '/admin/products-report', active: false },
    { url: '/admin/payments-report', active: false },
    { url: '/admin/profit-ratio', active: false },
    { url: '/admin/customer-report', active: false },
    { url: '/admin/finance-report', active: false },
    { url: '/admin/trend-analysis', active: false },
    { url: '/admin/reports/loyalty', active: false },

    { url: '/admin/campaign-sms-stats', active: false },
    { url: '/admin/bulk-sms', active: false },
    { url: '/admin/bulksms-history', active: false },

    { url: '/admin/crm-reports', active: false },
    { url: '/admin/crm-dashboard', active: false },
    { url: '/admin/bblc-report', active: false },

    { url: '/admin/fb-embedded-signup', active: false },
];
