import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, NavigationError } from '@angular/router';
import { ApplicationHandlerService } from './helpers_and_handlers/application-handler.service';
import { VendorService } from './services/vendor.service';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  constructor(private router: Router, private appHandler: ApplicationHandlerService,
    private vendorService: VendorService) { }
  loadingRouteConfig: boolean;
  ngOnInit() {
    // (window as any).fbAsyncInit = () => {
    //   (window as any).FB.init({
    //     appId: '2047006695695393',
    //     autoLogAppEvents: true,
    //     xfbml: true,
    //     version: 'v20.0'
    //   });
    //   // (window as any).FB.AppEvents.logPageView();
    // };
    // (function (d, s, id) {
    //   var js, fjs = d.getElementsByTagName(s)[0];
    //   if (d.getElementById(id)) return;
    //   js = d.createElement(s); js.id = id;
    //   js.src = "https://connect.facebook.net/en_US/sdk.js";
    //   fjs.parentNode.insertBefore(js, fjs);
    // }(document, 'script', 'facebook-jssdk'));
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // console.log('NavigationStart:', event.url);
      }
      if (event instanceof NavigationEnd) {
        // console.log('NavigationEnd:', event.url);
      }
      if (event instanceof NavigationError) {
        console.log('NavigationError:', event.error);
      }
    });
    if (this.appHandler.getVendorId() != null &&
      this.appHandler.getVendorId().length > 0) {
      this.vendorService.getAccessControlConfigByVendorId(this.appHandler.getVendorId())
        .subscribe(response => {
          if (response.data != null && response.data.length > 0) {
            this.appHandler.setAccessControlConfig(response.data);
          } else {
            this.appHandler.setAccessControlConfig(null);
          }
        })
    }
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationStart) {
        this.loadingRouteConfig = true;
      } else if (evt instanceof NavigationEnd) {
        // this.loadingRouteConfig = false;
        return;
      }

      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
