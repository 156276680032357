import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandlerService } from './http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { ApplicationHandlerService } from '../helpers_and_handlers/application-handler.service';
import { Observable } from 'rxjs/Rx';
import { catchError } from 'rxjs/operators';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private handleError: HandleError;
  constructor(
    public http: HttpClient,
    public appHandler: ApplicationHandlerService,
    httpErrorHandler: HttpErrorHandlerService) {
    this.handleError = httpErrorHandler.createHandleError('User Service');
  }

  loginUser(credential): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/OAuth", "username=" + encodeURIComponent(credential.username) + "&password=" + encodeURIComponent(credential.password))
      .pipe(
        catchError(this.handleError('user login'))
      );
  }

  sendOTP(credential): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/auth/otp/send", {
      Mobile: credential.mobile,
      Email: credential.email
    })
      .pipe(
        catchError(this.handleError('send OTP'))
      );
  }

  validateOTP(userId: any, otp: any): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/auth/otp/validate", {
      UserId: userId,
      Otp: otp
    })
      .pipe(
        catchError(this.handleError('validate OTP'))
      );
  }

  addUser(userInfo: User): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/users/AddUser", userInfo)
      .pipe(
        catchError(this.handleError('add user'))
      );
  }

  updateUser(userInfo: User): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/users/UpdateUser", userInfo)
      .pipe(
        catchError(this.handleError('update user by user id'))
      );
  }

  updateUserProfile(userInfo: User): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/users/profile/update", userInfo)
      .pipe(
        catchError(this.handleError('update logged in user by user access token'))
      );
  }

  updateUserPassword(currentPassword: string, newPassword: string): Observable<any> {
    var password = {
      currentPassword: currentPassword,
      newPassword: newPassword
    }
    return this.http.post<any>(this.appHandler.hostUrl + "/users/profile/update/password", password)
      .pipe(
        catchError(this.handleError('user password update'))
      );
  }

  getAllUsers(): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/users/GetAllUsers")
      .pipe(
        catchError(this.handleError('user listing'))
      );
  }

  getLoggedInUserProfile(): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/users/profile")
      .pipe(
        catchError(this.handleError('get logged in user profile info'))
      );
  }

  getUsersByVendorId(vendorId): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/users/GetUsersByVendorId/" + vendorId)
      .pipe(
        catchError(this.handleError('user listing by vendorid'))
      );
  }


  getUserInfoById(userId: any): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/users/GetUserById/" + userId)
      .pipe(
        catchError(this.handleError('user info by user id'))
      );
  }

}
