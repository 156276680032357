import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { LoginComponent } from './views/login/login.component';
import { PosLayoutComponent } from './containers/pos-layout/pos-layout.component';
import { CustomerBillComponent } from './views/customer-bill/customer-bill.component';
import { AuthguardService } from './services/authguard.service';
import { SubscriptionComponent } from './views/admin/subscription/subscription.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'product/bundle/subscription',
    component: SubscriptionComponent,
    data: {
      title: 'Product Subscription'
    }
  },
  {
    path: 'customer_bill/:billnumber',
    component: CustomerBillComponent,
    data: {
      title: 'Customer Bill'
    }
  },
  {
    path: '',
    component: PosLayoutComponent,
    data: {
      title: 'POS'
    },
    children: [
      {
        path: 'pos',
        // pathMatch: 'full',
        canActivate: [AuthguardService],
        loadChildren: () => import('./views/billing-specialist/billing-specialist.module')
          .then(m => m.BillingSpecialistModule)
        // loadChildren: './views/billing-specialist/billing-specialist.module#BillingSpecialistModule'
      }]
  },
  {
    path: '',
    // pathMatch: 'full',
    component: DefaultLayoutComponent,
    data: {
      title: 'Super Admin'
    },
    children: [
      {
        path: 'superadmin',
        canActivate: [AuthguardService],
        loadChildren: () => import('./views/superadmin/superadmin.module')
          .then(m => m.SuperAdminModule)
        // loadChildren: './views/superadmin/superadmin.module#SuperAdminModule'
      }]
  },
  {
    path: '',
    // pathMatch: 'full',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'admin',
        canActivate: [AuthguardService],
        loadChildren: () => import('./views/admin/admin.module')
          .then(m => m.AdminModule)
        // loadChildren: './views/admin/admin.module#AdminModule'
      },
      {
        path: 'supervisor',
        canActivate: [AuthguardService],
        loadChildren: './views/supervisor/supervisor.module#SupervisorModule'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
