export const navItemForBillingSpecialist = [
    {
        name: 'Billing',
        url: '/pos/pos',
        icon: 'fa fa-calculator',
        active: true
    },
    {
        name: 'Appointment',
        url: '/pos/appointment',
        icon: 'fa fa-calendar',
        active: true
    },
    {
        name: 'Membership',
        url: '/pos/membership-bill',
        icon: 'fa fa-address-card',
        active: true,
    },
    {
        name: 'Wallet Money',
        url: '/pos/wallet',
        icon: 'fa fa-money',
        active: true,
    },
    {
        name: 'Loyalty Transactions',
        url: '/pos/loyalty-transactions',
        icon: 'fa fa-briefcase',
        active: true,
    },
    {
        name: 'Day Closure',
        url: '/pos/day-closure',
        icon: 'fa fa-stop-circle',
        active: true
    },
    {
        name: 'Expenses',
        url: '/pos/expense-history',
        icon: 'fa fa-money',
        active: true,
        quickLinks: [
            {
                name: 'Add Expense',
                url: '/pos/expense-view',
                icon: 'fa fa-money',
            },
            {
                name: 'Day Closure',
                url: '/pos/day-closure',
                icon: 'fa fa-stop-circle'
            },
            {
                name: 'Billing',
                url: '/pos/pos',
                icon: 'fa fa-calculator'
            }
        ]
    },
    {
        name: 'Incentive',
        url: '/pos/incentive-report',
        icon: 'fa fa-money',
        active: true,
        quickLinks: [
            {
                name: 'Add Expense',
                url: '/pos/expense-view',
                icon: 'fa fa-money',
            },
            {
                name: 'Day Closure',
                url: '/pos/day-closure',
                icon: 'fa fa-stop-circle'
            },
            {
                name: 'Billing',
                url: '/pos/pos',
                icon: 'fa fa-calculator'
            }
        ]
    },
    {
        name: 'Customer Enquiry',
        url: '/pos/customer-enquiry',
        icon: 'fa fa-question-circle-o',
        active: true
    },
    {
        name: 'Utilities',
        url: '/pos/settings',
        icon: 'fa fa-cogs',
        active: true,
    },
    {
        name: 'My Profile',
        url: '/pos/profile',
        icon: 'fa fa-user-circle',
        active: true
    }
];
