import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandlerService } from './http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { ApplicationHandlerService } from '../helpers_and_handlers/application-handler.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Vendor } from '../models/vendor';
import { CustomField } from '../models/customfield';
import { SMSQuotaAudit } from '../models/smaquotaaudit';

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  private handleError: HandleError;
  constructor(
    public http: HttpClient,
    public appHandler: ApplicationHandlerService,
    httpErrorHandler: HttpErrorHandlerService) {
    this.handleError = httpErrorHandler.createHandleError('User Service');
  }

  addVendor(vendorInfo: any): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/vendors/AddVendor", vendorInfo)
      .pipe(
        catchError(this.handleError('add vendor'))
      );
  }

  updateVendor(vendorInfo: Vendor): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/vendors/UpdateVendor", vendorInfo)
      .pipe(
        catchError(this.handleError('update vendor by vendor id'))
      );
  }

  updateVendorStatus(vendorId: any): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/vendors/status/update", {
      vendorId: vendorId
    })
      .pipe(
        catchError(this.handleError('update vendor status by vendor id'))
      );
  }

  uploadVendorLogo(formData: any, vendorId: any): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/vendors/UploadLogo/" + vendorId, formData)
      .pipe(
        catchError(this.handleError("vendor logo upload"))
      );
  }

  uploadImageAndGetShortLink(formData: any): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/vendors/image/link", formData)
      .pipe(
        catchError(this.handleError("upload image and generate short link"))
      );
  }

  getVendors(): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/vendors/GetVendors")
      .pipe(
        catchError(this.handleError('vendor listing'))
      );
  }

  getVendorsRenewalHistoryByVendorId(vendorId: any): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/renewal/list", {
      vendorId: vendorId
    })
      .pipe(
        catchError(this.handleError('vendor renewal history'))
      );
  }

  getAccessControlConfigByVendorId(vendorId: any): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/access/control/config/" + vendorId)
      .pipe(
        catchError(this.handleError('vendor access control config'))
      );
  }

  updateAccessControlConfig(configs: any): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/access/control/config", configs)
      .pipe(
        catchError(this.handleError('vendor access control config'))
      );
  }

  addVendorRenewalConfig(renewalConfigs: any[]): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/renewal/add", renewalConfigs)
      .pipe(
        catchError(this.handleError('add vendor renewal configs'))
      );
  }

  deleteVendorRenewalConfig(referenceNo: any): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/renewal/delete", {
      referenceNo: referenceNo
    })
      .pipe(
        catchError(this.handleError('delete vendor renewal config'))
      );
  }

  getSMSBalanceAndRenewalDate(vendorId: any, locationId: any) {
    return this.http.post<any>(this.appHandler.hostUrl + "/renewal/info", {
      vendorId: vendorId,
      locationId: locationId
    })
      .pipe(
        catchError(this.handleError('get SMS balance and renewal date'))
      );
  }

  updateVendorRenewalPayment(renewalConfig: any): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/renewal/update", renewalConfig)
      .pipe(
        catchError(this.handleError('update vendor renewal payment'))
      );
  }

  getSMSQuotaAuditHistoryByVendorId(vendorId: any, locationId: any): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/vendors/quota/sms/history", {
      vendorId: vendorId,
      locationId: locationId
    })
      .pipe(
        catchError(this.handleError('sms audit log history by vendor id'))
      );
  }

  saveSMSQuota(smsQuota: SMSQuotaAudit): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/vendors/quota/sms", smsQuota)
      .pipe(
        catchError(this.handleError('save sms quota'))
      );
  }

  getSMSQuotaByVendorId(vendorId: any, locationId: any): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/vendors/sms/quota/summary", {
      vendorId: vendorId,
      locationId: locationId
    })
      .pipe(
        catchError(this.handleError('sms quota by vendor id'))
      );
  }


  getVendorInfoById(vendorId: string): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/vendors/GetVendorById/" + vendorId)
      .pipe(
        catchError(this.handleError('vendor info by vendor id'))
      );
  }

  getAppVersion(): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/app/current/version")
      .pipe(
        catchError(this.handleError('current version info'))
      );
  }

  addNewReleaseVersion(version: any): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/app/release/add", version)
      .pipe(
        catchError(this.handleError('add new version info'))
      );
  }

  getAppReleaseHistory(): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/app/release/history")
      .pipe(
        catchError(this.handleError('get app release history'))
      );
  }

  getCustomFieldsByVendorId(vendorId: any, module: string): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/customfield/vendor/" + vendorId + "/" + module)
      .pipe(
        catchError(this.handleError('custom fields by vendor id'))
      );
  }

  addorUpdateCustomFields(customFields: CustomField[]): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/customfield/addorupdate", customFields)
      .pipe(
        catchError(this.handleError('add or update custom fields'))
      );
  }

  getCustomFieldsValuesByVendorId(vendorId: any, module: string): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/customfield/value/vendor/" + vendorId + "/" + module)
      .pipe(
        catchError(this.handleError('custom fields by vendor id'))
      );
  }

  getCustomFieldsForBillingByVendorId(vendorId: any, module: string): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/customfield/vendor/billing/" + vendorId + "/" + module)
      .pipe(
        catchError(this.handleError('custom fields for billing by vendor id'))
      );
  }
}
