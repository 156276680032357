import { Component, OnDestroy, AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations'
import { navItemForSupervisor } from '../../server/nav_supervisor';
import { navItemForAdmin } from '../../server/nav_admin';
import { navItemForAdminNonCRM } from '../../server/nav_admin_non_crm';
import { navItemForSuperAdmin } from '../../server/nav_super_admin';
import { naveItemForCRMOnly } from '../../server/nav_admin_crm';
import { naveItemForReportAdmin } from '../../server/nav_report_admin';
import { Router, NavigationEnd } from '@angular/router';

import { ApplicationHandlerService } from '../../helpers_and_handlers/application-handler.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { PurchaseService } from '../../services/purchase.service';
import { Address } from '../../models/address';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/interval';
import { VendorService } from '../../services/vendor.service';
import { AppointmentService } from '../../services/appointment.service';
import { CampaignService } from '../../services/campaign.service';

@Component({
  selector: 'app-dashboard',
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(300, style({ opacity: 0 }))
      ])
    ]),
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))
      ])
    ])
  ],
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnDestroy, OnInit, AfterViewInit {
  // @ViewChild('importantNoteModal', { static: false }) importantNoteModal;
  showingZurexaLogo: boolean = true;
  vendorLogo: string = this.appHandler.getVendorLogo();
  activeLogo = "assets/img/logo_with_name.png";
  @ViewChild('locationModal', { static: false }) locationModal;
  @ViewChild('userSelectionModal', { static: false }) userSelectionModal;
  @ViewChild('notificationModal', { static: false }) notificationModal;
  @ViewChild('versionUpgradeModal', { static: false }) versionUpgradeModal;

  windowWidth = window.innerWidth;
  public versionNumber = this.appHandler.getCurrentVersion(); locationId = this.appHandler.getLocationId();
  public navItems = []; public quickLinks = []; public activeLink = ""; public currentTime = new Date();
  public sidebarMinimized = true; assignedRoles: any[] = null; activeRole: string = this.appHandler.getUserActiveRole(); locationsMapped = []; activeLocation;
  private changes: MutationObserver;
  public element: HTMLElement = document.body; trialDuration = null;
  userRole: string = ""; displayNotificationPanel = false; newNotification = 0;
  displayUserPanel = false; locationName: string = this.appHandler.getLocationName();
  vendorName: string = this.appHandler.getVendorName();
  campaignStatus: any[] = this.appHandler.getCampaignStatusList();
  campaigns: any[] = [];
  notificationData: any = {
    ConsumableRequests: 0,
    Feedbacks: 0,
    CustomerEnquirie: 0,
    Appointments: 0,
    Campaigns: 0,
    BBLC: 0,
    Anniversaries: 0,
    Birthdays: 0
  };
  sub = null; showNotificationBar: boolean = false;
  smsBalance: any = null;
  renewalDate: any = null;
  renewalPrice: any = null;
  renewalLocations: number = 0;

  isTrial = false;
  trialDays: any = 0;
  appointments: any[] = [];

  constructor(public appHandler: ApplicationHandlerService, public toastr: ToastrService,
    public router: Router, public cookieService: CookieService,
    private vendorService: VendorService, private campaignService: CampaignService,
    public purchaseService: PurchaseService, private appointmentService: AppointmentService) {

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.activeLink = val.url;
        this.displayNotificationPanel = false;
        if (this.appHandler.getUserActiveRole() == this.appHandler.ROLE_ADMIN) {
          // this.getNotificationCount();
        }
        let activeRoute = this.navItems.find(ob => ob['url'] === this.activeLink);
        if (activeRoute != null && activeRoute["quickLinks"] != null) {
          this.quickLinks = activeRoute["quickLinks"];
        }
        else {
          if (this.navItems != null && this.navItems.length > 0) {
            let linksFound = false;
            this.navItems.forEach(navitem => {
              if (navitem.children != null && navitem.children.length > 0) {
                let activeRoute = navitem.children.find(ob => ob['url'] === this.activeLink);
                if (activeRoute != null && activeRoute["quickLinks"] != null) {
                  this.quickLinks = activeRoute["quickLinks"];
                  linksFound = true;
                }
              }
              else {
                if (navitem.quickLinks != null && navitem.quickLinks.length > 0) {
                  let activeRoute = navitem.quickLinks.find(ob => ob['url'] === this.activeLink);
                  if (activeRoute != null && activeRoute["quickLinks"] != null) {
                    this.quickLinks = activeRoute["quickLinks"];
                    linksFound = true;
                  }
                }
                else if (!linksFound) {
                  this.quickLinks = [];
                }
              }
            })
          }
        }
      }
    });


    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });

    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });
  }

  showNotificationWindow() {
    this.notificationModal.show();
  }

  getSMSBalanceAndRenewalDate() {
    this.vendorService.getSMSBalanceAndRenewalDate(this.appHandler.getVendorId(),
      this.appHandler.getLocationId())
      .subscribe(response => {
        if (response.status == this.appHandler.STATUS_CODE_SUCCESS) {
          if (response.data != null) {
            this.smsBalance = response.data.TransactionalSMSBalance +
              response.data.PromotionalSMSBalance;
            this.renewalDate = response.data.RenewalDate;
            this.renewalPrice = response.data.RenewalPrice;
            this.renewalLocations = response.data.RenewalLocations;
            this.isTrial = response.data.IsTrial;
            this.trialDays = response.data.TrialDuration;
          }
          else {
          }
        }
      });
  }

  getAppointmentsToday() {
    this.appointments = [];
    this.appointmentService.getTodaysAppointments(this.appHandler.getVendorId(),
      null, new Date(), new Date(), true)
      .subscribe(response => {
        if (response.status == this.appHandler.STATUS_CODE_SUCCESS) {
          if (response.data != null && response.data.length > 0) {

            var index = 0;
            response.data.forEach(element => {
              element.class = index % 4;
            });
            this.appointments = response.data;
          }
        }
        else {
        }
      });
  }

  getCampaignsToday() {
    this.campaigns = [];
    this.campaignService.getCampaignsToday(this.appHandler.getVendorId())
      .subscribe(response => {
        if (response.status == this.appHandler.STATUS_CODE_SUCCESS) {
          if (response.data != null && response.data.length > 0) {
            response.data.forEach(campaign => {
              let weekDays = {
                sun: false,
                mon: false,
                tue: false,
                wed: false,
                thu: false,
                fri: false,
                sat: false
              };
              weekDays.sun = campaign.RecurringDays[0] == 1 ? true : false;
              weekDays.mon = campaign.RecurringDays[1] == 1 ? true : false;
              weekDays.tue = campaign.RecurringDays[2] == 1 ? true : false;
              weekDays.wed = campaign.RecurringDays[3] == 1 ? true : false;
              weekDays.thu = campaign.RecurringDays[4] == 1 ? true : false;
              weekDays.fri = campaign.RecurringDays[5] == 1 ? true : false;
              weekDays.sat = campaign.RecurringDays[6] == 1 ? true : false;

              campaign.weekDays = weekDays;

              let status = this.campaignStatus.find(ob => ob.CampaignStatus == campaign.CampaignStatus);
              if (status != null) {
                campaign.CampaignStatusValue = status.Status;
              }
            });
            this.campaigns = response.data;
          }
        }
        else {
        }
      });
  }

  removeNotification() {
    this.showNotificationBar = false;
    // setTimeout(() => {
    document.getElementsByClassName("app-header")[0].classList.remove("margin-top40");
    // }, 10);
  }

  // initializeFireworkEffect() {
  //   const container = document.getElementById('container');
  //   const fireworks = new Fireworks(container, {
  //     rocketsPoint: 50,
  //     hue: { min: 0, max: 360 },
  //     delay: { min: 15, max: 30 },
  //     speed: 2,
  //     acceleration: 1.05,
  //     friction: 0.95,
  //     gravity: 1.5,
  //     particles: 50,
  //     trace: 3,
  //     explosion: 5,
  //     autoresize: true,
  //     brightness: {
  //       min: 50,
  //       max: 80,
  //       decay: { min: 0.015, max: 0.03 }
  //     },
  //     mouse: {
  //       click: false,
  //       move: false,
  //       max: 3
  //     },
  //     boundaries: {
  //       x: 50,
  //       y: 50,
  //       width: container.clientWidth,
  //       height: container.clientHeight,
  //       visible: false
  //     },
  //     sound: {
  //       enable: true,
  //       files: [
  //         // 'explosion0.mp3',
  //         // 'explosion1.mp3',
  //         // 'explosion2.mp3'
  //       ],
  //       volume: { min: 1, max: 2 },
  //     }
  //   });

  //   fireworks.start();
  // }


  getNotificationCount() {
    this.newNotification = 0;
    this.purchaseService.getEventSummary(this.appHandler.getVendorId())
      .subscribe(response => {
        if (response.status == this.appHandler.STATUS_CODE_SUCCESS) {
          if (response.data != null) {
            this.newNotification = 0;
            this.notificationData = response.data;
            if (response.data.ConsumableRequests > 0) {
              this.newNotification = this.newNotification + 1;;
            }
            if (response.data.Feedbacks > 0) {
              this.newNotification = this.newNotification + 1;
            }
            if (response.data.CustomerEnquiries > 0) {
              this.newNotification = this.newNotification + 1;
            }
            if (response.data.Appointments > 0) {
              this.newNotification = this.newNotification + 1;
            }
            if (response.data.Campaigns > 0) {
              this.newNotification = this.newNotification + 1;
            }
            if (response.data.BBLC > 0) {
              this.newNotification = this.newNotification + 1;
            }
            if (response.data.Anniversaries > 0) {
              this.newNotification = this.newNotification + 1;
            }
            if (response.data.Birthdays > 0) {
              this.newNotification = this.newNotification + 1;
            }
          }
          else {
            this.appHandler.openSnackBar('something went wrong! please try again.', 'OK', 'danger');
          }
        }
      });
  }

  checkTime(i): any {
    if (i < 10) { i = "0" + i };  // add zero in front of numbers < 10
    return i;
  }

  startTime() {

    setInterval(() => {
      this.currentTime = new Date();
    }, 1000);
  }

  toggleNotificationPanel() {
    this.displayUserPanel = false;
    if (!this.displayNotificationPanel) {
      if (this.newNotification > 0) {
        this.displayNotificationPanel = true;
      }
    }
    else {
      this.displayNotificationPanel = false;
    }
  }

  toggleUserPanel() {
    this.displayNotificationPanel = false;
    if (!this.displayUserPanel) {
      this.displayUserPanel = true;
    }
    else {
      this.displayUserPanel = false;
    }
  }

  getRoleName(role) {
    return this.appHandler.getRoleNameUsingKey(role);
  }

  updateActiveRole() {
    this.appHandler.setUserActiveRole((this.activeRole));
    this.updateNavbar();
  }

  logout() {
    this.cookieService.deleteAll('/', "localhost:4700");
    this.cookieService.deleteAll('/', "erp.zurexa.in");
    this.appHandler.openSnackBar('logged out successfully.', 'OK', 'success');
    this.router.navigate(['/login']);
  }

  updateActiveLocation() {
    this.appHandler.setLocationId(this.activeLocation);
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
    if (this.sub != null) {
      this.sub.unsubscribe();
    }
  }

  navigateToProfile() {
    this.displayUserPanel = false;
    this.router.navigate(['/admin/profile']);
  }

  updateNavbar() {
    this.userRole = this.appHandler.getUserActiveRole();

    if (this.cookieService.get("crmonly") == "true") {
      this.navItems = naveItemForCRMOnly.filter(i => i.active);;
    }
    else if (this.appHandler.getUserActiveRole() == this.appHandler.ROLE_ADMIN || this.appHandler.getUserActiveRole() == this.appHandler.ROLE_SECONDARY_ADMIN) {
      var navForAdmin;

      if (this.cookieService.get("crm") == "true" && this.appHandler.getUserActiveRole() == this.appHandler.ROLE_ADMIN) {
        navForAdmin = navItemForAdmin.filter(i => i.active);;
      }
      else {
        navForAdmin = navItemForAdminNonCRM.filter(i => i.active);;
      }
      navForAdmin.forEach((item, index) => {
        if (item.url == "/admin/fb-embedded-signup" || item.url == "/admin/product-bundle") {
          if (this.appHandler.getVendorId() != "1") {
            navForAdmin.splice(index, 1);
          }
        }
        if (item.name == "Consumable Request") {
          if (this.cookieService.get("crequest") == "false") {
            navForAdmin.splice(index, 1);
          }
        }
        if (item.name == "Staffs" && this.appHandler.getBusinessType() == this.appHandler.BUSINESS_TYPE_GENERAL.toString()) {
          navForAdmin.splice(index, 1);
        }
        if (item.name == "Reports" && this.appHandler.getBusinessType() == this.appHandler.BUSINESS_TYPE_GENERAL.toString()) {
          if (item.children != null && item.children.length > 0) {
            // item.children.splice(2, 1);
            item.children.forEach((subitem, indexvalue) => {
              if (subitem.name == "Staff vs Service") {
                item.children.splice(indexvalue, 1);
              }
            });
          }
        }
      })
      // if (this.appHandler.getBusinessType() == this.appHandler.BUSINESS_TYPE_GENERAL.toString()) {
      //   navForAdmin.splice(9, 1);
      // }
      this.navItems = navForAdmin;
      this.router.navigate(['/admin/dashboard']);
    }
    else if (this.appHandler.getUserActiveRole() == this.appHandler.ROLE_SUPERVISOR) {
      this.navItems = navItemForSupervisor.filter(i => i.active || i.title);
    }
    else if (this.appHandler.getUserActiveRole() == this.appHandler.ROLE_BILLING_SPECIALIST) {
      // this.navItems = navItemForBillingSpecialist.filter(i => i.active);;
      this.router.navigate(['/pos']);
    }
    else if (this.appHandler.getUserActiveRole() == this.appHandler.ROLE_SUPER_ADMIN) {
      this.navItems = navItemForSuperAdmin;
      this.router.navigate(['/superadmin/vendors']);
    }
    else if (this.appHandler.getUserActiveRole() == this.appHandler.ROLE_REPORT_ADMIN) {
      this.navItems = naveItemForReportAdmin.filter(i => i.active || i.title);
      this.router.navigate(['/admin/reports']);
    }
  }

  refreshNotifications() {
    this.getAppointmentsToday();
    this.getCampaignsToday();
    // this.getNotificationCount();
  }

  reloadPage() {
    this.versionUpgradeModal.hide();
    window.location.reload();
  }

  validateCurrentVersion() {
    var that = this;
    setInterval(() => {
      that.versionUpgradeModal.hide();
      this.vendorService.getAppVersion().subscribe(
        (data) => {
          if (data != null) {
            if (this.appHandler.getCurrentVersion().toLowerCase().trim()
              != data.version.toLowerCase().trim()) {
              that.versionUpgradeModal.show();
            }
          }
        },
        (error) => {
        });
    }, 900000);
  }

  ngOnInit(): void {
    var roles = this.appHandler.getUserRole();
    this.activeRole = this.appHandler.getUserActiveRole();
    if (roles != null) {
      this.assignedRoles = roles.split(",");
    }
    this.locationsMapped = this.appHandler.getLocationsMapped();
    if (this.locationsMapped != null && this.locationsMapped.length > 0) {
      this.locationsMapped.forEach(location => {
        if (location.Address == null) {
          location.Address = new Address();
        }
      });
      this.activeLocation = this.locationsMapped[0].Id;
    }
    this.updateNavbar();
    this.vendorName = this.cookieService.get("vendorname");
    this.startTime();

    if (this.activeRole != this.appHandler.ROLE_SUPER_ADMIN) {
      setTimeout(() => {
        this.showNotificationBar = true;
        setTimeout(() => {
          document.getElementsByClassName("app-header")[0].classList.add("margin-top40");
        }, 10);
      }, 3000);
    }

    this.getSMSBalanceAndRenewalDate();
    this.getAppointmentsToday();
    this.getCampaignsToday();
    this.validateCurrentVersion();
    // setInterval(this.opensnack, 10000, "my text");

    // this.initializeFireworkEffect();
  }

  // opensnack(): void {
  //   console.log("triggered");
  //   if (this.showingZurexaLogo) {
  //     if (this.vendorLogo != null && this.vendorLogo.trim().length > 0) {
  //       this.ngZone.run(() => {
  //         this.activeLogo = this.vendorLogo;
  //       });
  //       this.showingZurexaLogo = false;
  //     }
  //   }
  //   else {
  //     this.ngZone.run(() => {
  //       this.activeLogo = "assets/img/logo_with_name.png";
  //     });
  //     this.showingZurexaLogo = true;
  //   }
  // }

  getLocationAddress(address: Address) {
    return this.appHandler.getAddressString(address);
  }



  selectLocation(locationId, locationName) {
    if (locationId != null && locationId > 0) {
      this.appHandler.setLocationId(locationId);
      this.appHandler.setLocationName(locationName);
      this.locationId = locationId;
      this.locationName = locationName;
      this.locationModal.hide();
    }
  }

  selectActiveRole(role) {
    this.activeRole = role;
    this.appHandler.setUserActiveRole(role);
    // this.appHandler.setUserActiveRole((this.activeRole));
    this.updateNavbar();
    this.userSelectionModal.hide();
  }

  updateProfileWindow() {
    if (this.displayUserPanel) {
      this.displayUserPanel = false;
    }
  }

  openLocationSelection() {
    this.locationModal.show();
  }

  openUserSelection() {
    this.userSelectionModal.show();
  }

  ngAfterViewInit() {
    // if (this.appHandler.getNoteWindowStatus() == "1") {

    // }
    // else {
    //   this.importantNoteModal.show();
    //   this.appHandler.setNoteWindowStatus();
    // }

    if (this.appHandler.getUserActiveRole() != this.appHandler.ROLE_SUPER_ADMIN) {
      this.sub = Observable.interval(30000)
        .subscribe((val) => {
          if (this.showingZurexaLogo) {
            if (this.vendorLogo != null && this.vendorLogo.trim().length > 0) {
              // document.getElementById("brand_logo").classList.add("fadeOut_img");
              document.getElementsByClassName("navbar-brand-full")[0]["src"] = this.vendorLogo;
              // document.getElementById("brand_logo").classList.remove("fadeOut_img");
              this.showingZurexaLogo = false;
            }
          }
          else {
            // document.getElementById("brand_logo").classList.add("fadeOut_img");
            document.getElementsByClassName("navbar-brand-full")[0]["src"] = "assets/img/logo_with_name.png";
            // document.getElementById("brand_logo").classList.remove("fadeOut_img");
            this.showingZurexaLogo = true;
          }
        });
    }
    else {
      document.getElementsByClassName("navbar-brand-full")[0]["src"] = "assets/img/logo_with_name.png";
      // document.getElementById("brand_logo").classList.remove("fadeOut_img");
      this.showingZurexaLogo = true;
    }
    setTimeout(() => {
      this.trialDuration = this.cookieService.get('trial');
      setTimeout(() => {
        this.trialDuration = null;
        this.cookieService.set("trial", "0");
      }, 10000);
    }, 10000)

  }
}
