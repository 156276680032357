import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandlerService } from './http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { ApplicationHandlerService } from '../helpers_and_handlers/application-handler.service';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Appointment } from '../models/appointment';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  private handleError: HandleError;
  constructor(
    public http: HttpClient,
    public appHandler: ApplicationHandlerService,
    httpErrorHandler: HttpErrorHandlerService) {
    this.handleError = httpErrorHandler.createHandleError('Appointment Service');
  }

  addAppointment(appointmentInfo: Appointment): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/appointment/add", appointmentInfo)
      .pipe(
        catchError(this.handleError('add appointment'))
      );
  }

  getAppointmentById(appointmentId: any): Observable<any> {
    return this.http.get<any>(this.appHandler.hostUrl + "/appointment/info/" + appointmentId)
      .pipe(
        catchError(this.handleError('get appointment by id'))
      );
  }

  getAppointmentForCustomerByBillDate(customerId: any, billDate: any): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/appointment/customer/date", {
      customerId: customerId,
      billDate: billDate
    })
      .pipe(
        catchError(this.handleError('get appointment by customer id and bill date'))
      );
  }

  updateAppointment(appointmentInfo: Appointment): Observable<any> {
    return this.http.post<any>(this.appHandler.hostUrl + "/appointment/update", appointmentInfo)
      .pipe(
        catchError(this.handleError('update appointment'))
      );
  }

  updateAppointmentStatus(appointmentId: any, cancelReason: any, cancelComments: any, actionType: any) {
    return this.http.post<any>(this.appHandler.hostUrl + "/appointment/status/update", {
      appointmentId: appointmentId,
      reason: cancelReason,
      comments: cancelComments,
      appointmentStatus: actionType
    })
      .pipe(
        catchError(this.handleError('appointment status update by id'))
      );
  }

  getAppointmentsByDateRange(vendorId: any, locationId: any, fromDate: any, toDate: any, activeOnly: boolean): any {
    let from_date = new Date(fromDate).toDateString();
    let to_date = new Date(toDate).toDateString();

    return this.http.post<any>(this.appHandler.hostUrl + "/appointment/list/range", {
      fromDate: from_date,
      toDate: to_date,
      vendorId: vendorId,
      locationId: locationId,
      activeOnly: activeOnly
    })
      .pipe(
        catchError(this.handleError('appointment list by vendor and location id'))
      );
  }

  getTodaysAppointments(vendorId: any, locationId: any, fromDate: any, toDate: any, activeOnly: boolean): any {
    let from_date = new Date(fromDate).toDateString();
    let to_date = new Date(toDate).toDateString();

    return this.http.post<any>(this.appHandler.hostUrl + "/appointment/today", {
      fromDate: from_date,
      toDate: to_date,
      vendorId: vendorId,
      locationId: locationId,
      activeOnly: activeOnly
    })
      .pipe(
        catchError(this.handleError('appointments today'))
      );
  }

  checkStaffAvailability(staffId: any, appointmentDate: any, startTimeString: any, endTimeString: any) {
    return this.http.post<any>(this.appHandler.hostUrl + "/appointment/staff/availability", {
      staffId: staffId,
      appointmentDate: appointmentDate,
      startTimeString: startTimeString,
      endTimeString: endTimeString
    })
      .pipe(
        catchError(this.handleError('staff availability by date and time'))
      );
  }

}
