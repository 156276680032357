import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AmChartsModule, AmChartsService } from '@amcharts/amcharts3-angular';

import { ToastrModule } from 'ngx-toastr';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { NgxSpinnerModule } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AppComponent } from './app.component';
import { NgOtpInputModule } from 'ng-otp-input';
// Import containers
import { DefaultLayoutComponent } from './containers';

import { LoginComponent } from './views/login/login.component';


// export function appInitializer() {
//   return () => new Promise(resolve => {
//     // wait for facebook sdk to initialize before starting the angular app
//     window['fbAsyncInit'] = function () {
//       FB.init({
//         appId: "2047006695695393",
//         autoLogAppEvents: true,
//         xfbml: true,
//         version: 'v20.0'
//       });
//     };

//     // Load the JavaScript SDK asynchronously
//     (function (d, s, id) {
//       var js, fjs = d.getElementsByTagName(s)[0];
//       if (d.getElementById(id)) return;
//       js = d.createElement(s); js.id = id;
//       js.src = "https://connect.facebook.net/en_US/sdk.js";
//       fjs.parentNode.insertBefore(js, fjs);
//     }(document, 'script', 'facebook-jssdk'));
//   });
// }

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular'

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { TableModule } from 'primeng/table';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { CookieService } from 'ngx-cookie-service';
import { PosLayoutComponent } from './containers/pos-layout/pos-layout.component';
import { CustomerBillComponent } from './views/customer-bill/customer-bill.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material';
import { CarouselModule } from 'primeng/carousel';
import { GlobalErrorHandlerService } from './services/global-error-handler.service';
import { SubscriptionComponent } from './views/admin/subscription/subscription.component';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppAsideModule,
    NgxSpinnerModule,
    AppBreadcrumbModule.forRoot(),
    SlimLoadingBarModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      // maxOpened: 3,
      // newestOnTop: true,
    }),
    AmChartsModule,
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    FormsModule,
    ModalModule.forRoot(),
    HttpClientModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    TableModule,
    ChartsModule,
    MatSnackBarModule,
    CarouselModule,
    BsDatepickerModule.forRoot(),
    NgOtpInputModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  declarations: [
    AppComponent,
    APP_CONTAINERS,
    LoginComponent,
    PosLayoutComponent,
    CustomerBillComponent,
    SubscriptionComponent
  ],
  providers: [
    MatSnackBar,
    DatePipe,
    DatePipe,
    // {
    //   provide: LocationStrategy,
    //   useClass: HashLocationStrategy
    // },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    }
    , CookieService, AmChartsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
