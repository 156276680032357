export const navItemForSuperAdmin: any[] = [
    {
        name: 'Vendors',
        url: '/superadmin/vendors',
        icon: 'fa fa-user-circle',
        quickLinks: [
            {
                name: 'Add Vendor',
                url: '/superadmin/vendor-info',
                icon: 'fa fa-user-circle',
                quickLinks: [
                    {
                        name: 'Vendors',
                        url: '/superadmin/vendors',
                        icon: 'fa fa-user-circle'
                    },
                    {
                        name: 'Vendor Locations',
                        url: '/superadmin/vendors/locations',
                        icon: 'fa fa-cart-arrow-down',
                    },
                    {
                        name: 'Users',
                        url: '/superadmin/vendors/users',
                        icon: 'fa fa-users',
                    }
                ]
            },
            {
                name: 'Vendor Locations',
                url: '/superadmin/vendors/locations',
                icon: 'fa fa-cart-arrow-down',
            },
            {
                name: 'Users',
                url: '/superadmin/vendors/users',
                icon: 'fa fa-users',
            }
        ]
    },
    {
        name: 'Vendor Locations',
        url: '/superadmin/vendors/locations',
        icon: 'fa fa-map-marker',
        quickLinks: [
            {
                name: 'Add Vendor Location',
                url: '/superadmin/vendors/location-info',
                icon: 'fa fa-plus-square-o',
                quickLinks: [
                    {
                        name: 'Vendor Locations',
                        url: '/superadmin/vendors/locations',
                        icon: 'fa fa-map-marker'
                    },
                    {
                        name: 'Vendors',
                        url: '/superadmin/vendors',
                        icon: 'fa fa-user-circle',
                    },
                    {
                        name: 'Users',
                        url: '/superadmin/vendors/users',
                        icon: 'fa fa-users',
                    }
                ]
            },
            {
                name: 'Vendors',
                url: '/superadmin/vendors',
                icon: 'fa fa-user-circle',
            },
            {
                name: 'Users',
                url: '/superadmin/vendors/users',
                icon: 'fa fa-users',
            }
        ]
    },
    {
        name: 'Users',
        url: '/superadmin/vendors/users',
        icon: 'fa fa-users',
        quickLinks: [
            {
                name: 'Add Admin User',
                url: '/superadmin/vendors/user-info',
                icon: 'fa fa-plus-square-o',
                quickLinks: [
                    {
                        name: 'Users',
                        url: '/superadmin/vendors/users',
                        icon: 'fa fa-users',
                    },
                    {
                        name: 'Vendor Locations',
                        url: '/superadmin/vendors/locations',
                        icon: 'fa fa-map-marker',
                    },
                    {
                        name: 'Vendors',
                        url: '/superadmin/vendors',
                        icon: 'fa fa-user-circle',
                    }
                ]
            },
            {
                name: 'Vendor Locations',
                url: '/superadmin/vendors/locations',
                icon: 'fa fa-map-marker',
            },
            {
                name: 'Vendors',
                url: '/superadmin/vendors',
                icon: 'fa fa-user-circle',
            }
        ]
    },
    {
        name: 'SMS Quota',
        url: '/superadmin/vendors/sms-quota',
        icon: 'fa fa-comments-o',
        quickLinks: [
            {
                name: 'Users',
                url: '/superadmin/vendors/users',
                icon: 'fa fa-users',
                quickLinks: [
                    {
                        name: 'Users',
                        url: '/superadmin/vendors/users',
                        icon: 'fa fa-users',
                    },
                    {
                        name: 'Vendor Locations',
                        url: '/superadmin/vendors/locations',
                        icon: 'fa fa-map-marker',
                    },
                    {
                        name: 'Vendors',
                        url: '/superadmin/vendors',
                        icon: 'fa fa-user-circle',
                    }
                ]
            },
            {
                name: 'Vendors',
                url: '/superadmin/vendors',
                icon: 'fa fa-user-circle',
            },
            {
                name: 'Vendor Locations',
                url: '/superadmin/vendors/locations',
                icon: 'fa fa-map-marker',
            }
        ]
    },
    {
        name: 'Custom Fields',
        url: '/superadmin/vendors/fields',
        icon: 'fa fa-cogs',
        quickLinks: [
            {
                name: 'Add Admin User',
                url: '/superadmin/vendors/user-info',
                icon: 'fa fa-plus-square-o',
                quickLinks: [
                    {
                        name: 'Users',
                        url: '/superadmin/vendors/users',
                        icon: 'fa fa-users',
                    },
                    {
                        name: 'Vendor Locations',
                        url: '/superadmin/vendors/locations',
                        icon: 'fa fa-map-marker',
                    },
                    {
                        name: 'Vendors',
                        url: '/superadmin/vendors',
                        icon: 'fa fa-user-circle',
                    }
                ]
            },
            {
                name: 'Vendor Locations',
                url: '/superadmin/vendors/locations',
                icon: 'fa fa-map-marker',
            },
            {
                name: 'Vendors',
                url: '/superadmin/vendors',
                icon: 'fa fa-user-circle',
            }
        ]
    },
    {
        name: 'Background Jobs',
        url: '/superadmin/background-jobs',
        icon: 'fa fa-refresh',
        quickLinks: [
            {
                name: 'Add Admin User',
                url: '/superadmin/vendors/user-info',
                icon: 'fa fa-plus-square-o',
                quickLinks: [
                    {
                        name: 'Users',
                        url: '/superadmin/vendors/users',
                        icon: 'fa fa-users',
                    },
                    {
                        name: 'Vendor Locations',
                        url: '/superadmin/vendors/locations',
                        icon: 'fa fa-map-marker',
                    },
                    {
                        name: 'Vendors',
                        url: '/superadmin/vendors',
                        icon: 'fa fa-user-circle',
                    }
                ]
            },
            {
                name: 'Vendor Locations',
                url: '/superadmin/vendors/locations',
                icon: 'fa fa-map-marker',
            },
            {
                name: 'Vendors',
                url: '/superadmin/vendors',
                icon: 'fa fa-user-circle',
            }
        ]
    },
    {
        name: 'Payment Edit',
        url: '/superadmin/payment-edit',
        icon: 'fa fa-money',
        quickLinks: [
            {
                name: 'Background Jobs',
                url: '/superadmin/background-jobs',
                icon: 'fa fa-refresh',
                quickLinks: [
                    {
                        name: 'Users',
                        url: '/superadmin/vendors/users',
                        icon: 'fa fa-users',
                    },
                    {
                        name: 'Vendor Locations',
                        url: '/superadmin/vendors/locations',
                        icon: 'fa fa-map-marker',
                    },
                    {
                        name: 'Vendors',
                        url: '/superadmin/vendors',
                        icon: 'fa fa-user-circle',
                    }
                ]
            },
            {
                name: 'Vendor Locations',
                url: '/superadmin/vendors/locations',
                icon: 'fa fa-map-marker',
            },
            {
                name: 'Vendors',
                url: '/superadmin/vendors',
                icon: 'fa fa-user-circle',
            }
        ]
    },
    {
        name: 'Daily Report Config',
        url: '/superadmin/dailyreport',
        icon: 'fa fa-envelope-o',
        quickLinks: [
            {
                name: 'Add Admin User',
                url: '/superadmin/vendors/user-info',
                icon: 'fa fa-plus-square-o',
                quickLinks: [
                    {
                        name: 'Users',
                        url: '/superadmin/vendors/users',
                        icon: 'fa fa-users',
                    },
                    {
                        name: 'Vendor Locations',
                        url: '/superadmin/vendors/locations',
                        icon: 'fa fa-map-marker',
                    },
                    {
                        name: 'Vendors',
                        url: '/superadmin/vendors',
                        icon: 'fa fa-user-circle',
                    }
                ]
            },
            {
                name: 'Vendor Locations',
                url: '/superadmin/vendors/locations',
                icon: 'fa fa-map-marker',
            },
            {
                name: 'Vendors',
                url: '/superadmin/vendors',
                icon: 'fa fa-user-circle',
            }
        ]
    },
    {
        name: 'Vendor Activities',
        url: '/superadmin/vendor-bills',
        icon: 'fa fa-clock-o',
        quickLinks: [
            {
                name: 'Daily Report Config',
                url: '/superadmin/dailyreport',
                icon: 'fa fa-envelope-o',
                quickLinks: [
                    {
                        name: 'Background Jobs',
                        url: '/superadmin/background-jobs',
                        icon: 'fa fa-refresh'
                    },
                    {
                        name: 'Payment Edit',
                        url: '/superadmin/payment-edit',
                        icon: 'fa fa-money'
                    },
                    {
                        name: 'Vendors',
                        url: '/superadmin/vendors',
                        icon: 'fa fa-user-circle',
                    }
                ]
            },
            {
                name: 'Payment Edit',
                url: '/superadmin/payment-edit',
                icon: 'fa fa-money'
            },
            {
                name: 'Vendors',
                url: '/superadmin/vendors',
                icon: 'fa fa-user-circle',
            }
        ]
    },
    {
        name: 'Utilities',
        url: '/superadmin/utilities',
        icon: 'fa fa-cogs',
        quickLinks: [
            {
                name: 'Daily Report Config',
                url: '/superadmin/dailyreport',
                icon: 'fa fa-envelope-o',
                quickLinks: [
                    {
                        name: 'Background Jobs',
                        url: '/superadmin/background-jobs',
                        icon: 'fa fa-refresh'
                    },
                    {
                        name: 'Payment Edit',
                        url: '/superadmin/payment-edit',
                        icon: 'fa fa-money'
                    },
                    {
                        name: 'Vendors',
                        url: '/superadmin/vendors',
                        icon: 'fa fa-user-circle',
                    }
                ]
            },
            {
                name: 'Payment Edit',
                url: '/superadmin/payment-edit',
                icon: 'fa fa-money'
            },
            {
                name: 'Vendors',
                url: '/superadmin/vendors',
                icon: 'fa fa-user-circle',
            }
        ]
    },
    {
        name: 'DLT Setup',
        url: '/superadmin/dlt',
        icon: 'fa fa-cog',
        quickLinks: [
            {
                name: 'Daily Report Config',
                url: '/superadmin/dailyreport',
                icon: 'fa fa-envelope-o',
                quickLinks: [
                    {
                        name: 'Utilities',
                        url: '/superadmin/utilities',
                        icon: 'fa fa-cogs',
                    },
                    {
                        name: 'Payment Edit',
                        url: '/superadmin/payment-edit',
                        icon: 'fa fa-money'
                    },
                    {
                        name: 'Vendors',
                        url: '/superadmin/vendors',
                        icon: 'fa fa-user-circle',
                    }
                ]
            },
            {
                name: 'Payment Edit',
                url: '/superadmin/payment-edit',
                icon: 'fa fa-money'
            },
            {
                name: 'Vendors',
                url: '/superadmin/vendors',
                icon: 'fa fa-user-circle',
            }
        ]
    },
    {
        name: 'Renewal Config',
        url: '/superadmin/renewal',
        icon: 'fa fa-refresh',
        quickLinks: [
            {
                name: 'Daily Report Config',
                url: '/superadmin/dailyreport',
                icon: 'fa fa-envelope-o',
                quickLinks: [
                    {
                        name: 'Utilities',
                        url: '/superadmin/utilities',
                        icon: 'fa fa-cogs',
                    },
                    {
                        name: 'Payment Edit',
                        url: '/superadmin/payment-edit',
                        icon: 'fa fa-money'
                    },
                    {
                        name: 'Vendors',
                        url: '/superadmin/vendors',
                        icon: 'fa fa-user-circle',
                    }
                ]
            },
            {
                name: 'Payment Edit',
                url: '/superadmin/payment-edit',
                icon: 'fa fa-money'
            },
            {
                name: 'Vendors',
                url: '/superadmin/vendors',
                icon: 'fa fa-user-circle',
            }
        ]
    },
    {
        name: 'SMS Templates',
        url: '/superadmin/sms-templates',
        icon: 'fa fa-commenting-o',
        quickLinks: [
            {
                name: 'Daily Report Config',
                url: '/superadmin/dailyreport',
                icon: 'fa fa-envelope-o',
                quickLinks: [
                    {
                        name: 'Utilities',
                        url: '/superadmin/utilities',
                        icon: 'fa fa-cogs',
                    },
                    {
                        name: 'Payment Edit',
                        url: '/superadmin/payment-edit',
                        icon: 'fa fa-money'
                    },
                    {
                        name: 'Vendors',
                        url: '/superadmin/vendors',
                        icon: 'fa fa-user-circle',
                    }
                ]
            },
            {
                name: 'Payment Edit',
                url: '/superadmin/payment-edit',
                icon: 'fa fa-money'
            },
            {
                name: 'Vendors',
                url: '/superadmin/vendors',
                icon: 'fa fa-user-circle',
            }
        ]
    },
    {
        name: 'Access Control',
        url: '/superadmin/access-control',
        icon: 'fa fa-cogs',
        quickLinks: [
            {
                name: 'Daily Report Config',
                url: '/superadmin/dailyreport',
                icon: 'fa fa-envelope-o',
                quickLinks: [
                    {
                        name: 'Utilities',
                        url: '/superadmin/utilities',
                        icon: 'fa fa-cogs',
                    },
                    {
                        name: 'Payment Edit',
                        url: '/superadmin/payment-edit',
                        icon: 'fa fa-money'
                    },
                    {
                        name: 'Vendors',
                        url: '/superadmin/vendors',
                        icon: 'fa fa-user-circle',
                    }
                ]
            },
            {
                name: 'Payment Edit',
                url: '/superadmin/payment-edit',
                icon: 'fa fa-money'
            },
            {
                name: 'Vendors',
                url: '/superadmin/vendors',
                icon: 'fa fa-user-circle',
            }
        ]
    },
    {
        name: 'Web Releases',
        url: '/superadmin/releases',
        icon: 'fa fa-cloud-upload',
        quickLinks: [
            {
                name: 'Daily Report Config',
                url: '/superadmin/dailyreport',
                icon: 'fa fa-envelope-o',
                quickLinks: [
                    {
                        name: 'Utilities',
                        url: '/superadmin/utilities',
                        icon: 'fa fa-cogs',
                    },
                    {
                        name: 'Payment Edit',
                        url: '/superadmin/payment-edit',
                        icon: 'fa fa-money'
                    },
                    {
                        name: 'Vendors',
                        url: '/superadmin/vendors',
                        icon: 'fa fa-user-circle',
                    }
                ]
            },
            {
                name: 'Payment Edit',
                url: '/superadmin/payment-edit',
                icon: 'fa fa-money'
            },
            {
                name: 'Vendors',
                url: '/superadmin/vendors',
                icon: 'fa fa-user-circle',
            }
        ]
    }
];
