import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationHandlerService } from '../../helpers_and_handlers/application-handler.service';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { VendorService } from '../../services/vendor.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isPasswordLogin: boolean = true;
  otpSent: boolean = false; isOTPValid: boolean = false;
  userId: number = null; otp: string = null; intervalTimer = null;
  userInfo: any = null;
  loginOTPResendTimer = null;
  constructor(public router: Router, public appHandler: ApplicationHandlerService, private vendorService: VendorService,
    public userService: UserService, public toastr: ToastrService, public cookieService: CookieService) { }

  carousalData: any = [
    {
      icon: "fa fa-calculator",
      imageUrl: "../../../assets/img/point-of-sale.svg",
      title: "Point Of Sale",
      description: "Zurexa has an integrated/built-in point-of-sale (POS) system that enables faster checkout for your salon and spa clients."
    },
    {
      icon: "fa fa-calendar",
      imageUrl: "../../../assets/img/appointment.svg",
      title: "Schedule Appointment",
      description: "Simplify the appointment scheduling process of your business with our clean and simple tool."
    },
    {
      icon: "fa fa-map-marker",
      imageUrl: "../../../assets/img/multiple.svg",
      title: "Manage multi-location",
      description: "Manage one or more salon locations easily within a few clicks. Do all this and more with real-time access to data."
    },
    {
      icon: "fa fa-pie-chart",
      imageUrl: "../../../assets/img/analytics.svg",
      title: "Analytics & Reports",
      description: "Analyze your business with individual reports and detailed insights."
    },
    {
      icon: "fa fa-bullhorn",
      imageUrl: "../../../assets/img/digital-marketing.svg",
      title: "Marketing",
      description: "Create targeted promotions and engage with new clients at the right time with the right offers."
    }
  ];

  greeting = "";
  appVersion = this.appHandler.getCurrentVersion();
  signingIn: boolean = false;
  sendingOTP: boolean = false;
  credential: any = {
    username: null,
    password: null,
    mobile: null,
    email: null
  }
  responsiveOptions = [];

  // initializeFireworkEffect() {
  //   const container = document.getElementById('login_full_screen');
  //   const fireworks = new Fireworks(container, {
  //     rocketsPoint: 50,
  //     hue: { min: 0, max: 360 },
  //     delay: { min: 15, max: 30 },
  //     speed: 2,
  //     acceleration: 1.05,
  //     friction: 0.95,
  //     gravity: 1.5,
  //     particles: 50,
  //     trace: 3,
  //     explosion: 5,
  //     autoresize: true,
  //     brightness: {
  //       min: 50,
  //       max: 80,
  //       decay: { min: 0.015, max: 0.03 }
  //     },
  //     mouse: {
  //       click: false,
  //       move: false,
  //       max: 3
  //     },
  //     boundaries: {
  //       x: 50,
  //       y: 50,
  //       width: container.clientWidth,
  //       height: container.clientHeight,
  //       visible: false
  //     },
  //     sound: {
  //       enable: true,
  //       files: [
  //         // 'explosion0.mp3',
  //         // 'explosion1.mp3',
  //         // 'explosion2.mp3'
  //       ],
  //       volume: { min: 1, max: 2 },
  //     }
  //   });

  //   fireworks.start();
  // }

  ngOnInit() {

    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 1,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 1,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];

    var hrs = new Date().getHours();
    if (hrs >= 5 && hrs < 12)
      this.greeting = 'Good Morning';
    else if (hrs >= 12 && hrs <= 17)
      this.greeting = 'Good Afternoon';
    else
      this.greeting = 'Good Evening';

    // this.initializeFireworkEffect();
  }

  updateSuccessfulLogin(response: any) {
    if (this.intervalTimer != null) {
      clearInterval(this.intervalTimer);
    }
    this.appHandler.openSnackBar('logged in successfully!', 'OK', 'success');
    this.appHandler.setAccessToken(response.data.Token);
    this.getAccessControlConfigByVendor(response.data.VendorId);
    this.cookieService.set('trial', response.data.TrialDuration);
    this.cookieService.set('vendorid', response.data.VendorId);
    this.appHandler.setVendorLogo(response.data.Logo);
    this.cookieService.set('wholesale', response.data.WholesaleMode);
    this.cookieService.set('sms', response.data.SMSEnabled);
    this.cookieService.set('email', response.data.EmailEnabled);
    this.cookieService.set('priceedit', response.data.AllowPriceEdit);
    this.cookieService.set('business', response.data.BusinessType);
    this.cookieService.set('crmonly', response.data.IsOnlyCRM);
    this.cookieService.set('crm', response.data.CRMEnabled);
    this.cookieService.set('crequest', response.data.EnableConsumableRequest);
    this.cookieService.set("calc", response.data.RequiredCalcMode);
    this.cookieService.set("currency", response.data.CurrencySymbol);
    if (response.data.LocationIds != null && response.data.LocationIds.length > 0) {
      this.cookieService.set('locationid', response.data.LocationIds[0].Id);
      this.cookieService.set('locationname', response.data.LocationIds[0].Name);
      this.appHandler.setLocationsMapped(response.data.LocationIds);
    }
    this.cookieService.set('vendorname', response.data.VendorName);
    this.appHandler.setUserRole(response.data.Role);
    if (response.data.Role.indexOf(this.appHandler.ROLE_ADMIN) >= 0 || response.data.Role.indexOf(this.appHandler.ROLE_SECONDARY_ADMIN) >= 0 && !response.data.IsOnlyCRM) {
      if (response.data.Role.indexOf(this.appHandler.ROLE_ADMIN) >= 0) {
        this.appHandler.setUserActiveRole(this.appHandler.ROLE_ADMIN);
      }
      else if (response.data.Role.indexOf(this.appHandler.ROLE_SECONDARY_ADMIN) >= 0) {
        this.appHandler.setUserActiveRole(this.appHandler.ROLE_SECONDARY_ADMIN);
      }
      this.router.navigate(['/admin/dashboard']);
    }
    else if (response.data.Role.indexOf(this.appHandler.ROLE_SUPER_ADMIN) >= 0) {
      this.appHandler.setUserActiveRole(this.appHandler.ROLE_SUPER_ADMIN);
      this.router.navigate(['/superadmin/vendors']);
    }
    else if (response.data.Role.indexOf(this.appHandler.ROLE_REPORT_ADMIN) >= 0) {
      this.appHandler.setUserActiveRole(this.appHandler.ROLE_REPORT_ADMIN);
      this.router.navigate(['/admin/reports']);
    }
    else if (response.data.Role.indexOf(this.appHandler.ROLE_BILLING_SPECIALIST) >= 0 && !response.data.IsOnlyCRM) {
      this.appHandler.setUserActiveRole(this.appHandler.ROLE_BILLING_SPECIALIST);
      this.router.navigate(['/pos']);
    }
    if (response.data.IsOnlyCRM) {
      this.appHandler.setUserActiveRole(this.appHandler.ROLE_ADMIN);
      this.router.navigate(['/admin/crm-dashboard']);
    }
  }

  getAccessControlConfigByVendor(vendorId) {
    this.vendorService.getAccessControlConfigByVendorId(vendorId)
      .subscribe(response => {
        if (response.data != null && response.data.length > 0) {
          this.appHandler.setAccessControlConfig(response.data);
        } else {
          this.appHandler.setAccessControlConfig(null);
        }
      })
  }

  loginUser() {
    this.signingIn = false;
    if (this.isPasswordLogin) {
      if (this.credential.username != null && this.credential.password != null) {
        this.signingIn = true;
        this.userService.loginUser(this.credential)
          .subscribe(response => {
            this.signingIn = false;
            if (response.status == this.appHandler.STATUS_CODE_SUCCESS) {
              if (response.data != null) {
                this.updateSuccessfulLogin(response);
              }
            }
            else {
              if (response.status == this.appHandler.STATUS_CODE_UNAUTHORIZED) {
                this.appHandler.openSnackBar("incorrect username or password", 'OK', 'danger');
              }
              else if (response.status == this.appHandler.STATUS_CODE_FORBIDDEN) {
                this.appHandler.openSnackBar("you have exceeded the number of allowed login attempts. please try again later.", 'OK', 'danger');
              }
              else if (response.status == this.appHandler.STATUS_CODE_SERVICE_UNAVAILABLE) {
                this.appHandler.openSnackBar("your trial period has expired.", 'OK', 'danger');
              }
              else if (response.status == this.appHandler.STATUS_CODE_UPGRADE_REQUIRED) {
                // this.appHandler.updateTemporaryToken(response.token);
                this.appHandler.openSnackBar("Your subscription has ended. Please subscribe to continue using the app.", 'OK', 'danger');
                // this.router.navigate(['/product/bundle/subscription']);
              }
              else if (response.status == this.appHandler.STATUS_CODE_GONE) {
                this.appHandler.openSnackBar("user account has been disabled.", 'OK', 'danger');
              }
              else {
                this.appHandler.openSnackBar('something went wrong! please try again.', 'OK', 'danger');
              }
            }
          });
      }
    }
    else {
      if (this.otpSent) {
        return
      }
      else {
        this.sendOTP();
      }
    }
  }

  onOtpChange(otp) {
    if (otp != null && otp.trim().length == 4) {
      this.isOTPValid = true;
      this.otp = otp;
    }
    else {
      this.isOTPValid = false;
    }
  }

  sendOTP() {
    this.otp = null;
    this.signingIn = false;
    this.sendingOTP = true;
    this.userService.sendOTP(this.credential)
      .subscribe(response => {
        this.sendingOTP = false;
        if (response.status == this.appHandler.STATUS_CODE_SUCCESS) {
          if (response.data != null) {
            // this.appHandler.openSnackBar('OTP sent successfully!', 'OK', 'success');
            this.userInfo = response.data;
            this.otpSent = true;
            this.triggerTimerForOTPResend();
            this.userId = response.data.UserId;
          }
        }
        else if (response.status == this.appHandler.STATUS_CODE_ITEM_NOT_FOUND) {
          this.appHandler.openSnackBar('user not found.', 'OK', 'danger');
        }
        else {
          this.appHandler.openSnackBar('something went wrong! please try again.', 'OK', 'danger');
        }
      });
  }


  triggerTimerForOTPResend() {
    var timeleft = 180;
    var that = this;
    this.intervalTimer = setInterval(function () {
      timeleft--;
      that.loginOTPResendTimer = timeleft;

      if (timeleft <= 0) {
        that.loginOTPResendTimer = 0;
        clearInterval(that.intervalTimer);
      }
    }, 1000);
  }

  resendOTP() {
    this.sendOTP();
  }

  validateOTP() {
    this.signingIn = true;
    this.userService.validateOTP(this.userId, this.otp)
      .subscribe(response => {
        this.signingIn = false;
        if (response.status == this.appHandler.STATUS_CODE_SUCCESS) {
          if (response.data != null) {
            this.updateSuccessfulLogin(response);
          }
        }
        else if (response.status == this.appHandler.STATUS_CODE_EXPECTATION_FAILED) {
          this.appHandler.openSnackBar('invalid OTP.', 'OK', 'danger');
        }
        else {
          this.appHandler.openSnackBar('something went wrong! please try again.', 'OK', 'danger');
        }
      });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  toggleLoginMode() {
    this.signingIn = false; this.sendingOTP = false;
    this.isPasswordLogin = !this.isPasswordLogin;
    this.otp = null; this.userId = null;
    this.otpSent = false;
    if (this.intervalTimer != null) {
      clearInterval(this.intervalTimer);
    }
  }

}
